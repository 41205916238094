import { useEffect } from 'react';
import { IFrame } from '../../../components/IFrame';
import { EPGM_URL } from '../../../config/constants';
import { loadTitle } from '../../../helpers/changeTitle';

type ToPartsProps = {
  title?: string;
};

export function ToParts(props: ToPartsProps) {
  useEffect(() => {
    loadTitle(props.title);
  }, []);

  return (
    <IFrame url={`${EPGM_URL}/buscar_pecas`}/>
  );
}
