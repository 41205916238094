import { t } from 'i18next';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { Procedure } from '../../../@types/model';
import { Moment } from '../../../config/moment';
import Format from '../../../helpers/format';
import { Deadline } from '../../Deadline';
import { truncate } from '../../../helpers/strings';
import { Avatar } from '../Avatar';
import styles from './styles.module.scss';

const hearings = (procedure: Procedure) => {
  if (procedure?.procedure_deadlines) {
    return (
      procedure.procedure_deadlines
        .filter((deadline) => deadline.reason === 'Audiência'
        && (deadline.individual_id === null || deadline.individual_id === procedure.current_individual_id)));
  }
  return [];
};

const returnDeadline = (procedure: Procedure) => {
  if (procedure.procedure_deadlines) {
    const deadlineList = procedure.procedure_deadlines.sort((deadlineA, deadlineB) => {
      const dateA = deadlineA.deadline ? new Date(deadlineA.deadline).getTime() : 0;
      const dateB = deadlineB.deadline ? new Date(deadlineB.deadline).getTime() : 0;
      return dateA - dateB;
    });

    for (let i = 0; i < deadlineList?.length; i += 1) {
      if (deadlineList[i].individual_id === procedure.current_individual_id || deadlineList[i].individual_id === null) {
        return deadlineList[i];
      }
    }
  }
  return undefined;
};

const requirimentDate = (procedure: Procedure) => {
  if (procedure.requiriment_date) {
    return (
      Moment((procedure.requiriment_date)?.toLocaleString()).format('DD/MM/YYYY')
    );
  }
  return t('procedureBox.procedureList.no_requiriment_date');
};

const competence = (procedure: Procedure) => {
  if (procedure.competence?.length !== 0) {
    return procedure.competence;
  }
  return t('procedureBox.procedureList.no_competence');
};

const setDeadlineMostNext = (procedure: Procedure) => {
  const crescentDate = hearings(procedure).sort((a, b) => new Date(`${a.deadline}`).getTime() - new Date(`${b.deadline}`).getTime());
  return (
    hearings(procedure).length !== 0 ? crescentDate.map((cd) => `${moment(cd.deadline).format('DD/MM/YYYY')}\n`)
      : t('procedureBox.procedureList.no_hearings_count')
  );
};

export const renderHeader = (description: string, procedure: Procedure) => {
  switch (description) {
    case 'last_product_generated':
      return (
        <Tooltip title={`${procedure.last_product_generated?.length > 20 ? procedure.last_product_generated : ''}`}>
          <span className={styles.itemProcedure}>{truncate(procedure?.last_product_generated, 20)}</span>
        </Tooltip>
      );
    case 'subject':
      return (
        <Tooltip title={procedure?.subject?.length > 30 ? procedure.subject : ''}>
          <span className={styles.itemProcedure}>{truncate(procedure?.subject, 20)}</span>
        </Tooltip>
      );
    case 'procedure_value':
      return <span className={styles.itemProcedure}>{Format.currency(procedure?.procedure_value)}</span>;
    case 'flux':
      return (
        <Tooltip title={procedure?.flux?.name?.length > 30 ? procedure.flux.name : ''}>
          <span className={styles.itemProcedure}>{truncate(procedure?.flux?.name, 20)}</span>
        </Tooltip>
      );
    case 'interested_parts':
      return (
        <Tooltip title={procedure?.interested_parts?.length > 0 ? `(${procedure.interested_parts.length}) ${procedure.interested_parts.join(', ')}` : ''}>
          <span className={styles.itemProcedure}>{truncate(procedure?.interested_parts.join(', '), 20)}</span>
        </Tooltip>
      );
    case 'origin_procedure_name':
      return (
        <Tooltip title={procedure?.origin_procedure_name?.length > 30 ? procedure?.origin_procedure_name : ''}>
          <span className={styles.itemProcedure}>{truncate(procedure?.origin_procedure_name, 20)}</span>
        </Tooltip>
      );
    case 'process_category_name':
      return (
        <Tooltip title={procedure?.process_category_name?.length > 0 ? procedure?.process_category_name : ''}>
          <span className={styles.itemProcedure}>{truncate(procedure?.process_category_name, 20)}</span>
        </Tooltip>
      );
    case 'hearings_count':
      return (
        <Tooltip
          title={setDeadlineMostNext(procedure)}
        >
          <span className={styles.itemProcedure}>{hearings(procedure).length}</span>
        </Tooltip>
      );
    case 'competence':
      return (
        <span className={styles.itemProcedure}>
          {competence(procedure)}
        </span>
      );
    case 'created_at':
      return (
        <span className={styles.itemProcedure}>
          {Moment((procedure[description as keyof Procedure])?.toLocaleString()).format('DD/MM/YYYY')}
        </span>
      );
    case 'received_date':
      return (
        <span className={styles.itemProcedure}>
          {Moment((procedure[description as keyof Procedure])?.toLocaleString()).format('DD/MM/YYYY')}
        </span>
      );
    case 'time_inbox':
      return (
        <span className={styles.itemProcedure}>
          {Moment((procedure[description as keyof Procedure])?.toLocaleString()).fromNow()}
        </span>
      );
    case 'inactive_time':
      return (
        <span className={styles.itemProcedure}>
          {Moment((procedure[description as keyof Procedure])?.toLocaleString()).fromNow()}
        </span>
      );
    case 'procedure_deadline':
      return (
      <span className={styles.itemProcedure}>
        {<Deadline procedure={procedure} />}
      </span>
      );
    case 'requiriment_date':
      return (
        <span className={styles.itemProcedure}>
          {requirimentDate(procedure)}
        </span>
      );
    case 'cdas_linked':
      return (
        <span className={styles.itemProcedure}>
          <div>
            <div>
              <span >{t('procedureBox.procedureList.cdas_linked')}:</span>
              <span >{ 0 }</span>
            </div>
          </div>
        </span>
      );
    case 'deadlines':
      return (
        <span className={styles.itemProcedure}>
          <Deadline
            procedure={procedure}
            procedureDeadline={returnDeadline(procedure)} />
        </span>
      );
    case 'current_responsible':
      return (
        <span className={styles.itemProcedure}>
          <div>
            <Avatar
              name={procedure.individual_name }
              color={procedure.individual_color}
              profile_picture={procedure.individual_profile_picture}
            />
          </div>
        </span>
      );
    case 'previous_responsibles':
      return (
        <span className={styles.itemProcedure}>
          <div>
            <div>
                <Avatar
                  name={ procedure.involved_people[0].name }
                  color={procedure.involved_people[0].color}
                  profile_picture={procedure.involved_people[0].profile_picture}
                />
            </div>
          </div>
        </span>
      );
    case 'shared_with':
      return (
        <span className={styles.itemProcedure}>
          <div>
              <Avatar
                name={ procedure.involved_people[0].name }
                color={procedure.involved_people[0].color}
                profile_picture={procedure.involved_people[0].profile_picture}
              />
          </div>
        </span>
      );
    default:
      return '';
  }
};
