import { Grid } from '@mui/material';
import { t } from 'i18next';
import { FaCog } from 'react-icons/fa';
import { useContext } from 'react';
import { CitationIntimation, Procedure } from '../../@types/model';
import { QueryContext } from '../../context/queryContext';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { EmptyList } from '../EmptyList';
import { Item } from './Item';
import { LoadingList } from './LoadingList';
import styles from './styles.module.scss';
import Show from '../Show';
import { ItemCitationIntimation } from './ItemCitationIntimation';
import { RoundButton } from '../RoundButton';

type ProcedureListProps = {
  origin: 'collection' | 'default';
  scroll?: number;
};

export function ProcedureList(props: ProcedureListProps) {
  const {
    procedures, loading, box, citationIntimations, orderGeneral,
  } = useContext(QueryContext);
  const { proceduresSeleted, openCustomizeColumns } = useContext(ProcedureActionsContext);
  const display: boolean = props.origin !== 'collection';

  const verifyBoxCitationIntimation = () => {
    return box === 'citationIntimation';
  };

  const handleSubjectCollumn = () => {
    if (display) {
      if (verifyBoxCitationIntimation()) {
        return t('procedureBox.procedureList.citationIntimation.header.created_at');
      }
      return t(`procedureBox.procedureList.${orderGeneral.length > 0 ? orderGeneral[0].description : 'subject'}`);
    }
    return t('procedureCollections.procedureList.description');
  };

  const handleDataCollumn = () => {
    if (display) {
      if (verifyBoxCitationIntimation()) {
        return t('procedureBox.procedureList.citationIntimation.header.observations');
      }
      return t(`procedureBox.procedureList.${orderGeneral.length > 0 ? orderGeneral[1].description : 'time_inbox'}`);
    }
    return t('procedureCollections.procedureList.data');
  };

  const handleInterestedParts = () => {
    if (display) {
      if (verifyBoxCitationIntimation()) {
        return t('procedureBox.procedureList.citationIntimation.header.content');
      }
      return t(`procedureBox.procedureList.${orderGeneral.length > 0 ? orderGeneral[2].description : 'time_inbox'}`);
    }
    return t('procedureCollections.procedureList.location');
  };

  const getGridProps = () => {
    if (display && !verifyBoxCitationIntimation()) {
      return { xs: 1, sm: 10, md: 16 };
    }
    if (!display) {
      return { xs: 1, sm: 10, md: 14 };
    }
    return { xs: 1, sm: 10, md: 10 };
  };

  const handleDeadline = () => {
    if (display && !verifyBoxCitationIntimation()) {
      return t(`procedureBox.procedureList.${orderGeneral.length > 0 ? orderGeneral[3].description : 'time_inbox'}`);
    }
    return <></>;
  };

  return (
    <div
      className={ styles.container }
      style={props.scroll ? { overflowY: props.scroll >= 150 ? 'scroll' : 'auto' } : {}}
      data-cy={`procedure-list-${props.origin}`}
    >
      <Grid
        container
        columns={getGridProps()}
        className={ styles.head }
      >
        <Show if={verifyBoxCitationIntimation()}>
          <Grid
            item
            xs={ 1 }
            sm={ 2 }
            md={ 2 }
          >
            <span>{t('procedureBox.procedureList.citationIntimation.header.processNumber')}</span>
          </Grid>
        </Show>
        <Grid
          item
          xs={ 1 }
          sm={ verifyBoxCitationIntimation() ? 2 : 4 }
          md={ verifyBoxCitationIntimation() ? 2 : 3 }
        >
          <span>{verifyBoxCitationIntimation() ? t('procedureBox.procedureList.citationIntimation.header.subject') : t('procedureBox.procedureList.procedure') }</span>
        </Grid>
        <Grid
          item
          sm={ display && !verifyBoxCitationIntimation() ? 3 : (verifyBoxCitationIntimation() ? 1 : 2) }
          md={ display && !verifyBoxCitationIntimation() ? 3 : (verifyBoxCitationIntimation() ? 1 : 2) }
          sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
        >
          <span>{ handleSubjectCollumn() }</span>
        </Grid>
        <Grid
          item
          sm={ display && !verifyBoxCitationIntimation() ? 3 : 2 }
          md={ display && !verifyBoxCitationIntimation() ? 3 : 2 }
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          <span>{ handleDataCollumn() }</span>
        </Grid>
        <Show if={!display}>
          <Grid
            item
            sm={ display && !verifyBoxCitationIntimation() ? 3 : 2 }
            md={ 2 }
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          >
            <span>{t('procedureCollections.procedureCollection.LastStep')}</span>
          </Grid>
        </Show>
        <Grid
          item
          sm={ display && !verifyBoxCitationIntimation() ? 3 : 2 }
          md={ 2 }
          sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
        >
          <span>{ handleInterestedParts() }</span>
        </Grid>
        <Show if={!display}>
          <Grid
            item
            sm={ display && !verifyBoxCitationIntimation() ? 3 : 2 }
            md={ 2 }
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          >
            <span>{t('procedureCollections.procedureCollection.InvolvedPeoples')}</span>
          </Grid>
        </Show>
        <Show if={display && !verifyBoxCitationIntimation()}>
          <Grid
            item
            md={ 2 }
            sm={ 2 }
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          >
            <span>{ handleDeadline() }</span>
          </Grid>
        </Show>
        <Show if={display && !verifyBoxCitationIntimation()}>
          <Grid
            item
            md={ 3 }
            sm={ 4 }
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            className={styles.customizeColumns}
          >
            <RoundButton
              dataCy={'cypress-customize-columns-action'}
              className={ styles.button }
              onClick={() => openCustomizeColumns()}
              icon={<FaCog/>}
              tooltip={t('procedureBox.customizeColumns.action')}
              size='big'
            />
          </Grid>
        </Show>
      </Grid>
      {verifyBoxCitationIntimation() ? citationIntimations.map((row: CitationIntimation) => (
        <ItemCitationIntimation
          citationIntimation={row}
          origin={'collection'}
          key={Object(row).id}
          selected={Boolean(proceduresSeleted.find((i) => i.id === row.id))} />
      )) : procedures.map((row: Procedure) => (
        <Item
          origin={props.origin}
          procedure={row}
          key={Object(row).id}
          selected={Boolean(proceduresSeleted.find((i) => i.id === row.id))} />
      ))}
      { loading && (procedures == null || procedures.length === 0) && <LoadingList /> }
      {
        !loading && ((box !== 'citationIntimation' && (procedures == null || procedures.length === 0)) || (box === 'citationIntimation' && citationIntimations.length === 0)) && <EmptyList />
      }
    </div>
  );
}
