import { useContext, useState } from 'react';
import {
  ThemeProvider,
} from '@mui/material/';
import { FaChevronDown } from 'react-icons/fa';
import Menu from '@mui/material/Menu';
import styles from './styles.module.scss';
import { CheckBox } from '../CheckBox';
import { QueryContext } from '../../context/queryContext';
import { customTheme } from './customTheme';
import { GenericMarker } from '../../@types/markers';
import {
  getMarkerColor, getMarkerLabel,
} from '../../helpers/markers';
import { Procedure } from '../../@types/model';

type MenuSelectProps = {
  itens: GenericMarker[];
  keyId: string;
  label: string;
  procedureId: number;
  procedures?: Procedure[];
};

export function MarkerMenu(props: MenuSelectProps) {
  const { addMarker, changePriorityMarker, changePendencyMarker } = useContext(QueryContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [items, setItems] = useState<GenericMarker[]>(props.itens);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setItems(props.itens);
  };

  const getColor = () => {
    if (props.keyId === 'low') {
      return styles.low;
    }
    if (props.keyId === 'normal') {
      return styles.normal;
    }
    if (props.keyId === 'high') {
      return styles.high;
    }
    return styles.default;
  };

  const handleFilter = () => {
    const marker = props.itens.find((element) => {
      if (element.marker_pending) {
        return element.marker_pending === props.keyId;
      }
      return element.marker_priority === props.keyId;
    });
    if (marker) {
      addMarker(marker);
    }
  };

  const handleChange = (marker: GenericMarker) => {
    if (marker.marker_pending) {
      changePendencyMarker(props.procedureId, marker.marker_pending, props.procedures);
    } else {
      changePriorityMarker(props.procedureId, marker.marker_priority, props.procedures);
    }
    handleClose();
  };

  return (
    <div
      className={ `${styles.container} ${getColor()}` }
    >
      <ThemeProvider theme={customTheme}>
      <div onClick={open ? handleClose : handleClick}>
        <FaChevronDown className={ `${styles.selectButton} ${open ? styles.open : ''}`} />
      </div>
      <span
        className={`${styles.selectedItem}`}
        onClick={() => handleFilter()}
      >{props.label}</span>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ paddingTop: '0px !important' }}
      >
        {items.map((marker) => (
          <div className={`${styles.item}`} onClick={() => handleChange(marker)}>
            <CheckBox
              value={ marker.marker_pending === props.keyId || marker.marker_priority === props.keyId }
              text={ getMarkerLabel(marker) }
              color={ getMarkerColor(marker) }
              isMarker
            />
          </div>
        ))}
      </Menu>
      </ThemeProvider>
    </div>
  );
}
