import { useContext } from 'react';
import { t } from 'i18next';
import { DashboardDigitalProcessesContext } from '../../context/dashboardDigitalProcessesContext';
import styles from './styles.module.scss';
import { Info } from '../Charts/Info';

export function ProceduresCollection() {
  const { loading, procedureTotals } = useContext(DashboardDigitalProcessesContext);

  return (
    <div className={ styles.container }>
      <div className={ styles.boxInfo }>
        <Info
          title={t('home.dashboard.digitalProcesses.totals.inbox.title')}
          totalValue={procedureTotals.total_inbox}
          textColor={'var(--gray3)'}
          backgroundColor={'var(--gray5)'}
          loading={loading}
          tooltip={t('home.dashboard.digitalProcesses.totals.inbox.tooltip')}
          currency={false} />
      </div>
      <div className={ styles.boxInfo }>
        <Info
          title={t('home.dashboard.digitalProcesses.totals.outbox.title')}
          totalValue={procedureTotals.total_outbox}
          textColor={'var(--success)'}
          backgroundColor={'var(--successLight1)'}
          loading={loading}
          tooltip={t('home.dashboard.digitalProcesses.totals.outbox.tooltip')}
          currency={false} />
      </div>
      <div className={ styles.boxInfo }>
        <Info
          title={t('home.dashboard.digitalProcesses.totals.inputbox.title')}
          totalValue={procedureTotals.total_inputbox}
          textColor={'var(--primaryHover)'}
          backgroundColor={'var(--infoInbox)'}
          loading={loading}
          tooltip={t('home.dashboard.digitalProcesses.totals.inputbox.tooltip')}
          currency={false} />
      </div>
    </div>
  );
}
