import { useState, useContext } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Batch, MultipleActionStep } from '../../@types/backgroundActions';
import { DocumentList } from './DocumentList';
import { SelectedProcedures } from '../SelectedProcedures';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { Button } from '../Button';
import { Toast } from '../Toast';
import { GetVariablesToSignRequest } from '../../@types/config';
import { AGILIS_API_URL, EPGM_URL } from '../../config/constants';
import { BackgroundActionsContext } from '../../context/backgroundActions';

type CosignerProps = {
  multipleActions?: MultipleActionStep;
};

export function Cosigner(props: CosignerProps) {
  const {
    setAlert, proceduresSeleted, removeProcedures, setModal,
  } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [documents, setDocuments] = useState<any[]>([]);
  const keyI18n = 'procedureBox.actions.cosigner';

  const renderTitle = () => {
    return (
      <Trans
        i18nKey={`${keyI18n}.modal.alertConfirmation.title`}
        components={{ bold: <strong /> }}
        values={{ docCount: documents.length, count: proceduresSeleted.length }}
      />
    );
  };

  const renderText = () => {
    return (
      <Trans
        i18nKey={`${keyI18n}.modal.alertConfirmation.text`}
        components={{ bold: <strong /> }}
        values={{ count: documents.length }}
      />
    );
  };

  const handleConfirm = () => {
    const data: GetVariablesToSignRequest = { procedures: [] };
    proceduresSeleted.map((procedure) => {
      const procedureDocuments = documents.filter((doc) => doc.procedure_id === procedure.id);
      data.procedures.push({
        procedure_id: procedure.id,
        progress_id: procedureDocuments[0].progress_id,
        attachments: procedureDocuments.map((doc) => {
          return {
            attachment_url: doc.url,
            attachment_id: doc.id,
            attachment_signed_url: doc.options.data.url_signed_file,
            control_code: (Math.random() + 1).toString(36).substring(4).toUpperCase(),
          };
        }),
      });
    });

    const body = {
      codebase: EPGM_URL,
      path_files: documents.map((doc) => doc.url).join(),
      id_files: documents.map((doc) => doc.id).join(),
      host_upload: `${AGILIS_API_URL}/api/activity/attachment_selections/upload_signed_file`,
      progress_id: documents.map((doc) => doc.progress_id).join(),
      procedure_id: documents.map((doc) => doc.procedure_id).join(),
      control_codes: documents.map(() => (Math.random() + 1).toString(36).substring(4).toUpperCase()).join(),
      class_name: documents.map((doc) => doc.class_name).join(),
      direction: 'from-page',
    };

    const procedures = proceduresSeleted;
    const type = 'cosigner';
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: documents.length,
      successList: [],
      failedList: [],
      body: {
        data: body,
        documents,
        documentsToRequest: data,
      },
      multipleActions: props.multipleActions,
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    window.postMessage(batch.body.data, '*');
    setAlert(undefined);
    setModal(undefined);
  };

  const handleSubmit = () => {
    setAlert({
      visible: true,
      title: renderTitle(),
      text: renderText(),
      confirmText: t('general.yes'),
      handleConfirm: () => handleConfirm(),
    });
  };

  return (
    <div className={ styles.container }>
      <SelectedProcedures />
      <div className={styles.header}>
        <p>
          <b>{t(`${keyI18n}.modal.title`)}</b>
        </p>
        <p>{t(`${keyI18n}.modal.subtitle`)}</p>
      </div>
      <div className={styles.toast}>
        <Toast
          type='info'
          collapse
          text={
            <Trans
              i18nKey={`${keyI18n}.modal.toast`}
              components={{ bold: <strong />, a: <a /> }}
            />
          }
          open
        />
      </div>

      <DocumentList onChange={(val) => setDocuments(val)} />

      <footer>
        <Button
          title={t(`${keyI18n}.modal.submit`)}
          textCenter
          round
          buttonType='primary'
          size='flat'
          disabled={documents.length === 0}
          onClick={() => handleSubmit()} />
      </footer>
    </div>
  );
}
