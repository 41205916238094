import { ReactNode, useContext } from 'react';
import { FaExclamationTriangle, FaTimes } from 'react-icons/fa';
import { Batch } from '../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import Show from '../../Show';
import { ProgressBar } from '../ProgressBar';
import { Results } from '../Results';
import styles from './styles.module.scss';

type AlertProps = {
  title: string;
  text: ReactNode;
  icon?: ReactNode;
  batch: Batch;
  waiting?: boolean;
  textIcon?: boolean;
  alertText? : string;
};

export function Alert(props: AlertProps) {
  const { removeBatch } = useContext(BackgroundActionsContext);
  return (
    <div className={ styles.container }>
      <div className={styles.header}>
        <div className={styles.title}>
          <Show if={!!props.textIcon}>
            {props.icon}
          </Show>
          <Show if={!props.textIcon}>
            <div className={`${props.icon && styles.icon}`}>
              {props.icon}
            </div>
          </Show>
          <span>{props.title}</span>
        </div>

        <a className={`${styles.close} ${props.batch.status === undefined ? styles.disabled : ''}`} onClick={() => removeBatch(props.batch)}>
          <FaTimes />
        </a>
      </div>
      {
        props.waiting ? (
            <div className={styles.bodyAlert}>
              <div className={styles.text}>
                <div className={styles.alert}>
                  <FaExclamationTriangle color={'var(--warningDark)'}/>
                  <span>{props.alertText}</span>
                </div>
                <div className={styles.box}>
                  <span>{props.text}</span>
                </div>
              </div>
            </div>
        ) : (
            <div>
              <Show if={props.batch.status === 'finished' || props.batch.status === 'waitingClose'}>
                <Results batch={props.batch}/>
              </Show>
              <div className={styles.body}>
                <div className={styles.text}>
                  <span>{props.text}</span>
                </div>
                <ProgressBar batch={props.batch}/>
              </div>
            </div>
        )
      }
    </div>
  );
}
