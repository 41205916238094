import {
  NegotiationPerMonthResponse,
  DebtAmountResponse, NegotiationProgress, Negotiations, Prescriptions,
} from '../@types/debtDashboard';
import {
  DigitalProcessesAmountResponse,
  PerformanceResponse,
  ProductivityResponse,
  IndividualResponse,
  ListFieldsAndIndividuals,
} from '../@types/digitalProcessesDashboard';
import Axios from '../config/api';

export default class DashboardService {
  public static async getReceiptTypes() {
    return Axios.get('api/dashboards_inscriptions/list_receipt_types')
      .then((res) => res.data);
  }

  public static async getDebtsAmount(query?: string) {
    return Axios.get<DebtAmountResponse>(`api/dashboards_inscriptions/debts_amount${query || ''}`)
      .then((res) => res.data);
  }

  public static async getPrescriptions(query?: string) {
    return Axios.get<Prescriptions>(`api/dashboards_inscriptions/prescriptions${query || ''}`)
      .then((res) => res.data);
  }

  public static async getNegotiations(query?: string) {
    return Axios.get<Negotiations>(`api/dashboards_inscriptions/negotiations${query || ''}`)
      .then((res) => res.data);
  }

  public static async getDebtsPerMonth(year: string, query?: string) {
    return Axios.get<NegotiationPerMonthResponse>(`api/dashboards_inscriptions/negotiations/per_month?year=${year}${query || ''}`)
      .then((res) => res.data);
  }

  public static async getNegotiationProgress(query?: string) {
    return Axios.get<NegotiationProgress[]>(`api/dashboards_inscriptions/inscriptions_progress${query || ''}`)
      .then((res) => res.data);
  }

  public static async getDigitalProcessesAmount(query?: string) {
    return Axios.get<DigitalProcessesAmountResponse>(`api/dashboards_processes/totals${query || ''}`)
      .then((res) => res.data);
  }

  public static async getPerformance(query?: string) {
    return Axios.get<PerformanceResponse>(`api/dashboards_processes/performance${query || ''}`)
      .then((res) => res.data);
  }

  public static async getProductivity(query?: string) {
    return Axios.get<ProductivityResponse>(`api/dashboards_processes/individuals${query || ''}`)
      .then((res) => res.data);
  }

  public static async getFieldsWithIndividuals() {
    return Axios.get<ListFieldsAndIndividuals>('api/dashboards_processes/list_fields_and_individuals')
      .then((res) => res.data);
  }

  public static async getIndividuals() {
    return Axios.get<IndividualResponse[]>('api/individuals')
      .then((res) => res.data);
  }

  public static async getProcedures(processValue?: string, quantity?: number) {
    return Axios.get(`api/procedures/autocomplete/procedure_numbers?q[num_cont]=${processValue}&per_page=10&page=1`)
      .then((res) => res.data);
  }

  public static async getIndividualsFilter(value?: string, quantity?: number) {
    return Axios.get(`api/procedures/autocomplete/individuals?q[name_cont]=${value}&per_page=10&page=1`)
      .then((res) => res.data);
  }

  public static async getInterestedPartFilter(value?: string, quantity?: number) {
    return Axios.get(`api/procedures/autocomplete/interested_part_procedures?q[name_cont]=${value}&per_page=5&page=1`)
      .then((res) => res.data);
  }

  public static async getLastUpdatedInDashboard() {
    return Axios.get('api/dashboards/schedules')
      .then((res) => res.data);
  }

  public static async getContributors(contributorName?: string, quantity?: number) {
    return Axios.get(`api/inscriptions/get_contributors?q=${contributorName}`)
      .then((res) => res.data.result);
  }

  public static async getContributorData(contributorId?: number, quantity?: number) {
    return Axios.get(`api/inscriptions/request_legal_action?q[id_eq]=${contributorId}`)
      .then((res) => res.data.result);
  }

  public static async getInscriptionsData(inscriptionIds?: number[], quantity?: number) {
    return Axios.get('api/inscriptions/charges', { params: { inscriptions: inscriptionIds } })
      .then((res) => res.data.result);
  }

  public static async getStepChartDependencies() {
    return Axios.get('api/dashboards_processes/progresses/dependencies')
      .then((res) => res.data);
  }

  public static async getStepChart(query: string) {
    return Axios.get(`api/dashboards_processes/progresses${query}`)
      .then((res) => res.data);
  }

  public static async downloadXls(query: string) {
    return Axios.get(`api/dashboards_processes/progresses/download_xls${query}`)
      .then((res) => res.data);
  }
}
