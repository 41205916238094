import { useState } from 'react';
import { t } from 'i18next';
import Menu from '@mui/material/Menu';
import { Tooltip } from '@mui/material';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './styles.module.scss';
import { CheckBox } from '../CheckBox';

export type ItemType = {
  id: string;
};

const itens: ItemType[] = [
  {
    id: 'all',
  },
  {
    id: 'none',
  },
];

type SelectBoxMenuProps = {
  onClickAll: () => void;
  onClickNone: () => void;
  selectedCount: number;
  countTotal: number;
  color?: string;
};

export default function SelectBoxMenu(props: SelectBoxMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (item: ItemType) => {
    switch (item.id) {
      case 'all':
        props.onClickAll();
        break;
      case 'none':
        props.onClickNone();
        break;
      default:
        break;
    }
  };

  return (
    <div className={`${styles.selectionBoxContainer}`}>
      <div
        className={`${styles.itemContainer} ${open ? styles.itemContainerOpen : ''}`}
        onClick={open ? handleClose : handleClick}
      >
        <Tooltip title={t('procedureBox.selectionBoxMenu.title').toString()}>
          <div>
            <CheckBox
              value={ props.selectedCount > 0 }
              uncheckedColor='var(--dark)'
              color={props.color || 'var(--primary)'}
              isMarker={false}
              partially={props.selectedCount < props.countTotal}
            />
          </div>
        </Tooltip>
        { open ? <FaChevronUp className={ styles.chevron } /> : <FaChevronDown className={ styles.chevron } /> }
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {itens.map((i) => (
            <div
              className={styles.menuItem}
              onClick={() => handleChange(i)}
            >
              <p>{t(`procedureBox.selectionBoxMenu.${i.id}`)}</p>
            </div>
          ))}
        </Menu>
      </div>
    </div>
  );
}
