import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import saveAs from 'file-saver';
import { CDA } from '../../../@types/digitalProcesses/cdas';
import { Table } from '../../../components/Table';
import { Moment } from '../../../config/moment';
import Format from '../../../helpers/format';
import ProcedureBoxService from '../../../services/procedureBoxService';
import styles from './styles.module.scss';
import { Button } from '../../../components/Button';
import Show from '../../../components/Show';
import { Procedure } from '../../../@types/model';
import { CheckBox } from '../../../components/CheckBox';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';

type CDAsProps = {
  procedure: Procedure;
};

export function CDAs(props: CDAsProps) {
  const [data, setData] = useState<CDA[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [checkboxes, setCheckboxes] = useState<number[]>([]);
  const {
    setAlert,
    addToasts,
  } = useContext(ProcedureActionsContext);

  const toggleCheckboxId = (id: number) => {
    setCheckboxes((prevCheckboxes) => {
      if (prevCheckboxes.includes(id)) {
        return prevCheckboxes.filter((checkboxId) => checkboxId !== id);
      }
      return [...prevCheckboxes, id];
    });
  };

  const toggleSelectAll = () => {
    const allSelected = checkboxes.length > 0 && checkboxes.every((id) => data.some((item) => item.id === id));
    setCheckboxes(allSelected ? [] : data.map((item) => item.id));
  };

  useEffect(() => {
    ProcedureBoxService.getCDAs(props.procedure.id).then((res) => {
      setData(res);
      setLoaded(true);
    });
  }, []);

  const getTotalValue = () => {
    let total = 0;
    data.map((cda) => {
      total += parseFloat(cda.balance);
    });
    return Format.currency(total);
  };

  const verifyDate = (date: string) => {
    if (Moment(date).isValid()) {
      return Moment(date).format('DD/MM/YYYY');
    }
    return date;
  };

  const handleToasts = (attach: boolean | undefined) => {
    if (attach) {
      addToasts({
        type: 'success',
        text: t('procedureBox.processExtract.cdas.toasts.successAttach'),
      });
    } else {
      addToasts({
        type: 'success',
        text: t('procedureBox.processExtract.cdas.toasts.successGenerate'),
      });
    }
  };

  const handleEmitExtract = () => {
    const inscriptionsIds = data.map((inscription, index) => {
      return index === 0 ? `inscriptions[]=${inscription.id}` : `&inscriptions[]=${inscription.id}`;
    }).join('');

    ProcedureBoxService.emitExtract(inscriptionsIds).then((resp) => {
      const blob = Format.base64ToBlob(resp, 'application/pdf');
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    });
  };

  const handleEmitCda = (attach?: boolean) => {
    ProcedureBoxService.emitCdas(checkboxes, props.procedure.id, attach).then((resp) => {
      const blob = new Blob([resp], { type: 'application/pdf' });
      saveAs(blob, `cdas_${props.procedure.id}.pdf`);
      setAlert(undefined);
      if (resp.size > 500) {
        handleToasts(attach);
      } else {
        addToasts({
          type: 'error',
          text: t('procedureBox.processExtract.cdas.toasts.error'),
        });
      }
    });
  };

  const handleModal = () => {
    setAlert({
      visible: true,
      type: 'warning',
      title: t('procedureBox.processExtract.cdas.alert.title'),
      text: t('procedureBox.processExtract.cdas.alert.text'),
      confirmText: t('procedureBox.processExtract.cdas.alert.confirmText'),
      confirmType: 'primary',
      cancelText: t('procedureBox.processExtract.cdas.alert.cancelText'),
      cancelType: 'default',
      handleConfirm: () => {
        setAlert({
          visible: true,
          title: t('procedureBox.processExtract.cdas.loading.title'),
          text: t('procedureBox.processExtract.cdas.loading.text'),
          type: 'loading',
        });
        handleEmitCda(false);
      },
      handleCancel: () => {
        setAlert({
          visible: true,
          title: t('procedureBox.processExtract.cdas.loading.title'),
          text: t('procedureBox.processExtract.cdas.loading.text'),
          type: 'loading',
        });
        handleEmitCda(true);
      },
    });
  };

  return (
    <div className={ styles.container }>
      <div className={styles.header}>
        <div className={styles.extract}>
          <Show if={!!data && ['FLU0051', 'FLU0003'].includes(props.procedure.flux.code)}>
            <Button
              onClick={() => handleEmitExtract()}
              title={t('procedureBox.processExtract.cdas.extract')}
              buttonType='default' round size='small' />

          <Button
            dataCy='cypress-generate-cda-button'
            onClick={handleModal}
            disabled={checkboxes.length === 0}
            title={t('procedureBox.processExtract.cdas.cda')}
            buttonType='primary' round size='small' />
          </Show>
        </div>
        <p className={styles.title}>{t('procedureBox.processExtract.cdas.total')} {getTotalValue()}</p>
      </div>
      {
        loaded ? (
          <Table>
            <thead>
              <tr>
                <th>
                  {<CheckBox
                    dataCy={'cypress-checkbox-cda-all'}
                    value={checkboxes.length > 0}
                    onClick={toggleSelectAll}
                    uncheckedColor='var(--dark)'
                    color='var(--primary)'
                  />}
                </th>
                <th>{t('procedureBox.processExtract.cdas.table.contributor')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.number')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.step')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.lastChange')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.prescriptionDate')}</th>
                <th>{t('procedureBox.processExtract.cdas.table.value')}</th>
              </tr>
            </thead>
            {
              data.map((cda) => (
                <tbody>
                  <td>
                    {<CheckBox
                      dataCy={`checkbox-cda-${cda.id}`}
                      value={checkboxes.includes(cda.id)}
                      uncheckedColor='var(--dark)'
                      color='var(--primary)'
                      onClick={() => toggleCheckboxId(cda.id)}
                    />}
                  </td>
                  <td>{`${cda.contributor.cpf_cnpj} - ${cda.contributor.name}`}</td>
                  <td>{cda.cda_number}</td>
                  <td>{cda.state}</td>
                  <td>{Moment(cda.last_history_state.created_at).format('DD/MM/YYYY')}</td>
                  <td>{verifyDate(cda.prescription_date)}</td>
                  <td>{Format.currency(parseFloat(cda.balance))}</td>
                </tbody>
              ))
            }
          </Table>
        ) : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </div>
  );
}
