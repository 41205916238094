import { CheckBox } from '../../CheckBox';
import styles from './styles.module.scss';

type ColorsCheckBoxProps = {
  setColor: (value: string) => void;
  color: string;
};

export function ColorsSelect(props: ColorsCheckBoxProps) {
  const colorsPalette = [
    '#1A1818',
    '#ADABAB',
    '#A47152',
    '#F78934',
    '#10949E',
    '#1E5CE7',
    '#9D1BEF',
    '#D331B4',
    '#A30E44',
  ];

  const toggleChecked = (color: string) => {
    props.setColor(color);
  };

  return (
    <div className={styles.colors}>
      {
        colorsPalette.map((color) => (
          <CheckBox
            onClick={ () => toggleChecked(color) }
            isMarker
            key={color}
            color={color}
            value={ props.color === color }
            fontLarger
          />
        ))
      }
    </div>
  );
}
