import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { OptionType } from '../../../@types/config';
import { Flux } from '../../../@types/digitalProcesses/fluxes';
import { Header } from '../../../components/Header';
import { QueryContext } from '../../../context/queryContext';
import NewProcessService from '../../../services/newProcessService';
import { NewAdministrativeProurmaProcess } from '../Forms/NewAdministrativeProurmaProcess';
import { NewConsultancyProcess } from '../Forms/NewConsultancyProcess';
import { NewJudicialProcess } from '../Forms/NewJudicialProcess';
import { NewPetitioningInformations } from '../Forms/NewPetitioningInformations';
import { NewPJARecord } from '../Forms/NewPJARecord/indext';
import { NewProcessRegistration } from '../Forms/NewProcessRegistration';
import styles from './styles.module.scss';
import { NewProurmaDeed } from '../Forms/NewProurmaDeed';
import { NewIntimation } from '../Forms/NewIntimation';
import { NewSuspensionCancellationRequest } from '../Forms/NewSuspensionCancellationRequest';
import { NewRequestedLegalAction } from '../Forms/NewRequestedLegalAction';
import { alphabeticalOrder } from '../../../helpers/alphabeticalOrder';
import { NewOfficialLetterSefin } from '../Forms/NewOfficialLetterSefin';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Show from '../../../components/Show';
import Select from '../../../components/Select';
import { sendEvent } from '../../../helpers/googleAnalytics';

type NewProcedureProps = {
  closeModal: () => void;
  edit?: boolean;
};

export function NewProcedure(props: NewProcedureProps) {
  const { loadData } = useContext(QueryContext);
  const [fluxes, setFluxes] = useState<Flux[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [selected, setSelected] = useState<OptionType | null>();
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [procedure, setProcedure] = useState<any>({});
  const [fluxAlteration, setFluxAlteration] = useState<any>({});
  const handleStartCreateAnalytics = (val: any) => {
    sendEvent({
      event_name: 'new_procedure',
      label: `Fluxo - ${val?.label}`,
    });
  };

  const PROURMA_CODE = 'FLU0025';

  const activitiesCodes = ['ATV0001', 'ATV0032', 'ATV0016', 'ATV0026', 'ATV0044', 'ATV0025', 'ATV0027', 'ATV0008', 'ATV0029'];

  const getProcedureEdit = () => {
    NewProcessService.getEditProcedure(proceduresSeleted[0].id).then((res) => {
      if (res.flux_alteration === undefined) {
        setProcedure(res);
      } else {
        setProcedure(undefined);
        setFluxAlteration(res);
      }
    });
  };

  const loadFluxes = () => {
    NewProcessService.getAllFluxes().then((res) => {
      setFluxes(res);
      const mappedFluxes = res.filter((flux: Flux) => activitiesCodes.includes(flux.activity.code)).map((flux: Flux) => ({
        value: flux.id,
        label: flux.name,
      }));
      const mappedFluxesString = alphabeticalOrder(mappedFluxes.map((mapped: any) => mapped.label));

      const mappedAlphabeticalOrder = mappedFluxesString.map((el: any) => {
        return {
          label: el,
          value: mappedFluxes.filter((flux: any) => flux.label === el)[0].value,
        };
      });

      setOptions(mappedAlphabeticalOrder);
    });
  };

  useEffect(() => {
    loadFluxes();
    if (props.edit) {
      getProcedureEdit();
    }
  }, []);

  const closeModal = () => {
    props.closeModal();
    loadData();
  };

  const renderText = () => {
    const params = {
      fluxName: procedure?.flux_being_edited,
      number: proceduresSeleted[0]?.process_number,
    };

    return t(`procedureBox.newProcedure.${props.edit ? 'processNumber' : 'data'}`, params);
  };

  const renderForm = () => {
    const selectedFlux = fluxes.find((flux) => flux.id === selected?.value);
    const activityCode = props.edit ? procedure?.activity_code : selectedFlux?.activity.code;
    const activityByFluxAlteration = fluxAlteration.activity_code;

    const fluxCode = fluxes.find((fluxe: any) => fluxe.id === procedure?.flux_id || fluxAlteration?.flux_id)?.code;

    const fluxByFluxAlteration = fluxes.find((flux) => flux.id === fluxAlteration?.flux_id);
    const procedureId = fluxAlteration.procedure_id;

    switch (activityCode || activityByFluxAlteration) {
      case 'ATV0001':
        return (
          <NewProcessRegistration
            fluxId={selectedFlux?.id || fluxByFluxAlteration?.id}
            fluxCode={selectedFlux?.code || fluxByFluxAlteration?.code}
            procedure={props.edit ? procedure.process_registration ? procedure : undefined : undefined}
            procedureId={procedureId}
            closeModal={closeModal}
            fluxes={fluxes}
          />
        );
      case 'ATV0027':
        return (
          <NewConsultancyProcess
            fluxId={selectedFlux?.id}
            closeModal={closeModal}
            procedure={props.edit ? procedure : undefined}
          />
        );
      case 'ATV0016':
        return (
          <NewJudicialProcess
            fluxId={selectedFlux?.id ? selectedFlux.id : procedure?.flux_id}
            procedure={props.edit ? procedure : undefined}
            closeModal={closeModal}
            isProurma={selectedFlux?.code === 'FLU0033'}
          />
        );
      case 'ATV0032':
        return (
          <NewAdministrativeProurmaProcess
            fluxId={selectedFlux?.id || fluxByFluxAlteration?.id}
            procedure={props.edit ? procedure : undefined}
            isProurma={selectedFlux?.code === PROURMA_CODE || activityCode === 'ATV0032' || activityByFluxAlteration === 'ATV0032'}
            closeModal={closeModal}
            procedureId={procedureId}
          />
        );
      case 'ATV0025':
        return (
          <NewPJARecord
            fluxId={selectedFlux?.id ? selectedFlux.id : 0}
            closeModal={closeModal}
            procedure={props.edit ? procedure : undefined}
          />
        );
      case 'ATV0029':
        return (
          <NewProurmaDeed
            fluxId={selectedFlux?.id ? selectedFlux.id : 0}
            procedure={props.edit ? procedure : undefined}
            closeModal={closeModal}
          />
        );
      case 'ATV0042':
        return (
          <NewIntimation
            fluxId={selectedFlux?.id ? selectedFlux.id : 0}
            closeModal={closeModal}
          />
        );
      case 'ATV0044':
        return (
          <NewSuspensionCancellationRequest
            fluxId={selectedFlux?.id ? selectedFlux.id : 0}
            closeModal={closeModal}
          />
        );
      case 'ATV0008':
        return (
          <NewPetitioningInformations
            fluxId={(selectedFlux?.id ? selectedFlux.id : procedure?.flux_id) || fluxAlteration.flux_id}
            closeModal={closeModal}
            fluxCode={selectedFlux?.code || fluxCode}
            procedure={props.edit ? procedure?.petitioning_information : undefined}
            procedureId={fluxAlteration.procedure_id || procedure.id}
          />
        );
      case 'ATV0021':
        return (
          <NewRequestedLegalAction
            fluxId={selectedFlux?.id ? selectedFlux.id : 0}
            closeModal={closeModal}
          />
        );
      case 'ATV0026':
        return (
            <NewOfficialLetterSefin
              fluxId={selectedFlux?.id ? selectedFlux.id : 0}
              closeModal={closeModal}
            />
        );
      default:
        return '';
    }
  };

  return (
    <div className={ styles.container }>
      <Header text={renderText()} />
      <Show if={!props.edit}>
        <Grid
          container
          columns={{ xs: 1, sm: 10, md: 12 }}
          className={ styles.head }
        >
          <Grid
            item
            xs={ 1 }
            sm={ 6 }
            md={ 4 }
            className={styles.input}
          >
            <Select
              dataCy={'new-procedure-select'}
              label={t('procedureBox.newProcedure.selectFlux.label')}
              placeholder={t('procedureBox.newProcedure.selectFlux.placeholder')}
              onChange={(val) => { handleStartCreateAnalytics(val); setSelected(val); } }
              options={options}
              required
            />
          </Grid>
        </Grid>
      </Show>
      <div>
        { renderForm() }
      </div>
    </div>
  );
}
