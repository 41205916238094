import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { EPGM_URL, AGILIS_FRONT, EPGM_LOGIN_SCREEN } from '../../config/constants';
import AuthService from '../../services/authService';

import ilustrationAgilisImg from '../../assets/ilustration-agilis.svg';
import logoCtigi from '../../assets/logo-ctigi.svg';
import logoPgm from '../../assets/logo-pgm.svg';
import logoAgilisImg from '../../assets/logo-agilis.svg';
import { InputText } from '../../components/InputText';
import { Button } from '../../components/Button';
import { Toast } from '../../components/Toast';
import { Modal } from '../../components/Modal';
import { ForgotPassword } from './ForgotPassword';

type LoginScreenProps = {
  recoverPassword?: boolean;
};

type ToastType = {
  text: string;
  type: 'default' | 'warning' | 'info' | 'success' | 'error';
};

const LoginMain: React.FC = () => {
  return (
    <div className={styles.loginContainer}>
      <iframe src={`${EPGM_URL}/usuarios/entrar?agilis=${AGILIS_FRONT}`} className={styles.iframeStyle}></iframe>
    </div>
  );
};

const LoginScreen = (props: LoginScreenProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [toast, setToast] = useState<ToastType | undefined>();
  const [loading, setLoading] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleUsername = () => {
    const cleanedValue = username.replace(/[^\d\s]/g, '');
    const isNum = /^\d+$/.test(cleanedValue);
    if (isNum) {
      return username.replaceAll('.', '').replaceAll('-', '');
    }

    return username;
  };

  const loadCurrentUserToken = async (origin: string) => {
    setLoading(true);
    AuthService.getCurrentUserToken(handleUsername(), password, origin).then((res: any) => {
      const responseToken = res;
      localStorage.setItem('currentUserToken', responseToken.token);
      setLoading(false);
      window.location.href = '/';
    }).catch((err) => {
      setLoading(false);
      setToast({ text: err.data?.exception, type: 'error' });
    });
  };

  const handleLogin = (event: any) => {
    event.preventDefault();
    setToast(undefined);
    if (username && password && AGILIS_FRONT) {
      loadCurrentUserToken(AGILIS_FRONT);
    } else {
      setToast({ text: t('general.login.emptyFields'), type: 'error' });
    }
  };

  const handleChange = (e: any) => {
    const { value } = e.target;
    const cleanedValue = value.replace(/[^\d\s]/g, '');
    const isNum = /^\d+$/.test(cleanedValue);

    let maskedValue;
    if (isNum) {
      maskedValue = '';
      for (let i = 0; i < cleanedValue.length; i += 1) {
        if (i === 3 || i === 6) {
          maskedValue += '.';
        } else if (i === 9) {
          maskedValue += '-';
        }
        maskedValue += cleanedValue[i];
      }
    } else {
      maskedValue = value;
    }

    setUsername(maskedValue);
  };

  const handleChangePassword = (event: any) => {
    event.preventDefault();
    setToast(undefined);
    if (password !== confirmPassword) {
      setToast({ text: t('general.login.recoverPassword.differentPassword'), type: 'error' });
    } else if (token) {
      AuthService.recoverPassword(password, confirmPassword, token)
        .then(() => {
          setPasswordChanged(true);
        })
        .catch(() => {
          setToast({
            text: t('general.login.recoverPassword.error'),
            type: 'error',
          });
        });
    }
  };

  const handleOpenForgotPasswordModal = () => {
    setToast(undefined);
    setOpenForgotPassword(true);
  };

  const handleForgotPasswordEmailSend = () => {
    setOpenForgotPassword(false);
    setToast({
      text: t('general.login.recoverPassword.emailSend'),
      type: 'info',
    });
  };

  const renderPasswordChanged = () => {
    return (
      <div className={styles.passwordChanged}>
        <span>
          <Trans
            i18nKey={'general.login.recoverPassword.success'}
            components={{ a: <a /> }}
          />
        </span>
      </div>
    );
  };

  return (
    <body className={styles.body}>
      <Grid
        className={styles.section}
        container
        columns={{ xs: 2, sm: 6, md: 12 }}
        spacing={5}
      >
        <Grid
          item
          xs={2}
          sm={6}
          md={6}
          className={styles.image}
        >
          <img src={ilustrationAgilisImg} className={styles.ilustration}/>
        </Grid>
        <Grid item
          xs={2}
          sm={6}
          md={6}
          className={styles.form}
        >
          <div className={styles.login}>
            {props.recoverPassword ? (
              <div className={styles.loginEmail}>
                <img src={logoAgilisImg} className={styles.loginEmailImg} />
                <span className={styles.loginEmailText}>{t('general.login.recoverPassword.title')}</span>
                {
                  toast && (
                    <Toast
                      open={true}
                      type={toast.type}
                      text={toast?.text}
                      collapse
                    />
                  )
                }
                {passwordChanged ? renderPasswordChanged()
                  : (
                  <div>
                    <div className={styles.boxInput}>
                      <InputText
                        className={styles.input}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        label={t('general.login.recoverPassword.newPassword')}
                        placeholder={t('general.login.recoverPassword.newPasswordPlaceholder')} />
                    </div>
                    <div className={styles.boxInput}>
                      <InputText
                        className={styles.input}
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        label={t('general.login.recoverPassword.confirmPassword')}
                        placeholder={t('general.login.recoverPassword.newPasswordPlaceholder')} />
                    </div>
                    <div className={styles.links}>
                      <span>
                        <Trans
                          i18nKey={'general.login.contact'}
                          components={{ a: <a /> }} />
                      </span>
                    </div>
                    <div className={styles.button}>
                      <Button
                        title={t('general.login.recoverPassword.submit')}
                        size='flat'
                        round
                        buttonType='primary'
                        onClick={handleChangePassword}
                        disabled={loading}
                        type='submit' />
                    </div>
                  </div>
                  )
                }
              </div>
            ) : (
              <form onSubmit={handleLogin} className={styles.loginEmail}>
                <img src={logoAgilisImg} className={styles.loginEmailImg} />
                <span className={styles.loginEmailText}>{t('general.login.slogan')}</span>
                {toast && (
                  <Toast
                    open={true}
                    type={toast.type}
                    text={toast?.text}
                    collapse />
                )}
                <div className={styles.boxInput}>
                  <InputText
                    className={styles.input}
                    value={username}
                    onChange={handleChange}
                    label={t('general.login.username')}
                    placeholder={t('general.login.usernamePlaceholder')}
                  />
                </div>
                <div className={styles.boxInput}>
                  <InputText
                    className={styles.input}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    label={t('general.login.password')}
                    placeholder={t('general.login.passwordPlaceholder')}
                  />
                </div>

                <div className={styles.links}>
                  <a href='#' onClick={handleOpenForgotPasswordModal}>
                    {t('general.login.forgotPassword')}
                  </a>
                  <span>
                    <Trans
                      i18nKey={'general.login.contact'}
                      components={{ a: <a /> }}
                    />
                  </span>
                </div>

                <div className={styles.button}>
                  <Button
                    title={t('general.login.signIn')}
                    size='flat'
                    round
                    buttonType='primary'
                    onClick={handleLogin}
                    disabled={loading}
                    type='submit'
                  />
                </div>
              </form>
            )}
            <div className={styles.logos}>
              <img src={logoPgm} className={styles.loginEmailImg} />
              <img src={logoCtigi} className={styles.loginEmailImg} />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={styles.footer}>
        <p>{t('general.login.footer')}</p>
      </div>
      <Modal
        open={openForgotPassword}
        onClose={() => setOpenForgotPassword(false)}
      >
        <ForgotPassword onSubmit={handleForgotPasswordEmailSend}/>
      </Modal>
    </body>
  );
};

const SignIn = (props: LoginScreenProps) => {
  return EPGM_LOGIN_SCREEN ? <LoginMain /> : <LoginScreen recoverPassword={props.recoverPassword}/>;
};

export default SignIn;
