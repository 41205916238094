import { Grid } from '@mui/material';
import { t } from 'i18next';
import Switch from 'react-switch';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button } from '../../../../components/Button';
import DebtBoxService from '../../../../services/debtBoxService';
import { Label } from '../../../../components/Label';
import Select from '../../../../components/Select';

type SearchProps = {
  search?: {
    contributor?: string;
    processNumber?: string;
    inscription?: string;
    cda?: string;
    installmentNumber?: string;
    origin?: string;
    showExtinctDebits?: boolean;
  };
};

export function Search(props: SearchProps) {
  const [showExtinctDebits, setShowExtinctDebits] = useState(props.search?.showExtinctDebits);
  const [contributor, setContributor] = useState(props.search?.contributor);
  const [processNumber, setProcessNumber] = useState<any>(props.search?.processNumber);
  const [inscription, setInscription] = useState(props.search?.inscription);
  const [cda, setCda] = useState<any>(props.search?.cda);
  const [installmentNumber, setInstallmentNumber] = useState<any>(props.search?.installmentNumber);
  const [origin, setOrigin] = useState<any>(props.search?.origin);

  useEffect(() => {
  }, [contributor, processNumber, inscription, cda, installmentNumber, origin]);

  const submit = () => {
    const params = encodeURIComponent(JSON.stringify({
      contributor,
      processNumber,
      inscription,
      cda,
      installmentNumber,
      origin,
      showExtinctDebits,
    }));
    window.location.href = `/active_debt/debt_box/debt_box_search?search=${params}`;
  };

  const emptyFields = () => {
    return (!contributor && !processNumber && !inscription && !cda && !installmentNumber && !origin);
  };

  return (
    <div className={ styles.container }>
      <Grid
        columns={{ xs: 1, sm: 12, md: 12 }}
        container
      >
        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
          item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.contributor.label')}
              placeholder={t('debtBox.search.fields.contributor.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getContributorList}
              onChange={(event) => setContributor(event?.value)}
              labelBold
            />
          </Grid>
        </Grid>

        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
            item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.processNumber.label')}
              placeholder={t('debtBox.search.fields.processNumber.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getInscriptionsByProcessNumberList}
              onChange={(event) => setProcessNumber(event?.label)}
              labelBold
            />
          </Grid>
        </Grid>

        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
          item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.inscription.label')}
              placeholder={t('debtBox.search.fields.inscription.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getInscriptionsByNumberList}
              onChange={(event) => setInscription(event?.value)}
              labelBold
            />
          </Grid>
        </Grid>

        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
          item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.cda.label')}
              placeholder={t('debtBox.search.fields.cda.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getInscriptionsByCdaNumberList}
              onChange={(event) => setCda(event?.label)}
              labelBold
            />
          </Grid>
        </Grid>

        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
          item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.installmentNumber.label')}
              placeholder={t('debtBox.search.fields.installmentNumber.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getInscriptionsByInstallmentNumberList}
              onChange={(event) => setInstallmentNumber(event?.label)}
              labelBold
            />
          </Grid>
        </Grid>

        <Grid
          item xs={3} sm={12}
          className={styles.field}
        >
          <Grid
          item xs={3} sm={12} md={6}
          >
            <Select
              label={t('debtBox.search.fields.origin.label')}
              placeholder={t('debtBox.search.fields.origin.placeholder')}
              options={[]}
              urlToUpdate={DebtBoxService.getInscriptionsByKeyList}
              onChange={(event) => setOrigin(event?.label)}
              labelBold
            />
          </Grid>
        </Grid>
      </Grid>

      <div className={styles.actions}>
        <div className={styles.switch}>
          <Label text={t('debtBox.search.fields.showExtinctDebits')}/>
          <Switch
            checked={showExtinctDebits || false}
            onChange={() => setShowExtinctDebits(!showExtinctDebits)}
            offColor='#e8e8e8'
            onColor='#1eb1e7'
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            disabled={emptyFields()}
            width={32}
          />
        </div>
        <div className={styles.buttons}>
          <Button
            title={t('debtBox.search.clear')}
            buttonType="default"
            round
            size='flat'
          />
          <Button
            onClick={() => submit()}
            title={t('debtBox.search.confirm')}
            buttonType="primary"
            disabled={emptyFields()}
            round
            size='flat'
          />
        </div>
      </div>
    </div>
  );
}
