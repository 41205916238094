import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import styles from './styles.module.scss';

type BoxProps = {
  title: string;
  color: string;
  tooltip?: string;
};

export function Box(props: BoxProps) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={props.tooltip || ''}
    >
      <div className={styles.item} onClick={() => setOpen(!open)}>
        <div className={styles.box} style={{ background: props.color }}></div>
        <span>{props.title}</span>
      </div>
    </Tooltip>
  );
}
