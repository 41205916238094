import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import {
  FaCalendarAlt,
  FaFileAlt,
  FaFlag,
  FaUserAlt,
} from 'react-icons/fa';
import { Document, ResponseDocuments } from '../../../@types/config';
import { Moment } from '../../../config/moment';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { CheckBox } from '../../CheckBox';
import SelectionBoxMenu from '../../SelectionBoxMenu';
import Show from '../../Show';
import styles from './styles.module.scss';

type DocumentListProps = {
  onChange: (value: Document[]) => void;
};

export function DocumentList(props: DocumentListProps) {
  const { proceduresSeleted } = useContext(ProcedureActionsContext);
  const [documents, setDocuments] = useState<any[]>([]);
  const [data, setData] = useState<ResponseDocuments>();
  const [procedureSelected, setProcedureSelected] = useState<number>();
  const procedureIds = proceduresSeleted.map((procedure) => procedure.id);

  useEffect(() => {
    ProcedureBoxService.getDocumentsToCosignByProcedure(procedureIds).then((res) => {
      setData(res);
      setProcedureSelected(res.procedures[0].procedure_id);
      const documentsFromProcedures = res.procedures.map((p) => p.documents);
      setDocuments(documentsFromProcedures.flat());
    });
  }, [proceduresSeleted]);

  useEffect(() => {
    if (data) {
      const allDocuments = data.procedures.map((el) => el.documents.map((doc) => doc));
      setDocuments(documents.filter((doc) => allDocuments.flat().includes(doc)));
    }
  }, [data]);

  useEffect(() => {
    props.onChange(documents);
  }, [documents]);

  const handleClick = (doc: Document) => {
    if (documents.includes(doc)) {
      setDocuments(documents.filter((el) => el.id !== doc.id));
    } else {
      setDocuments([...documents, doc]);
    }
  };

  const documentsByProcedure = (id?: number, status?: 'selected') => {
    if (data) {
      const procedure = data.procedures.find((p) => p.procedure_id === id);
      return procedure ? status ? procedure.documents.filter((doc) => documents.includes(doc)) : procedure.documents : [];
    }
    return [];
  };

  const documentsToShow = () => {
    if (data && procedureSelected) {
      return documentsByProcedure(procedureSelected);
    }
    return [];
  };

  const selectAll = () => {
    const newList = [...documents, documentsToShow()];
    setDocuments(Array.from(new Set(newList.flat())));
  };

  const unselectAll = () => {
    const idsToRemove = documentsToShow().map((el) => el);
    const newList = documents.filter((el) => !idsToRemove.includes(el));
    setDocuments(newList);
  };

  return (
    <div className={ styles.container }>
      <div className={styles.header}>
        <span className={styles.title}>{proceduresSeleted.length > 1
          ? t('procedureBox.actions.sign_document.modal.titleListManyProcedures')
          : t('procedureBox.actions.sign_document.modal.titleList')}
        </span>
      </div>
      <Show if={proceduresSeleted.length > 1}>
        <div className={styles.list}>
          {
            data && data.procedures.map((procedure) => (
              <Show if={procedureIds.includes(procedure.procedure_id)}>
                <a
                  onClick={() => setProcedureSelected(procedure.procedure_id)}
                  className={procedureSelected === procedure.procedure_id ? styles.selected : ''}
                >
                  {procedure.num}
                </a>
              </Show>
            ))
          }
        </div>
      </Show>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>
                <SelectionBoxMenu
                  onClickAll={() => selectAll()}
                  onClickNone={() => unselectAll()}
                  selectedCount={documentsByProcedure(procedureSelected, 'selected').length}
                  countTotal={documentsByProcedure(procedureSelected).length}
                />
              </th>
              <th>{t('procedureBox.actions.sign_document.modal.documentList.fileName')}</th>
              <th>{t('procedureBox.actions.sign_document.modal.documentList.date')}</th>
              <th>{t('procedureBox.actions.sign_document.modal.documentList.owner')}</th>
              <th>{t('procedureBox.actions.sign_document.modal.documentList.type')}</th>
            </tr>
          </thead>
          {
            documentsToShow().map((document) => (
              <tbody>
                <td>
                  <CheckBox
                    value={documentsByProcedure(procedureSelected, 'selected').includes(document)}
                    uncheckedColor='var(--dark)'
                    color='var(--primary)'
                    isMarker={false}
                    onClick={() => handleClick(document)}
                  />
                </td>
                <td>
                  <FaFileAlt />
                  <a href={document.url} target='_blank'>
                    {document.options.data.file_name}
                  </a>
                </td>
                <td>
                  <FaCalendarAlt />
                  {Moment(document.attachment_date.toString()).format('DD/MM/YYYY')}
                </td>
                <td>
                  <FaUserAlt />
                  {document.individual_name}
                </td>
                <td>
                  <FaFlag />
                  {document.type}
                </td>
              </tbody>
            ))
          }
        </table>
      </div>
    </div>
  );
}
