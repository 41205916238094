import { useContext, useEffect, useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import moment from 'moment';
import { t } from 'i18next';
import Grid from '@mui/material/Grid';
import Switch from 'react-switch';
import { isValid, parse } from 'date-fns';
import styles from './styles.module.scss';
import { SelectedProcedures } from '../../SelectedProcedures';
import Select from '../../Select';
import { TextArea } from '../../TextArea';
import { Header } from '../../Header';
import { InputText } from '../../InputText';
import { Procedure, ProcedureDeadline } from '../../../@types/model';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { OptionType } from '../../../@types/config';
import { Button } from '../../Button';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import Show from '../../Show';
import { Tooltip } from '../../Tooltip';
import { InputDate } from '../../InputDate';
import { ErrorField } from '../../ErrorField';

type DeadlineFormProps = {
  procedureDeadline: ProcedureDeadline | undefined;
  closeModal: () => void;
  procedure?: Procedure;
};

export function DeadlineForm(props: DeadlineFormProps) {
  const {
    addToasts,
    removeDeadlineProcedures,
    proceduresSeleted,
    addDeadlineToProcedures,
  } = useContext(ProcedureActionsContext);
  const [reason, setReason] = useState<OptionType>(props.procedureDeadline?.reason || { label: '', value: 0 });
  const [reasonList, setReasonList] = useState<OptionType[]>([]);
  const [dateValue, setDateValue] = useState('');
  const [description, setDescription] = useState(props.procedureDeadline?.description || '');
  const [title, setTitle] = useState(props.procedureDeadline?.title || '');
  const [enabled, setEnabled] = useState(false);
  const [checked, setChecked] = useState(true);
  const [attorney, setAttorney] = useState<OptionType>({ label: '', value: 0 });
  const [attorneyList, setAttorneyList] = useState<OptionType[]>([]);
  const [court, setCourt] = useState<OptionType>({ label: '', value: 0 });
  const [courtList, setCourtList] = useState<OptionType[]>([]);
  const [dateError, setDateError] = useState('');

  const exibitionTranslate = 'procedureBox.procedureDeadline';
  const deadlineDate = props.procedureDeadline?.deadline
    ? moment(props.procedureDeadline?.deadline).format('DD/MM/YYYY HH:mm')
    : '';

  const handleSetDate = (newValue: any) => {
    if (newValue.length <= 0) return (setEnabled(false), setDateError(''));
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})$/;
    if (!dateRegex.test(newValue)) return false;

    const today = new Date();
    today.setDate(today.getDate() - 1);

    const formattedValue = newValue.replace(dateRegex, '$3-$2-$1T$4:$5');

    const selectedDate = parse(formattedValue, "yyyy-MM-dd'T'HH:mm", new Date());

    const validate = isValid(selectedDate) && selectedDate >= today;
    setDateError(validate ? '' : t(`${exibitionTranslate}.dateError`));

    return setDateValue(validate ? moment(selectedDate).format('DD/MM/YYYY HH:mm') : '');
  };

  const loadReasons = async () => {
    const response = await ProcedureBoxService.getDeadlineReason(proceduresSeleted[0]?.id, proceduresSeleted[0]?.box);
    const reasons = response.data.reason;
    setReasonList(reasons);
    if (props.procedureDeadline) {
      const value = reasons.find((item: OptionType) => item.label === props.procedureDeadline?.reason);
      setReason(value);
    }
  };

  const loadAttorneys = async () => {
    if (props.procedure) {
      const response = await ProcedureBoxService.getAttorneys(props.procedure.flux.id);
      const attorneys = response;
      setAttorneyList(attorneys);
    }
  };

  const loadCourts = async () => {
    const response = await ProcedureBoxService.getCourts();
    const courts = response;
    setCourtList(courts);
  };

  const handleRemoveDeadline = () => {
    const procedures = props.procedure ? [props.procedure] : proceduresSeleted;
    removeDeadlineProcedures(procedures, props.procedureDeadline?.id);
    props.closeModal();
  };

  const getDate = () => {
    if (dateValue.length > 0) {
      return dateValue;
    }
    return props.procedureDeadline?.deadline || '';
  };

  const isOneProcedure = () => {
    if (props.procedure && (props.procedureDeadline !== undefined || proceduresSeleted.length <= 1)) {
      return [props.procedure];
    }

    return proceduresSeleted;
  };

  const handleSubmit = () => {
    const attorneySelected = attorney.value;
    const courtSelected = court.value;
    const deadline: ProcedureDeadline = {
      deadline: getDate(),
      reason,
      title,
      description,
      attorney_id: attorneySelected,
      courtSelected,
      ...({ public: checked }),
    };

    if (isOneProcedure()[0].process_number === t(`${exibitionTranslate}.noProcedureNumber`)) {
      addToasts({
        type: 'error',
        text: t(`${exibitionTranslate}.hearingError`),
      });
    } else {
      addDeadlineToProcedures(isOneProcedure(), deadline, props.procedureDeadline);
    }

    props.closeModal();
  };

  useEffect(() => {
    const checkReason = reason.label ? reason.label.length > 0 : false;
    const checkDate = dateValue.length > 0 && dateError.length === 0;
    const checkTitle = title.length > 0;
    setEnabled(checkReason && checkDate && checkTitle);
  }, [dateValue, title, reason]);

  useEffect(() => {
    if (reason.value === 1) {
      loadAttorneys();
      loadCourts();
      if (props.procedureDeadline?.event_params?.attorney_data) {
        const attorneyData: OptionType = props.procedureDeadline?.event_params?.attorney_data;
        setAttorney(attorneyData);
      }
      if (props.procedureDeadline?.event_params?.court_data) {
        const courtData: OptionType = props.procedureDeadline?.event_params?.court_data;
        setCourt(courtData);
      }
    }
    setChecked(!(props.procedureDeadline && props.procedureDeadline.individual_id));
  }, [reason]);

  useEffect(() => {
    loadReasons();
    if (props.procedureDeadline) {
      setDateValue(moment(props.procedureDeadline.deadline).format('DD/MM/YYYY HH:mm'));
    }
  }, []);

  return (
    <>
      <SelectedProcedures />
      <div className={ styles.container }>
        <div className={styles.header}>
          <Header text={t('procedureBox.procedureDeadline.title')} />
        </div>
        <Grid
          container
          columns={{ xs: 1, sm: 10, md: 10 }}
          className={styles.content}
        >
          <Grid
            item
            xs={1}
            sm={6}
            md={6}
            className={styles.select}
          >
            <Select
              dataCy={'cypress-select-deadline-reason'}
              options={reasonList}
              label={t('procedureBox.procedureDeadline.reason')}
              placeholder={t('procedureBox.procedureDeadline.reasonPlaceholder')}
              value={reason}
              onChange={(value) => {
                if (value) {
                  setReason(value);
                }
              }}
              required
            />
          </Grid>
          <Show if={reason.value === 1}>
            <Grid
              item
              xs={1}
              sm={6}
              md={6}
              className={styles.select}
            >
              <Select
                dataCy={'cypress-select-deadline-attorney'}
                options={attorneyList}
                label={t('procedureBox.procedureDeadline.attorney')}
                placeholder={t('procedureBox.procedureDeadline.attorneyPlaceholder')}
                value={attorney && attorney}
                onChange={(value) => {
                  if (value) {
                    setAttorney(value);
                  }
                }}
                required
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={6}
              md={6}
              className={styles.select}
            >
              <Select
                dataCy={'cypress-input-deadline-court'}
                options={courtList}
                label={t('procedureBox.procedureDeadline.court')}
                placeholder={t('procedureBox.procedureDeadline.courtPlaceholder')}
                value={court && court}
                onChange={(value) => {
                  if (value) {
                    setCourt(value);
                  }
                }}
                required
              />
            </Grid>
          </Show>
          <Grid
            item
            xs={1}
            sm={6}
            md={6}
            className={styles.select}
          >
            <InputText
              dataCy={'cypress-input-deadline-title'}
              label={t('procedureBox.procedureDeadline.inputTitle')}
              placeholder={t('procedureBox.procedureDeadline.inputTitlePlaceholder')}
              onChange={(event) => setTitle(event.target.value)}
              value={title}
              required
            />
          </Grid>
        <Grid
          item
          xs={1}
          sm={10}
          md={10}
          className={styles.observation}
        >
          <TextArea
            dataCy={'cypress-textarea-deadline-description'}
            label={t('procedureBox.procedureDeadline.description')}
            placeholder={t('procedureBox.procedureDeadline.descriptionPlaceholder')}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={3}
          md={3}
          className={styles.select}
        >
          <InputDate
            datetime
            dataCy={'cypress-inputdate-deadline'}
            label={t('procedureBox.procedureDeadline.deadline')}
            value={dateValue || deadlineDate}
            onChange={(newValue) => handleSetDate(newValue)}
            required
          />
          <ErrorField text={dateError} />
        </Grid>
        <Grid
          item
          xs={1}
          sm={10}
          md={10}
          className={styles.visibility}
        >
          <Switch
            onClick={() => setChecked(!checked)}
            checked={checked}
            onChange={() => setChecked(!checked)}
            offColor='#e8e8e8'
            onColor='#1eb1e7'
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            width={32}
          />
          <span>{t(`${exibitionTranslate}.${checked ? 'public' : 'private'}`)}</span>
          <Tooltip
            title={`${t('procedureBox.procedureDeadline.tooltipVisibility')}`}
            placement='right'
          >
            <div className={styles.tooltip}><FaRegQuestionCircle /></div>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={1}
          sm={10}
          md={10}
          className={styles.submit}
        >
          <Show if={Boolean(props.procedureDeadline)}>
            <Button
              title={t('procedureBox.procedureDeadline.delete')}
              textCenter
              round
              buttonType='default'
              size='flat'
              disabled={false}
              onClick={() => handleRemoveDeadline()}
            />
          </Show>
          <Button
            title={t('procedureBox.procedureDeadline.cancel')}
            textCenter
            round
            buttonType='default'
            size='flat'
            onClick={() => props.closeModal()}
          />
          <Button
            dataCy={'cypress-submit-button-deadline'}
            title={props.procedureDeadline
              ? t('procedureBox.procedureDeadline.update')
              : t('procedureBox.procedureDeadline.submit')
            }
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={!enabled}
            onClick={() => handleSubmit()}
          />
        </Grid>
      </Grid>
    </div>
    </>
  );
}
