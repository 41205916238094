import { Tooltip } from '@mui/material';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useState } from 'react';
import { t } from 'i18next';
import { Button } from '../../../components/Button';
import styles from './styles.module.scss';
import { Label } from '../../../components/Label';
import { OptionType } from '../../../@types/config';
import Show from '../../../components/Show';

type EitherProps = {
  id: string;
  title: string,
  tooltip?: string,
  itens: OptionType[],
  onClick: (value: any) => any;
  defaultValue: any;
};

export function EitherFilter(props: EitherProps) {
  const [selected, setSelected] = useState(props.defaultValue);

  const onClick = (value: any) => {
    props.onClick(value);
    setSelected(value);
  };

  return (
    <div>
      <div className={styles.info}>
        <Label text={props.title} />
          {
            <Show if={!!props.tooltip}>
              <Tooltip title={ props.tooltip || '' }>
                <div className={styles.containerTag}>
                  <FaRegQuestionCircle />
                </div>
              </ Tooltip>
            </Show>
          }
      </div>
      <div className={`${styles.container}`}>
        {
          props.itens.map((i: OptionType) => (
            <Button
              title={t(i.label)}
              type='button'
              buttonType='default'
              size='small'
              round
              onClick={() => onClick(i.value)}
              selected={selected === i.value}
            />
          ))
        }
      </div>
    </div>
  );
}
