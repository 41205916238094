import { ImShuffle } from 'react-icons/im';
import {
  FaRegCircle,
  FaReply,
  FaPaperPlane,
  FaPaperclip,
  FaFileAlt,
  FaArchive,
  FaTrashAlt,
  FaEdit,
  FaPenNib,
  FaBoxOpen,
  FaQuoteRight,
  FaFileExcel,
} from 'react-icons/fa';
import { Step } from '../@types/digitalProcesses/timeline';

export default function IconSelections(step: Step) {
  const activityId = step.list_activity_id;
  const registerActivities = [1, 8, 16, 21, 22, 24, 25, 26, 27, 29, 31, 32, 33, 35, 37, 38, 42, 44, 45, 55, 58, 60];
  const signActivities = [9, 14];
  const archiveActivities = [10, 17, 59, 64];

  if (step.code === 'SCS0001') {
    return (<FaFileExcel />);
  }

  if (registerActivities.includes(activityId)) {
    return (<FaRegCircle />);
  }
  if (signActivities.includes(activityId)) {
    return (<FaPenNib />);
  }
  if (archiveActivities.includes(activityId)) {
    return (<FaArchive />);
  }
  switch (activityId) {
    case 2:
      return (<FaFileAlt />);
    case 3:
      return (<FaPaperclip />);
    case 4:
      return (<FaPaperPlane />);
    case 41:
      return (<FaQuoteRight />);
    case 62:
      return (<FaTrashAlt />);
    case 63:
      return (<FaEdit />);
    case 65:
      return (<FaBoxOpen />);
    case 66:
      return (<ImShuffle />);
    default:
      <FaReply />;
  }
  return (
    <FaReply />
  );
}
