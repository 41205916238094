import * as React from 'react';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Grid, TableHead } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { FaPen, FaPlus } from 'react-icons/fa';
import { Avatar } from '../../../components/ProcedureList/Avatar';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';

const StyledTableContainer = styled(TableContainer)(() => ({
  borderRadius: 8,
  border: '1px solid var(--gray3)',
  borderBottom: '0px',
}));

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: 'var(--gray2)',
    paddingTop: 6,
    paddingBottom: 6,
    borderBottom: '1px solid rgb(173,171,171)',
    borderRight: '1px solid var(--gray3)',
  },
  [`&.${tableCellClasses.head}`]: {
    fontSize: 13,
    color: 'var(--gray2)',
    paddingTop: 1,
    paddingBottom: 1,
    borderBottom: '1px solid rgb(173,171,171)',
    borderRight: '1px solid var(--gray3)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

type TableProps = {
  currentResponsbile?: string;
  picture_profile?: string;
  individual_color?: string;
  singleColumn?: boolean;
  wantedRows: {};
  wantedColumns?: string[];
  tableWidth?: number;
  rowTitleWidth?: number;
  rowValueWidth?: number;
  avatarIcon?: boolean;
};

export function Table(props: TableProps) {
  const rows: any[] = [];

  const { openUpdateCategoryInProcess, openCreateCategoryInProcess } = useContext(ProcedureActionsContext);

  function createDataRow(rowTitle: any, processInformation?: ReactNode) {
    return {
      rowTitle, processInformation,
    };
  }

  for (const [key, value] of Object.entries(props.wantedRows)) {
    rows.push(createDataRow(key, value as ReactNode));
  }

  function headerContent() {
    if (props.wantedColumns) {
      return (
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" sx={{ maxWidth: props.rowValueWidth }}>
              {props.wantedColumns.map((head) => { return head; })}
            </StyledTableCell>
          </TableRow>
        </TableHead>
      );
    }
    return [];
  }

  return (
    <StyledTableContainer sx={{ maxWidth: props.tableWidth }}>
      <TableMui sx={{ maxWidth: props.tableWidth }} aria-label="customized table">
        { headerContent() }
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.rowTitle}>
              { !(props.singleColumn)
                && <StyledTableCell component="th" scope="row" sx={{ maxWidth: props.rowTitleWidth }}>
                {row.rowTitle}
              </StyledTableCell>
              }
              {props.singleColumn
                ? <StyledTableCell sx={{ maxWidth: props.rowValueWidth }}>
                    <span className={ styles.responsibleName }>
                      <Avatar
                        name={row.processInformation.name || props.currentResponsbile}
                        color={row.processInformation.color}
                        profile_picture={row.processInformation.profile_picture}
                      />  {row.processInformation.name || props.currentResponsbile}
                    </span>
                  </StyledTableCell>
                : <StyledTableCell align="left" sx={{ maxWidth: props.rowValueWidth }}>
                    <Grid xs>
                      <strong>{row.processInformation || props.currentResponsbile }</strong>
                      { row.rowTitle === t('procedureBox.actions.category.categoryVerify') && (
                        <div className={styles.icons}>
                          <FaPen className={styles.edit} onClick={() => openUpdateCategoryInProcess()}/>
                          <FaPlus className={styles.create} onClick={() => openCreateCategoryInProcess()}/>
                        </div>
                      ) }
                    </Grid>
                  </StyledTableCell>
              }
            </StyledTableRow>
          ))}
        </TableBody>
      </TableMui>
    </StyledTableContainer>
  );
}
