import { ReactNode } from 'react';
import { Item } from '../Item';
import './styles.scss';

type GroupMenuProps = {
  title: string;
  isOpen: boolean;
  isFooter?: boolean;
  items: ItemsProps[];
  closeMenu: () => void;
};

type ItemsProps = {
  text: string;
  icon?: ReactNode;
  href?: string;
  subItems?: ItemsProps[];
};

export function GroupMenu(props: GroupMenuProps) {
  return (
    <div className={`groupMenu ${props.isOpen ? 'groupMenuOpened' : ''}`}>
      <div className='groupMenuContent'>
        { props.isOpen ? <span className={`groupTitle ${props.isFooter ? 'groupTitleOpened' : ''}`}>{props.title}</span> : null }
        { props.items.map((item) => <Item
                                      key={item.text}
                                      item={ Object(item) }
                                      hover={ props.isOpen }
                                      closeMenu={props.closeMenu}
                                    />)}
      </div>
    </div>
  );
}
