import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {
  FaGrinWink,
  FaExclamationTriangle,
  FaInfoCircle,
  FaRegCheckCircle,
  FaBan,
} from 'react-icons/fa';
import './styles.scss';
import { ToastProps } from '../../@types/config';

export function Toast(props: ToastProps) {
  const [open, setOpen] = useState(props.open);
  let icon;

  switch (props.type) {
    case 'default':
      icon = (<FaGrinWink />);
      break;
    case 'warning':
      icon = <FaExclamationTriangle />;
      break;
    case 'info':
      icon = <FaInfoCircle />;
      break;
    case 'success':
      icon = <FaRegCheckCircle />;
      break;
    case 'error':
      icon = <FaBan />;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (props.isCloseToast) {
      setTimeout(() => {
        setOpen(false);
      }, 12000);
    }
  }, []);

  return (
    <Box className='container toastAnimation'>
      <Collapse in={ open } className={`collapse ${props.widthFull ? 'widthFull' : ''}`}>
        <Alert
          className={props.type}
          icon={icon}
          action={props.collapse
            ? <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            : null
          }
          sx={{ mb: 2 }}
          >
          {props.text}
          {props.textLink && <span className='link' onClick={props.action}>{props.textLink}</span>}
        </Alert>
      </Collapse>
    </Box>
  );
}
