import { Grid } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { CreateDocumentInBulk } from '..';
import { Batch, MultipleActionStep } from '../../../@types/backgroundActions';
import { Errors, OptionType, SelectGroupOption } from '../../../@types/config';
import { DocumentVariables } from '../../../@types/createDocument';
import { BackgroundActionsContext } from '../../../context/backgroundActions';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Button } from '../../Button';
import { CheckBox } from '../../CheckBox';
import { InputText } from '../../InputText';
import { SelectGroup } from '../../SelectGroup';
import { CurrentUser } from '../../../@types/currentUser';
import Show from '../../Show';
import { Toast } from '../../Toast';
import styles from './styles.module.scss';
import Format from '../../../helpers/format';
import Select from '../../Select';
import { CkEditor } from '../CkEditor';
import { orderOptionType } from '../../../helpers/strings';
import { CreateOptionModal } from '../../CreateOptionModal';

type EditProps = {
  closeModal: () => void;
  description: string;
  documentType: string;
  content: string;
  contentInBulk: CreateDocumentInBulk[];
  template: string;
  waiting?: boolean;
  isEdit: boolean;
  options: SelectGroupOption[];
  documentVariables: DocumentVariables[];
  onChangeDescription: (description: string) => void;
  onChangeType: (type: string) => void;
  onChangeContent: (content: string) => void;
  onChangeTemplate: (template: string) => void;
  onChangeTemplateName: (templateName: string) => void;
  onEditTemplate:(item: SelectGroupOption) => void;
  onDeleteTemplate:(id: number) => void;
  loadTemplates: () => void;
  multipleActions?: MultipleActionStep;
  reload?: boolean;
};

export function Edit(props: EditProps) {
  const errorsCharacteres = ['\'', '/', '#', '~', '.', '%', '*', ':', '<', '>', '?', '\\', '|', '`', '^', '´'];
  const { setAlert, proceduresSeleted, removeProcedures } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [alertMessage, setAlertMessage] = useState<string>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [agreeShare, setAgreeShare] = useState(false);
  const [agreeVisible, setAgreeVisible] = useState(false);
  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertType, setAlertType] = useState<'info' | 'success' | 'error'>('info');
  const isPetition = parseInt(props.template, 10) === 60;
  const [filteredData, setFilteredData] = useState<OptionType[]>([]);
  const documentType = filteredData.find((item) => item.value === props.documentType);
  const petitionType = filteredData.filter((type) => type.value === '9500')[0];
  const [newDocumentTypeName, setDocumentTypeName] = useState('');
  const [errors, setErrors] = useState<Errors | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
  const [proceedingData, setProceedingData] = useState<OptionType[]>([]);
  const [proceeding, setProceeding] = useState<OptionType | undefined>();

  useEffect(() => {
    ProcedureBoxService.getDocumentTypes().then((res) => {
      setFilteredData(orderOptionType(res));
    });
  }, [open]);

  useEffect(() => {
    ProcedureBoxService.getProceedings().then((res) => {
      const proceedingTemp = res.map((option: any) => {
        const obj = { label: `${option.description} - ${option.score}`, value: option.id };
        return obj;
      });
      setProceedingData(proceedingTemp);
    });
  }, []);

  const handleAlert = (display: boolean, type: 'info' | 'success' | 'error') => {
    setAlertType(type);
    setDisplayAlert(display);
  };

  const handleSaveModel = () => {
    if (props.isEdit) {
      ProcedureBoxService.updateDocumentTemplate(
        props.description,
        props.content,
        Number(props.template),
      ).then((res) => {
        handleAlert(true, 'success');
        props.loadTemplates();
        props.onChangeTemplate('empty');
      }).catch(() => {
        handleAlert(true, 'error');
      });
    } else {
      ProcedureBoxService.saveModel(
        props.description,
        props.content,
      ).then((res) => {
        handleAlert(true, 'success');
        props.loadTemplates();
        props.onChangeTemplate('empty');
      }).catch(() => {
        handleAlert(true, 'error');
      });
    }
  };

  const handleCancel = () => {
    props.onChangeTemplate('empty');
    props.onChangeDescription('');
    setAgreeShare(false);
    setAgreeVisible(false);
  };

  const handleTemplate = (val: any) => {
    if (val === 'empty') {
      props.onChangeContent('');
    }

    if (val === 60) {
      props.onChangeType('9500');
    }
    props.onChangeTemplate(val);
  };

  const renderTitle = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.prepareDocument.modal.modal_alert.procedures.title', { number: proceduresSeleted.length });
    }
    return t('procedureBox.actions.prepareDocument.modal.modal_alert.procedure.title');
  };

  const renderSubtitle = () => {
    if (proceduresSeleted.length > 1) {
      return t('procedureBox.actions.prepareDocument.modal.modal_alert.procedures.subtitle');
    }
    return (
      <Trans
        i18nKey='procedureBox.actions.prepareDocument.modal.modal_alert.procedure.subtitle'
        components={{ a: <a /> }}
        values={{ procedure_number: proceduresSeleted[0].process_number }}
      />
    );
  };

  const handleAlertMessage = () => {
    if (alertType === 'info') {
      return (
        <Trans
          i18nKey='procedureBox.actions.prepareDocument.modal.toast.info'
          components={{ bold: <strong />, a: <a /> }}
        />
      );
    }
    return t(`procedureBox.actions.prepareDocument.modal.toast.${alertType}`);
  };

  const handleSave = () => {
    const procedures = proceduresSeleted;
    const type = 'prepareDocument';
    const body = {
      in_bulk: procedures.length > 1,
      title: Format.removeSpecialCharacters(props.description),
      content: props.content,
      documentType: Number(props.documentType),
      contentInBulk: props.contentInBulk,
      template: props.template,
      waiting: props.waiting,
      proceedingId: proceeding?.value,
    };
    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
      multipleActions: props.multipleActions,
      reload: Boolean(props.reload),
    };
    addBatch(batch);
    if (!props.multipleActions) {
      removeProcedures(procedures);
    }
    setAlert(undefined);
    props.closeModal();
  };

  const alertToInvalidCharacter = (value: string) => {
    if (errorsCharacteres.some((e) => value.includes(e))) {
      setAlertMessage(t('procedureBox.actions.prepareDocument.modal.form.characterError'));
      setIsDisabled(true);
    } else {
      setAlertMessage('');
      setIsDisabled(false);
    }
    props.onChangeDescription(value);
  };

  const showAlert = () => {
    setAlert({
      visible: true,
      handleConfirm: () => handleSave(),
      title: renderTitle(),
      text: renderSubtitle(),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  const handleOnClick = () => {
    if (proceduresSeleted.length > 1) {
      showAlert();
    } else {
      handleSave();
    }
  };

  const handleDocumentType = () => {
    ProcedureBoxService.createDocumentType(newDocumentTypeName).then((res) => {
      const newDocumentTypeList = [...filteredData, res];
      setFilteredData(orderOptionType(newDocumentTypeList));
      setOpen(false);
    }).catch((err: any) => {
      setErrors({ new_document_type: [err.data.error] });
    });
  };

  const changeProceeding = (e: any) => {
    if (e) {
      setProceeding(e);
    }
  };

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 8 }}
      spacing={2}
    >
      <CreateOptionModal
        loading={false}
        errors={errors}
        errorField={'new_document_type'}
        open={open}
        setOpen={setOpen}
        setValue={setDocumentTypeName}
        inputLabel={t('procedureBox.actions.prepareDocument.modal.newDocumentType.title')}
        buttonLabel={t('procedureBox.actions.prepareDocument.modal.newDocumentType.button')}
        handleOnClick={handleDocumentType}
      />
      <Show if={displayAlert}>
        <Grid item xs={4} sm={8} md={8}>
          <Toast
            type={alertType}
            collapse
            text={handleAlertMessage()}
            open
          />
        </Grid>
      </Show>
      <Grid item xs={4} sm={4} md={4}>
        <SelectGroup
          label={t('procedureBox.actions.prepareDocument.modal.form.selectGroup.template')}
          tooltip={t('procedureBox.actions.prepareDocument.modal.form.tooltip.modelInfo')}
          required
          actionMenu
          options={props.options}
          value={props.template || 'empty'}
          onChange={(val) => handleTemplate(val)}
          handleDelete={(template) => props.onDeleteTemplate(template.value)}
          handleEdit={(template) => props.onEditTemplate(template)}
        />
      </Grid>
      <Show if={props.template === 'newTemplate' || props.isEdit}>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.prepareDocument.modal.form.name')}
            onChange={(event) => props.onChangeDescription(event.target.value)}
            value={props.description}
            tooltip={t('procedureBox.actions.prepareDocument.modal.form.tooltip.modelName')}
            placeholder={t('procedureBox.actions.prepareDocument.modal.form.placeholder')}
            required
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <Show if={props.documentVariables.length > 0}>
            <CkEditor
              variables={props.documentVariables}
              document={props.content}
              displayAlert={(display, type) => handleAlert(display, type)}
              onChangeText={(text) => props.onChangeContent(text)}
            />
          </Show>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <div className={styles.checkbox}>
            <div>
              <CheckBox
                value={agreeShare}
                uncheckedColor='var(--dark)'
                color='var(--primaryHover)'
                isMarker={false}
                onClick={() => setAgreeShare(!agreeShare)}
              />
            </div>
            <p>{t('procedureBox.actions.prepareDocument.modal.form.agreeShare')}</p>
          </div>
          <div className={styles.checkbox}>
            <div>
              <CheckBox
                value={agreeVisible}
                uncheckedColor='var(--dark)'
                color='var(--primaryHover)'
                isMarker={false}
                onClick={() => setAgreeVisible(!agreeVisible)}
              />
            </div>
            <p>{t('procedureBox.actions.prepareDocument.modal.form.agreeVisible')}</p>
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={8}
          className={styles.submit}
        >
          <Button
            title={t('procedureBox.actions.prepareDocument.modal.form.cancel')}
            textCenter
            round
            buttonType='default'
            size='flat'
            disabled={false}
            onClick={handleCancel}
          />

          <Button
            title={t('procedureBox.actions.prepareDocument.modal.form.save')}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={!(agreeShare && agreeVisible && props.description.length > 0 && props.content.length > 0)}
            onClick={handleSaveModel}
          />
        </Grid>
      </Show>
      <Show if={(props.template !== 'newTemplate' && !props.isEdit)}>
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            label={t('procedureBox.actions.prepareDocument.modal.form.docName')}
            onChange={(event) => alertToInvalidCharacter(event.target.value)}
            value={props.description}
            tooltip={t('procedureBox.actions.prepareDocument.modal.form.tooltip.documentName')}
            placeholder={t('procedureBox.actions.prepareDocument.modal.form.docPlaceholder')}
            required
          />
          <span className={styles.alert}>{alertMessage}</span>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <div className={currentUser.can_create_subject ? styles.inputWrapper : styles.select }>
            <div className={styles.selectContainer}>
              <Select
                label={t('procedureBox.actions.prepareDocument.modal.form.list.type')}
                options={filteredData}
                value={isPetition ? petitionType : documentType}
                placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.typePlaceholder')}
                onChange={(e) => {
                  props.onChangeType(isPetition ? '9500' : e?.value);
                }}
              />
            </div>
            <Show if={!!currentUser.can_create_subject}>
              <div
                onClick={() => setOpen(true)}
                className={styles.plusIcon}
              >
                <FaPlus />
              </div>
            </Show>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <div className={styles.selectContainer}>
            <Select
              label={'Tipo de procedimento'}
              tooltip={t('procedureBox.actions.prepareDocument.modal.form.list.proceeding.tooltip')}
              options={proceedingData}
              placeholder={t('procedureBox.actions.prepareDocument.modal.form.list.typePlaceholder')}
              onChange={(e) => { changeProceeding(e); }}
            />
          </div>
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          <CkEditor
            document={props.content}
            variables={[]}
            onChangeText={(text) => props.onChangeContent(text)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={8}
          md={8}
          className={styles.submit}
        >
          <Button
            title={t('procedureBox.actions.prepareDocument.modal.title')}
            textCenter
            round
            buttonType='primary'
            size='flat'
            disabled={props.description.length < 3 || isDisabled || props.documentType === undefined || props.documentType.length < 1}
            onClick={handleOnClick}
          />
        </Grid>
      </Show>
    </Grid>
  );
}
