import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Autocomplete, StyledEngineProvider, TextField } from '@mui/material';
import { FaChevronDown, FaRegQuestionCircle } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { Label } from '../Label';
import './styles.scss';
import { SelectGroupOption } from '../../@types/config';
import { Tooltip } from '../Tooltip';
import Show from '../Show';
import { ItemMenu } from './ItemMenu';

type SelectGroupProps = {
  label: string;
  required?: boolean;
  actionMenu?: boolean;
  placeholder?: string;
  tooltip?: string;
  options: SelectGroupOption[];
  value: any;
  actionForm?: string | undefined;
  onChange?: (value: any) => void;
  handleEdit?: (option: SelectGroupOption) => void;
  handleDelete?: (option: SelectGroupOption) => void;
  dataCy?: string;
};

export function SelectGroup(props: SelectGroupProps) {
  const [selectStatus, setSelectStatus] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [itemMenu, setItemMenu] = useState<boolean>(false);
  const handleItemClick = (
    item: string,
    option: SelectGroupOption,
    id: number,
  ) => {
    if (item === 'Editar' && props.handleEdit) {
      props.handleEdit(option);
    }
    if (item === 'Excluir' && props.handleDelete) {
      props.handleDelete(option);
    }
  };
  const handleChange = (val: any) => {
    if (props.onChange) {
      props.onChange(val);
    }
  };

  const renderMenu = (
    option: SelectGroupOption | undefined,
    optionProps: any,
  ) => {
    if (option?.menu) {
      return (
        <div id="optionItem" className="optionItem">
          <MenuItem onClick={(val) => handleChange(val)} value={option.value} {...optionProps}>
            {option.name}
          </MenuItem>
          <div>
            <ItemMenu
              option={option}
              handleOnClick={(type, item, id) => handleItemClick(type, item, id)}
              isOpen={setItemMenu}
            />
          </div>
        </div>
      );
    }
    return (
      <MenuItem className="optionItem" {...optionProps}>
        {option?.name}
      </MenuItem>
    );
  };

  let groupName: string = '';

  const options = props.options
    .map((option) => {
      if (option.header) {
        groupName = option.name as string;
        return undefined;
      }

      return {
        group: groupName,
        ...option,
      };
    })
    .filter((option) => option !== undefined);

  const inputValueConst = options.filter(
    (option) => option?.value === props.value,
  )[0];

  useEffect(() => {
    if (selectStatus) {
      setInputValue('');
    } else {
      setInputValue(`${inputValueConst?.name}`);
    }
  }, [inputValueConst, setSelectStatus]);

  const returnInputValue = () => {
    return (
      props.actionForm
        ? (props.actionForm === 'edit' ? inputValue : undefined)
        : undefined
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <div className="header">
        <Label text={props.label} required={props.required} />
        <Show if={!!props.tooltip}>
          <Tooltip title={props.tooltip || ''}>
            <FaRegQuestionCircle />
          </Tooltip>
        </Show>
      </div>
      <FormControl className="form">
        <Autocomplete
          open={selectStatus}
          value={inputValueConst}
          inputValue={returnInputValue()}
          onOpen={() => {
            setSelectStatus(true);
            setInputValue('');
          }}
          onClose={() => {
            if (!itemMenu) {
              setSelectStatus(false);
            }
          }}
          clearOnBlur
          onChange={(event, option) => handleChange(option?.value)}
          popupIcon={<FaChevronDown size={13} />}
          options={options}
          groupBy={(option) => option?.group || ''}
          getOptionLabel={(option) => (option?.name as string) || ''}
          data-cy={props.dataCy}
          disableListWrap
          classes={{
            input: 'inputContainer',
            inputRoot: 'inputRoot',
            endAdornment: 'popupIndicator',
            groupLabel: 'groupLabel',
          }}
          renderOption={(optionProps, option) => (props.actionMenu && selectStatus ? (
            renderMenu(option, optionProps)
          ) : (
              <MenuItem {...optionProps}>{option?.name}</MenuItem>
          ))
          }
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </StyledEngineProvider>
  );
}
