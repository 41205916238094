import List from '@mui/material/List';
import Menu from '@mui/material/Menu';
import {
  ReactNode, useState, MouseEvent, useContext,
} from 'react';
import { QueryContext } from '../../../context/queryContext';
import { Item } from '../Item';

type DropDownProps = {
  selected: string;
  data: ItemProps[];
};

type ItemProps = {
  icon: ReactNode,
  text: string,
  counter: {
    number: number;
    text: string;
  }[];
  selected: string,
  greenNumber: boolean,
};

export function DropDown(props: DropDownProps) {
  const { setBox, box } = useContext(QueryContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState(props.selected);
  const open = Boolean(anchorEl);
  const data = props.data.filter((item) => item.selected !== selected);
  const getItemSelected = props.data.find((element) => element.selected === selected);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (item: any) => {
    setSelected(item);
    setBox(item);
    handleClose();
  };

  const handleClickListItem = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div>
      <List
        component="nav"
      >
        <Item
          id={ 'lock-menu' }
          icon={ getItemSelected?.icon }
          text={ getItemSelected?.text || '' }
          counter={ getItemSelected?.counter || [] }
          onClick={ handleClickListItem }
          greenNumber={ getItemSelected?.greenNumber }
          selected
          open={ open }
          dropdown
        />
      </List>
      <Menu
        id="lock-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        PaperProps={{
          style: {
            transform: 'translateX(-6px)',
            maxWidth: '100%',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
          sx: { width: anchorEl && anchorEl.offsetWidth, padding: 0 },
        }}
      >
        { data.map((option, index) => (
          <Item
            key={ index }
            icon={ option.icon }
            text={ option.text }
            counter={ option.counter }
            selected={ box === option.selected }
            onClick={ () => { handleOnClick(option.selected); } }
            greenNumber={ option.greenNumber }
            dropdown
          />
        ))}
      </Menu>
    </div>
  );
}
