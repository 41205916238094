import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { CheckBox } from '../../../../CheckBox';
import styles from './styles.module.scss';
import { maskCpfOrCnpj } from '../../../../../helpers/masks';

type ApplicantsProps = {
  id: number;
  name: string;
  document: string;
  isMobile: boolean;
  onClick: (id: number, checked: boolean) => void;
};

export function Applicants(props: ApplicantsProps) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    props.onClick(props.id, checked);
  }, [checked]);

  return (
    <div className={`${styles.content} ${props.isMobile && styles.contentMobile}`}>
      <CheckBox
        onClick={() => setChecked(!checked)}
        color={'var(--primary)'}
        value={checked}
        isMarker={checked}
      />

     <div>
        <div className={styles.name}>
          <span>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.applicantsList.beneficiaryName')}</span>
          <div>
            <span>{props.name}</span>
          </div>
        </div>

        <div className={styles.cpf}>
          <span>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.applicantsList.document')}</span>
          <div>
            <span>{maskCpfOrCnpj(props.document).mask()}</span>
          </div>
        </div>
     </div>
    </div>
  );
}
