import { ReactNode, useState } from 'react';
import { Sidebar } from '../Sidebar';
import { Topbar } from '../Topbar';
import styles from './styles.module.scss';
import ModalProvider from '../../context/homeContext';
import { Alert } from '../Profile/Alert';
import { Modal } from '../Profile/Modal';
import { ToastsList } from '../ToastsList';

type LayoutProps = {
  children?: ReactNode
};

export function Layout(props: LayoutProps) {
  const [sidebarHovered, setSidebarHovered] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  return (
    <ModalProvider>
      <div className={ styles.container }>
        <Sidebar
          onHover={ (e) => setSidebarHovered(e) }
          setOpenMobile={ (e) => setOpenMobile(e) }
          openMobile={ openMobile }
          onClose={ (e) => setOpenMobile(e)}
        />
        <div className={ `${styles.contentTopbar} ${sidebarHovered ? styles.opened : ''}` }>
          <Topbar openMobile={ openMobile } setOpenMobile={ (e) => setOpenMobile(e) }/>
          <ToastsList fromHome />
          { props.children }
        </div>
        <Modal />
        <Alert />
      </div>
    </ModalProvider>
  );
}
