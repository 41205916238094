import { useState } from 'react';
import { t } from 'i18next';
import Switch from 'react-switch';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { RequestData } from '../../../@types/requestCalculation';
import { Button } from '../../Button';
import styles from './styles.module.scss';

type OrderDataProps = {
  content?: RequestData;
  nextStep: (val: any) => void;
  handleForm: (val: any) => void;
};

export function OrderData(props: OrderDataProps) {
  const [generateNumber, setGenerateNumber] = useState(Boolean(props.content?.generateNumber));

  const handleNextStep = () => {
    const data: RequestData = {
      generateNumber,
    };
    props.handleForm(data);
    props.nextStep(data);
  };

  return (
    <div className={styles.container}>
        <div className={styles.generateNumber}>
          <Switch
            onClick={() => setGenerateNumber(!generateNumber)}
            checked={generateNumber}
            onChange={() => setGenerateNumber(!generateNumber)}
            offColor='#ADABAB'
            onColor='#1EB1E7'
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            width={32}
          />
          <Tooltip placement='left-end' title={t('procedureBox.actions.eletronicCalculation.modal.tooltips.generateProcessNumberTooltip') || ''}>
            <p>{t('procedureBox.actions.eletronicCalculation.modal.orderDataContent.processNumber.generateProcessNumber')} <FaRegQuestionCircle className={styles.icon} /></p>
          </Tooltip>
        </div>
      <footer>
        <Button
          title={t('procedureBox.actions.eletronicCalculation.modal.orderDataContent.buttonText')}
          onClick={() => handleNextStep()}
          size='flat'
          buttonType='primary'
          round
        />
      </footer>
    </div>
  );
}
