import {
  FaClipboardList,
  FaDollarSign,
  FaStreetView,
  FaCrosshairs,
  FaCalendarAlt,
  FaBullhorn,
  FaComment,
  FaQuestion,
  FaFolder,
  FaScroll,
  FaUserCog,
  FaSitemap,
  FaFileSignature,
  FaAddressBook,
  FaGavel,
  FaBalanceScale,
  FaLandmark,
  FaCoins,
  FaCog,
  FaSearch,
} from 'react-icons/fa';
import { CurrentUser } from '../@types/currentUser';

const itemsModulesDigitalProcessReports = [
  {
    text: 'sidebar.modules.digital_process.reports.dashboard',
    level: 3,
    href: '/procedures/reports/dashboard',
  },
  {
    text: 'sidebar.modules.digital_process.reports.dashboard_health',
    level: 3,
    href: '/procedures/reports/dashboard_medic',
  },
  {
    text: 'sidebar.modules.digital_process.reports.distribution',
    level: 3,
    href: '/procedures/reports/distribution',
  },
  {
    text: 'sidebar.modules.digital_process.reports.productivity',
    level: 3,
    href: '/procedures/reports/productivity',
  },
  {
    text: 'sidebar.modules.digital_process.reports.petition',
    level: 3,
    href: '/procedures/reports/petitioning',
  },
  {
    text: 'sidebar.modules.digital_process.reports.audience',
    level: 3,
    href: '/procedures/reports/audience',
  },
  {
    text: 'sidebar.modules.digital_process.reports.search_cabinet',
    level: 3,
    href: '/procedures/reports/procedures_cabinet',
  },
  {
    text: 'sidebar.modules.digital_process.reports.search_judicial',
    level: 3,
    href: '/procedures/reports/judicial_procedures',
  },
  {
    text: 'sidebar.modules.digital_process.reports.court_orders',
    level: 3,
    href: '/procedures/reports/payment_orders',
  },
  {
    text: 'sidebar.modules.digital_process.reports.accounting_calculations',
    level: 3,
    href: '/procedures/reports/calculation',
  },
  {
    text: 'sidebar.modules.digital_process.reports.official_letter',
    level: 3,
    href: '/procedures/reports/judicial_orders',
  },
];

const itemsModulesActiveDebtRequeriment = [
  {
    text: 'sidebar.modules.active_debt.requeriment.inscribe',
    level: 3,
    href: '/active_debt/requiriments/approve',
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.request_analysis',
    level: 3,
    href: '/active_debt/requiriments/request',
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.register',
    level: 3,
    href: '/active_debt/requiriments/new',
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.register_edit_contributor',
    level: 3,
    href: '/active_debt/requiriments/new_contributor',
  },
];

const itemsModulesActiveDebtInscription = [
  {
    text: 'sidebar.modules.active_debt.inscription.consult_advanced_debit',
    level: 3,
    href: '/active_debt/inscriptions/search_debit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.extract_debit',
    level: 3,
    href: '/active_debt/inscriptions/extract_debit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.statement_responsibility',
    level: 3,
    href: '/active_debt/inscriptions/statement_responsibility',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.extinguish_suspend_credit',
    level: 3,
    href: '/active_debt/inscriptions/extinguish_suspend_credit',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.ownership_change',
    level: 3,
    href: '/active_debt/inscriptions/ownership_change',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.suspension_extinction_requests',
    level: 3,
    href: '/active_debt/inscriptions/suspension_extinction_requests',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.migration_registered_debts',
    level: 3,
    href: '/active_debt/inscriptions/migration_registered_debts',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.approve_migration_registered_debts',
    level: 3,
    href: '/active_debt/inscriptions/approve_migration_registered_debts',
  },
  {
    text: 'sidebar.modules.active_debt.inscription.retroactive_calculation',
    level: 3,
    href: '/active_debt/inscriptions/retroactive_calculation',
  },
];

const itemsModulesActiveDebtProtest = [
  {
    text: 'sidebar.modules.active_debt.protest.request_protest',
    level: 3,
    href: '/active_debt/protest/request_protest',
  },
  {
    text: 'sidebar.modules.active_debt.protest.abandonment_cancellation',
    level: 3,
    href: '/active_debt/protest/abandonment_cancellation',
  },
];

const itemsModulesActiveDebtJudgment = [
  {
    text: 'sidebar.modules.active_debt.judgment.request',
    level: 3,
    href: '/active_debt/judgment/request',
  },
  {
    text: 'sidebar.modules.active_debt.judgment.forward',
    level: 3,
    href: '/active_debt/judgment/forward',
  },
];

const itemsModulesActiveDebtManagement = [
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis',
    level: 3,
    href: '/active_debt/management/active_debt_diagnosis',
  },
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis_phase',
    level: 3,
    href: '/active_debt/management/active_debt_diagnosis_phase',
  },
  {
    text: 'sidebar.modules.active_debt.management.active_debt_diagnosis_range',
    level: 3,
    href: '/active_debt/management/active_debt_diagnosis_range',
  },
  {
    text: 'sidebar.modules.active_debt.management.geolocation_contributors',
    level: 3,
    href: '/active_debt/management/geolocation_contributors',
  },
  {
    text: 'sidebar.modules.active_debt.management.geolocation_contributors_regions',
    level: 3,
    href: '/active_debt/management/geolocation_contributors_regions',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_municipal_active_debt',
    level: 3,
    href: '/active_debt/management/overview_municipal_active_debt',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_prodat',
    level: 3,
    href: '/active_debt/management/overview_prodat',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_installment_management',
    level: 3,
    href: '/active_debt/management/overview_installment_management',
  },
  {
    text: 'sidebar.modules.active_debt.management.collection_management',
    level: 3,
    href: '/active_debt/management/collection_management',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_judgment',
    level: 3,
    href: '/active_debt/management/overview_judgment',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_protest',
    level: 3,
    href: '/active_debt/management/overview_protest',
  },
  {
    text: 'sidebar.modules.active_debt.management.evolution_debt_stock',
    level: 3,
    href: '/active_debt/management/evolution_debt_stock',
  },
  {
    text: 'sidebar.modules.active_debt.management.overview_phases_debt_inscriptions',
    level: 3,
    href: '/active_debt/management/overview_phases_debt_inscriptions',
  },
];

const itemsModulesActiveDebtReports = [
  {
    text: 'sidebar.modules.active_debt.reports.extract_inscriptions',
    level: 3,
    href: '/active_debt/reports/extract_inscriptions',
  },
  {
    text: 'sidebar.modules.active_debt.reports.general_collection',
    level: 3,
    href: '/active_debt/reports/general_collection',
  },
  {
    text: 'sidebar.modules.active_debt.reports.tax_collection',
    level: 3,
    href: '/active_debt/reports/tax_collection',
  },
  {
    text: 'sidebar.modules.active_debt.reports.collection_charges',
    level: 3,
    href: '/active_debt/reports/collection_charges',
  },
  {
    text: 'sidebar.modules.active_debt.reports.management_view_active_debt',
    level: 3,
    href: '/active_debt/reports/management_view_active_debt',
  },
  {
    text: 'sidebar.modules.active_debt.reports.manage_installments_agreements',
    level: 3,
    href: '/active_debt/reports/manage_installments_agreements',
  },
  {
    text: 'sidebar.modules.active_debt.reports.report_requests_API',
    level: 3,
    href: '/active_debt/reports/report_requests_API',
  },
];

const itemsModulesActiveDebtSettings = [
  {
    text: 'sidebar.modules.active_debt.settings.inform_tax',
    level: 3,
    href: '/active_debt/settings/inform_tax',
  },
  {
    text: 'sidebar.modules.active_debt.settings.calculation_standards',
    level: 3,
    href: '/active_debt/settings/calculation_standards',
  },
  {
    text: 'sidebar.modules.active_debt.settings.blocked_inscriptions',
    level: 3,
    href: '/active_debt/settings/blocked_inscriptions',
  },
  {
    text: 'sidebar.modules.active_debt.settings.refis',
    level: 3,
    href: '/active_debt/settings/refis',
  },
  {
    text: 'sidebar.modules.active_debt.settings.scheduling',
    level: 3,
    href: '/active_debt/settings/scheduling',
  },
];

const itemsModulesDigitalProcess = [
  {
    text: 'sidebar.modules.digital_process.procedure_box',
    icon: <FaFolder/>,
    href: '/procedure_box',
    level: 2,
  },
  {
    text: 'sidebar.modules.digital_process.procedure_collection',
    icon: <FaSearch/>,
    href: '/procedure_collection',
    level: 2,
  },
  {
    text: 'sidebar.modules.digital_process.reports.title',
    icon: <FaScroll/>,
    level: 2,
    subItems: itemsModulesDigitalProcessReports,
  },
];

const itemsModulesActiveDebt = [
  {
    text: 'sidebar.modules.active_debt.debt_box',
    icon: <FaFolder/>,
    href: '/active_debt/debt_box',
    level: 2,
  },
  {
    text: 'sidebar.modules.active_debt.requeriment.title',
    icon: <FaFileSignature/>,
    level: 2,
    subItems: itemsModulesActiveDebtRequeriment,
  },
  {
    text: 'sidebar.modules.active_debt.inscription.title',
    icon: <FaAddressBook/>,
    level: 2,
    subItems: itemsModulesActiveDebtInscription,
  },
  {
    text: 'sidebar.modules.active_debt.protest.title',
    icon: <FaGavel/>,
    level: 2,
    subItems: itemsModulesActiveDebtProtest,
  },
  {
    text: 'sidebar.modules.active_debt.judgment.title',
    icon: <FaBalanceScale/>,
    level: 2,
    subItems: itemsModulesActiveDebtJudgment,
  },
  {
    text: 'sidebar.modules.active_debt.management.title',
    icon: <FaLandmark/>,
    level: 2,
    subItems: itemsModulesActiveDebtManagement,
  },
  {
    text: 'sidebar.modules.digital_process.reports.title',
    icon: <FaScroll/>,
    level: 2,
    subItems: itemsModulesActiveDebtReports,
  },
  {
    text: 'sidebar.modules.active_debt.financial.title',
    icon: <FaCoins/>,
    level: 2,
    href: '/active_debt/financial',
  },
  {
    text: 'sidebar.modules.active_debt.settings.title',
    icon: <FaCog/>,
    level: 2,
    subItems: itemsModulesActiveDebtSettings,
  },
];

export const judicialFluxes = [
  'FLU0003',
  'FLU0004',
  'FLU0028',
  'FLU0060',
  'FLU0061',
  'FLU0033',
  'FLU0020',
  'FLU0095',
  'FLU0013',
];

export const itemsModules = [
  {
    text: 'sidebar.modules.digital_process.title',
    icon: <FaClipboardList/>,
    subItems: itemsModulesDigitalProcess,
    route: '/procedures',
  },
  {
    text: 'sidebar.modules.active_debt.title',
    icon: <FaDollarSign/>,
    subItems: itemsModulesActiveDebt,
    route: '/active_debt',
  },
];

export const itemsSearch = [
  {
    text: 'sidebar.modules.search.detran',
    icon: <FaStreetView/>,
    href: '/search/detran',
  },
  {
    text: 'sidebar.modules.search.to_parts',
    icon: <FaCrosshairs/>,
    href: '/search/to_parts',
  },
];

export const itemsCalendar = [
  {
    text: 'sidebar.modules.calendar',
    icon: <FaCalendarAlt/>,
    href: '/schedule',
  },
];

export const itemsSupport = [
  {
    text: 'sidebar.modules.support.suggestion_box',
    icon: <FaBullhorn/>,
  },
  {
    text: 'sidebar.modules.support.contact_us',
    icon: <FaComment/>,
  },
  {
    text: 'sidebar.modules.support.faq',
    icon: <FaQuestion/>,
    href: '/faq',
  },
];

export const itemsSettings = [
  {
    text: 'sidebar.modules.settings.user_management',
    icon: <FaUserCog/>,
    href: '/settings/user_management',
  },
  {
    text: 'sidebar.modules.settings.fluxes_management',
    icon: <FaSitemap/>,
    href: '/settings/fluxes_management',
  },
];

export const isActualResponsible = (individualName: string | undefined): boolean => {
  if (individualName) {
    const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
    return currentUser.name === individualName;
  }
  return false;
};

export const orderByOptions = [
  {
    label: 'Área (A-Z)',
    value: 'main_field asc',
  },
  {
    label: 'Área (Z-A)',
    value: 'main_field desc',
  },
  {
    label: 'Classificação (A-Z)',
    value: 'process_category_name asc',
  },
  {
    label: 'Classificação (Z-A)',
    value: 'process_category_name desc',
  },
  {
    label: 'Última Verificação (menos recente)',
    value: 'display_last_verification asc',
  },
  {
    label: 'Última Verificação (mais recente)',
    value: 'display_last_verification desc',
  },
];
