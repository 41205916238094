import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import styles from './styles.module.scss';

type FilterButtonProps = {
  icon?: ReactNode;
  hover?: boolean;
  onClick?: () => void;
  isOpen?: boolean;
};

export function FilterButton(props: FilterButtonProps) {
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={`${t('procedureBox.filterAdvanced.label')}`}>
        <div
          className={`${styles.filterButtonContainer} ${props.isOpen ? styles.selected : ''}`}
          onClick={ props.onClick }
        >
          { props.icon }
        </div>
      </Tooltip>
    </>
  );
}
