/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-loop-func */
import { t } from 'i18next';
import {
  createContext,
  FC,
  useState,
  useContext,
  useEffect,
} from 'react';
import {
  FaArchive, FaFileAlt, FaFolder, FaHands, FaPaperclip,
  FaPaperPlane, FaPenNib, FaTag, FaTrashAlt, FaPlus,
  FaEnvelopeOpenText, FaBoxOpen, FaStickyNote, FaListAlt, FaClock,
  FaShoePrints, FaLink, FaSitemap, FaShareAlt, FaQuoteRight, FaEdit,
  FaCog,
} from 'react-icons/fa';
import LinkIcon from '@mui/icons-material/Link';
import { ImShuffle } from 'react-icons/im';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  Alert, AttachDocument as AttachDocumentType, AttachedDocument,
  Modal, ToastProps, TramitRequest, IpmStates, ModalValues,
  OptionType, DataAbleSigner,
} from '../@types/config';
import { CitationIntimation, Procedure, ProcedureDeadline } from '../@types/model';
import { ProcedureActionsType } from '../@types/procedureActions';
import { TramitProcedure } from '../components/TramitProcedure';
import { ChangeFlux } from '../components/ChangeFlux';
import { isDesktop, isFirefox } from '../helpers/browser';
import { QueryContext } from './queryContext';
import { BackgroundActionsContext } from './backgroundActions';
import { Signer } from '../components/Signer';
import ProcedureBoxService from '../services/procedureBoxService';
import { CreateEditMarkerModal } from '../components/CreateEditMarkerModal';
import { AttachDocument } from '../components/AttachDocument';
import { DeleteDocument } from '../components/DeleteDocument';
import { CreateDocument } from '../components/CreateDocument';
import { ProcessesArchives } from '../components/ProcessesArchive';
import { ForceTramit } from '../components/ForceTramit';
import { ForwardIpm } from '../components/ForwardIpm';
import { BoxIcon } from '../components/BoxIcon';
import { SubProcess } from '../components/SubProcess';
import { PetitionInitial } from '../components/Petition/PetitionInitial';
import { PetitionIntermediary } from '../components/Petition/PetitionIntermediary';
import { EletronicCalculation } from '../components/ElectronicCalculation';
import { Spu } from '../components/Spu';
import { RegistrationSpu } from '../components/RegistrationSpu';
import { SpuJudicialDocument } from '../@types/spu';
import { NewProcedure } from '../pages/ProcedureBox/NewProcedure';
import { Unarchive } from '../components/Unarchive';
import { PaymentOrder } from '../components/PaymentOrder';
import { Note } from '../components/Note';
import { MultipleActions } from '../components/MultipleActions';
import { MultipleActionStep } from '../@types/backgroundActions';
import { DeadlineForm } from '../components/Deadline/DeadlineForm';
import { MovementsModal } from '../pages/ShowProcedure/PjeExtract/MovementsModal';
import { UpdateCategory } from '../components/UpdateCategory';
import { CreateCategory } from '../components/CreateCategory';
import { AttachProcedure } from '../components/AttachProcedure';
import { DistributeProcesses } from '../components/DistributeProcesses';
import { ExpandedInterestedParts } from '../components/ExpandedInterestedParts';
import { TotalPerFlux } from '../@types/digitalProcesses/fluxes';
import { ExpandedMarkers } from '../components/ExpandedMarkers';
import { Notice } from '../@types/pje';
import { PendingNoticesModal } from '../pages/ShowProcedure/PjeExtract/PendingNoticesModal';
import { ShareProcedure } from '../components/ShareProcedure';
import { Cosigner } from '../components/Cosigner';
import { UnattachProcedure } from '../components/UnattachProcedure';
import { NewCitationIntimation } from '../pages/ProcedureBox/NewCitationIntimation';
import { AttachCitationIntimation } from '../components/AttachCitationIntimation';
import ActionsService from '../services/actionsService';
import { AttachProcedureCitationIntimation } from '../components/AttachProcedureCitationIntimation';
import { CustomizeColumnsModal } from '../components/CustomizeColumnsModal';
import { sendEvent } from '../helpers/googleAnalytics';

const contextDefaultValues: ProcedureActionsType = {
  proceduresSeleted: [],
  addProcedure: () => {},
  addProcedures: () => {},
  addToasts: () => {},
  removeProcedure: () => {},
  removeProcedures: () => {},
  removeAllProcedures: () => {},
  addDeadlineToProcedures: () => {},
  removeDeadlineProcedures: () => {},
  handleSignModal: () => {},
  handleTramitModal: () => {},
  handleForceTramit: () => {},
  openSigner: () => {},
  openCosigner: () => {},
  openTramit: () => {},
  openDistributeProcesses: () => {},
  openShareProcedure: () => {},
  openChangeFlux: () => {},
  openForceTramit: () => {},
  openAttachFile: () => {},
  openDeleteFile: () => {},
  openUnarchiveModal: () => {},
  openNotesModal: () => {},
  openMultipleActions: () => {},
  openCreateDocument: () => {},
  openForwardIpm: () => {},
  openSpu: () => {},
  openRegistrationSpu: () => {},
  openExpandedMarkersModal: () => {},
  openExpandedInterestedPartsModal: () => {},
  openPaymentOrder: () => {},
  openCreateSubProcess: () => {},
  openArchiveProcesses: () => {},
  openDeleteProcesses: () => {},
  openEletronicCalculation: () => {},
  openInitialPetition: () => {},
  openIntermediaryPetition: () => {},
  openJoinDocuments: () => {},
  openNewProcedure: () => {},
  openNewCitationIntimation: () => {},
  openDeadline: () => {},
  openUpdateCategoryInProcess: () => {},
  openCreateCategoryInProcess: () => {},
  openAttachProcedure: () => {},
  openUnattachProcedure: () => {},
  openAttachCitationIntimation: () => {},
  openAttachProcedureCitationIntimation: () => {},
  openTramitCitationIntimation: () => {},
  openDeleteCitationIntimation: () => {},
  openMarkerModal: (type: 'edit' | 'create') => {},
  setAlert: () => {},
  alert: {
    visible: false,
    handleConfirm: () => {},
  },
  setModal: () => {},
  toasts: [],
  setToasts: () => {},
  modal: {
    visible: false,
    handleClose: () => {},
    title: '',
    size: 'medium',
  },
  isEmpty: false,
  isShowProcedure: false,
  openTramitModal: false,
  openSignModal: false,
  descriptions: [],
  setDescriptions: () => {},
  verify: false,
  setVerify: () => {},
  verifySignatures: undefined,
  setVerifySignatures: () => {},
  ipmStateSelected: '',
  setIpmStateSelected: () => {},
  selectedSubProcess: '',
  setSelectedSubProcess: () => {},
  archives: [],
  setArchives: () => {},
  stateDescription: false,
  setStateDescription: () => {},
  showIconSubProcess: false,
  setShowIconSubProcess: () => {},
  modalValues: {
    checked: false,
    dateValue: '',
    partsInterested: [],
    proceduresSeleted: [],
  },
  setModalValues: () => {},
  handleMultipleActions: () => {},
  openMovements: () => {},
  setFluxAmount: () => {},
  fluxAmount: [],
  openEditProcedure: () => {},
  openNotices: () => {},
  seenNotSeen: () => {},
  openReadyForProgress: () => {},
  citationIntimationSelected: undefined,
  setCitationIntimation: () => {},
  openCustomizeColumns: () => {},
};

export const ProcedureActionsContext = createContext<ProcedureActionsType>(
  contextDefaultValues,
);

const ProcedureActionsProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const [proceduresSeleted, setProcedures] = useState<Procedure[]>(contextDefaultValues.proceduresSeleted);
  const [citationIntimationSelected, setCitationIntimation] = useState<CitationIntimation | undefined>(contextDefaultValues.citationIntimationSelected);
  const {
    procedures, setStateProcedures, loadData, box,
  } = useContext(QueryContext);
  const { procedureOnProgress } = useContext(BackgroundActionsContext);
  const [openTramitModal, handleTramitModal] = useState(false);
  const [openSignModal, handleSignModal] = useState(false);
  const [alert, setAlert] = useState<Alert | undefined>();
  const [modal, setModal] = useState<Modal | undefined>();
  const [modalValues, setModalValues] = useState<ModalValues>(contextDefaultValues.modalValues);
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const isEmpty = proceduresSeleted.length === 0;
  const [archives, setArchives] = useState<any[]>([]);
  const [verify, setVerify] = useState(false);
  const [stateDescription, setStateDescription] = useState<boolean | undefined>(undefined);
  const [verifySignatures, setVerifySignatures] = useState<boolean | undefined>(undefined);
  const [ipmStateSelected, setIpmStateSelected] = useState<IpmStates>('verify');
  const [descriptions, setDescriptions] = useState<any>([]);
  const [selectedSubProcess, setSelectedSubProcess] = useState<'officialData' | 'documents'>('officialData');
  const [showIconSubProcess, setShowIconSubProcess]: any = useState(false);
  const isShowProcedure = pathname.includes('/procedure_box/show_procedure');
  const [fluxAmount, setFluxAmount] = useState<TotalPerFlux[]>([]);

  const addProcedure = (procedure: Procedure) => {
    if (!procedureOnProgress(procedure) && !proceduresSeleted.includes(procedure)) {
      setProcedures([...proceduresSeleted, procedure]);
    }
  };

  const addToasts = (props: ToastProps) => {
    setToasts([...toasts, props]);
  };

  const removeProcedure = (procedure: Procedure) => {
    if (!isShowProcedure) {
      setProcedures(() => proceduresSeleted.filter((item) => item.id !== procedure.id));
    }
  };

  const removeProceduresById = (procedure_ids: number[]) => {
    if (!isShowProcedure) {
      const proceduresTemp = procedure_ids.map((id) => proceduresSeleted.find((p) => p.id === id));
      const difference = proceduresSeleted.filter((x) => !proceduresTemp.includes(x));
      setProcedures(difference);
    }
  };

  const removeProcedures = (proceduresParam: Procedure[]) => {
    if (!isShowProcedure) {
      const difference = proceduresSeleted.filter((x) => !proceduresParam.includes(x));
      setProcedures(difference);
    }
  };

  const removeAllProcedures = () => {
    if (!isShowProcedure) {
      setProcedures([]);
    }
  };

  const addProcedures = (newProcedures: Procedure[]) => {
    setProcedures(newProcedures);
  };

  const addDeadlineToProcedures = async (
    proceduresParam: Procedure[],
    procedureDeadline: ProcedureDeadline,
    selectedDeadline: ProcedureDeadline | undefined,
  ) => {
    try {
      for (let index = 0; index < proceduresParam.length; index += 1) {
        const hearingObj = {
          process_number: proceduresParam[index].process_number,
          procedure_id: proceduresParam[index].id,
          court_id: procedureDeadline?.courtSelected,
          event_schedule: procedureDeadline.deadline,
          event_type: 0,
          individual_id: procedureDeadline?.attorney_id,
        };
        if (selectedDeadline !== undefined && selectedDeadline !== null && selectedDeadline.id !== undefined) {
          const deadlineObj = {
            deadline: procedureDeadline.deadline,
            reason: procedureDeadline.reason.value,
            title: procedureDeadline.title,
            description: procedureDeadline.description,
            public: procedureDeadline.public,
          };

          await ProcedureBoxService.updateDeadlineProcedures(
            selectedDeadline.id,
            {
              procedure_deadline: deadlineObj,
              ...(hearingObj.individual_id !== 0 ? { hearing: { ...hearingObj, event_id: selectedDeadline.event_id } } : { hearing: '' }),
            },
          ).then((res) => {
            if (res.status === 200) {
              addToasts({
                type: 'success',
                text: t('procedureBox.procedureDeadline.updateMessage.success'),
              });
              window.location.reload();
            }
          });
        } else {
          const deadlineObj = {
            deadline: procedureDeadline.deadline,
            reason: procedureDeadline.reason.value,
            title: procedureDeadline.title,
            description: procedureDeadline.description,
            procedure_id: proceduresParam[index].id,
            public: procedureDeadline.public,
          };
          await ProcedureBoxService.addDeadlineToProcedures({
            procedure_deadline: deadlineObj,
            ...(hearingObj.individual_id !== 0 ? { hearing: hearingObj } : { hearing: '' }),
          });
        }
      }
      if (!pathname.includes('/procedure_box/show_procedure')) {
        loadData();
        removeProcedures(proceduresSeleted);
      } else {
        window.location.reload();
      }
    } catch (error: any) {
      if (error.response.data) {
        addToasts({
          type: 'error',
          text: `${error.response.data.error}`,
        });
      } else {
        addToasts({
          type: 'error',
          text: t('procedureBox.procedureDeadline.error'),
        });
      }
    }
  };

  const removeDeadlineProcedures = async (proceduresParam: Procedure[], procedureDeadlineId: number | undefined) => {
    const procedureIds = proceduresParam.map((procedure) => procedure.id);
    const response = await ProcedureBoxService.removeDeadlineProcedures(procedureIds, procedureDeadlineId);
    if (response.status === 200) {
      const newProceduresState = procedures.map((procedure) => {
        if (procedureIds.includes(procedure.id)) {
          return {
            ...procedure,
            procedure_deadline: undefined,
          };
        }
        return procedure;
      });
      setStateProcedures(newProceduresState);
      window.location.reload();
    }
  };

  const openForceTramit = (reason?: string, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      open: true,
      size: 'larger',
      title: t('procedureBox.actions.forceTramit.title'),
      children: <ForceTramit
        reasonValue={reason}
        multipleActions={multipleActions}
      />,
      icon: <FaHands />,
      handleClose: () => setModal(undefined),
    });
  };

  const handleForceTramit = async () => {
    const proceduresId = proceduresSeleted.map((procedure) => procedure.id);
    const res = await ProcedureBoxService.getProcedureForceTramit(proceduresId);

    if (res.procedures_error.length === proceduresSeleted.length) {
      setAlert({
        visible: true,
        type: 'error',
        title: proceduresSeleted.length > 1
          ? t('procedureBox.actions.forceTramit.force_alert.title_error.procedures', { total_procedures: proceduresSeleted.length })
          : <Trans
              i18nKey={'procedureBox.actions.forceTramit.force_alert.title_error.procedure'}
              components={{ a: <a />, procedure_number: proceduresSeleted[0].process_number }}
            />,
        text: t('procedureBox.actions.forceTramit.force_alert.text'),
      });
    } else if (res.procedures_error.length >= 1 && res.procedures_success.length >= 1) {
      setAlert({
        visible: true,
        type: 'warning',
        title: t('procedureBox.actions.forceTramit.force_alert.title_warning', {
          total_procedures_error: res.procedures_error.length,
          total_procedures: proceduresSeleted.length,
          procedures_force_tramit: proceduresSeleted.length - res.procedures_error.length,
        }),
        text: t('procedureBox.actions.forceTramit.force_alert.text'),
        confirmText: t('procedureBox.actions.forceTramit.force_alert.buttonConfirm'),
        confirmType: 'primary',
        cancelText: t('procedureBox.actions.forceTramit.force_alert.buttonCancel'),
        cancelType: 'default',
        handleConfirm: openForceTramit,
      });

      const proceduresSuccess: Procedure[] = [];

      res.procedures_success.map((procedure: number) => {
        const procedureIndex = procedures.find((procedure_selected) => procedure_selected.id === procedure);
        if (procedureIndex) {
          proceduresSuccess.push(procedureIndex);
        }
      });

      addProcedures(proceduresSuccess);
    } if (res.procedures_error.length === 0) {
      openForceTramit();
    }
  };

  const openSigner = (multipleActions?: MultipleActionStep) => {
    if (isDesktop() && isFirefox()) {
      setModal({
        visible: true,
        title: t('procedureBox.actions.sign_document.title'),
        handleClose: () => setModal(undefined),
        open: true,
        icon: <FaPenNib />,
        children: <Signer multipleActions={multipleActions} />,
      });
    } else {
      setAlert({
        visible: true,
        title: t('procedureBox.actions.sign_document.alertRequeriments.title'),
        text: <Trans
                i18nKey='procedureBox.actions.sign_document.alertRequeriments.text'
                components={{ bold: <strong />, a: <a /> }}
              />,
        type: 'error',
      });
    }
  };

  const openCosigner = (multipleActions?: MultipleActionStep) => {
    if (isDesktop() && isFirefox()) {
      setModal({
        visible: true,
        title: t('procedureBox.actions.cosigner.title'),
        handleClose: () => setModal(undefined),
        open: true,
        icon: <FaPenNib />,
        children: <Cosigner multipleActions={multipleActions} />,
      });
    } else {
      setAlert({
        visible: true,
        title: t('procedureBox.actions.sign_document.alertRequeriments.title'),
        text: <Trans
                i18nKey='procedureBox.actions.sign_document.alertRequeriments.text'
                components={{ bold: <strong />, a: <a /> }}
              />,
        type: 'error',
      });
    }
  };

  const openTramit = (tramitForm?: TramitRequest, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.tramit.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaPaperPlane />,
      children: <TramitProcedure
        closeModal={() => setModal(undefined)}
        tramitForm={tramitForm}
        multipleActions={multipleActions}
      />,
    });
  };

  const openDistributeProcesses = (distributeForm?: any, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.distributeProcesses.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaSitemap/>,
      children: <DistributeProcesses
        closeModal={() => setModal(undefined)}
        distributeForm={distributeForm}
      />,
    });
  };

  const openShareProcedure = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.shareProcedure.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaShareAlt/>,
      children: <ShareProcedure
        closeModal={() => setModal(undefined)}
      />,
    });
  };

  const openChangeFlux = (flux?: OptionType, observation?: string, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.changeFlux.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <ImShuffle />,
      children: <ChangeFlux
        flux={flux}
        observation={observation}
        multipleActions={multipleActions}
      />,
    });
  };

  const openCreateDocument = (values?: any, waiting?: any, multipleActions?: MultipleActionStep, reload?: boolean) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.prepareDocument.modal.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaFileAlt />,
      children: <CreateDocument
        values={values}
        closeModal={() => setModal(undefined)}
        waiting={waiting}
        multipleActions={multipleActions}
        reload={reload}
      />,
    });
  };

  const openForwardIpm = (values?: any, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      box: <BoxIcon text={t('procedureBox.actions.ipm.title')} />,
      title: t('procedureBox.actions.ipm.forwardIPM.text'),
      handleClose: () => setModal(undefined),
      minimize: true,
      onMinimize: () => <></>,
      open: true,
      size: 'larger',
      children: <ForwardIpm multipleActions={multipleActions}/>,
    });
  };

  const openSpu = (spuObject?: SpuJudicialDocument, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      box: <BoxIcon text={t('procedureBox.actions.spu.title')} />,
      title: t('procedureBox.actions.spu.sendOficialLetter'),
      handleClose: () => setModal(undefined),
      minimize: true,
      onMinimize: () => <></>,
      open: true,
      size: 'larger',
      children: <Spu
        closeModal={() => setModal(undefined)}
        spuObject={spuObject}
        multipleActions={multipleActions}
      />,
    });
  };

  const openRegistrationSpu = (spuObject?: SpuJudicialDocument, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      box: <BoxIcon text={t('procedureBox.actions.spu.title')} />,
      title: t('procedureBox.actions.spu.registrationOficialLetter'),
      handleClose: () => setModal(undefined),
      minimize: true,
      onMinimize: () => <></>,
      open: true,
      size: 'larger',
      children: <RegistrationSpu
        closeModal={() => setModal(undefined)}
        spuObject={spuObject}
        multipleActions={multipleActions}
      />,
    });
  };

  const openCreateSubProcess = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.subProcedure.modal.judicialOrderModal.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaEnvelopeOpenText />,
      children: <SubProcess />,
    });
  };

  const openPaymentOrder = (form: any) => {
    setModal({
      visible: true,
      open: true,
      size: 'larger',
      title: t('procedureBox.actions.paymentOrder.title'),
      children: <PaymentOrder paymentOrderObject={form}/>,
      icon: <FaEnvelopeOpenText />,
      handleClose: () => setModal(undefined),
    });
  };

  const openEletronicCalculation = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.eletronicCalculation.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaEnvelopeOpenText />,
      children: <EletronicCalculation />,
    });
  };

  const openMarkerModal = (type: 'edit' | 'create') => {
    setModal({
      visible: true,
      title: type === 'create' ? t('procedureBox.modalMarker.titleCreate') : t('procedureBox.modalMarker.titleEdit'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaTag />,
      size: 'small',
      children: <CreateEditMarkerModal
        handleCloseModal={() => setModal(undefined)}
        type={ type }
     />,
    });
  };

  const openAttachFile = (
    documents?: AttachDocumentType[],
    waiting?: boolean,
    multipleActions?: MultipleActionStep,
    tramit?: boolean,
    reload?: boolean,
  ) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.attachFiles.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaPaperclip />,
      children: <AttachDocument
        closeModal={() => setModal(undefined)}
        documents={documents}
        waiting={waiting}
        tramit={tramit}
        multipleActions={multipleActions}
        reload={reload}
      />,
    });
  };

  const unarchiveBulkError = (procedureIds: number) => {
    setAlert({
      visible: true,
      title: <Trans
            i18nKey='procedureBox.actions.unarchive.bulk.alertError.title'
            components={{ amount: procedureIds, br: <br /> }} />,
      text: <Trans
            i18nKey='procedureBox.actions.unarchive.bulk.alertError.text'
            components={{ bold: <strong />, a: <a /> }} />,
      type: 'error',
    });
  };

  const unarchiveIndividualError = (procedureNumber: string) => {
    setAlert({
      visible: true,
      title: <Trans
            i18nKey='procedureBox.actions.unarchive.individual.alertError.title'
            components={{ number: procedureNumber, br: <br /> }} />,
      text: <Trans
            i18nKey='procedureBox.actions.unarchive.individual.alertError.text'
            components={{ bold: <strong />, a: <a /> }} />,
      type: 'error',
    });
  };

  const unarchive = (multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      icon: <FaBoxOpen />,
      title: t('procedureBox.actions.unarchive.title'),
      handleClose: () => setModal(undefined),
      minimize: true,
      onMinimize: () => <></>,
      open: true,
      size: 'medium',
      children: <Unarchive multipleActions={multipleActions} />,
    });
  };

  const openUnarchiveModal = async (multipleActions?: MultipleActionStep) => {
    const procedureIds = proceduresSeleted.map(({ id }) => id);
    const response = await ProcedureBoxService.getUnarchivePermission(procedureIds);
    setProcedures(() => proceduresSeleted.filter((e) => response.procedure_ids.includes(e.id)));
    if (response.total_with_permission === 0) {
      if (procedureIds.length > 1) {
        unarchiveBulkError(procedureIds.length);
      } else {
        unarchiveIndividualError(proceduresSeleted[0].process_number);
      }
    } else {
      unarchive(multipleActions);
    }
  };

  const openNotesModal = (multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      icon: <FaStickyNote />,
      confirmationOnClose: true,
      title: t('procedureBox.actions.notes.modal.title'),
      handleClose: () => {
        loadData(false);
        setModal(undefined);
      },
      onMinimize: () => <></>,
      open: true,
      size: 'larger',
      children: <Note multipleActions={multipleActions} closeModal={() => setModal(undefined)}/>,
    });
  };

  const openMultipleActions = (procedure?: Procedure, action?: string) => {
    setModal({
      visible: true,
      icon: <FaListAlt />,
      title: t('procedureBox.actions.multipleActions.title'),
      handleClose: () => setModal(undefined),
      onMinimize: () => <></>,
      open: true,
      size: 'larger',
      children: <MultipleActions
        closeModal={() => setModal(undefined)}
        procedure={procedure}
        section={action}
      />,
    });
  };

  const openDeleteFile = async (documents?: AttachedDocument[], multipleActions?: MultipleActionStep) => {
    const procedureIds = proceduresSeleted.map(({ id }) => id);
    if (proceduresSeleted.length > 1) {
      try {
        const response = await ProcedureBoxService.getAttachedDocumentsInBulk(procedureIds);
        if (response.length > 0) {
          setAlert({
            visible: true,
            title: t('procedureBox.actions.removeDocument.alertWarning.title'),
            text: <Trans
                    i18nKey='procedureBox.actions.removeDocument.alertWarning.text'
                    components={{ bold: <strong />, a: <a /> }}
                  />,
            type: 'warning',
            cancelText: `${t('procedureBox.actions.removeDocument.alertWarning.cancel')}`,
            confirmText: t('procedureBox.actions.removeDocument.alertWarning.continue'),
            handleConfirm: () => {
              setAlert(undefined);
              setModal({
                visible: true,
                title: t('procedureBox.actions.removeDocument.title'),
                handleClose: () => {
                  setModal(undefined);
                },
                open: true,
                icon: <FaTrashAlt />,
                children: <DeleteDocument
                  documents={documents || []}
                  closeModal={() => setModal(undefined)}
                  multipleActions={multipleActions}
                />,
              });
            },
          });
        } else {
          setAlert({
            visible: true,
            title: t('procedureBox.actions.removeDocument.alertError.title'),
            text: <Trans
                  i18nKey='procedureBox.actions.removeDocument.alertError.text'
                  components={{ bold: <strong />, a: <a /> }}
                />,
            type: 'error',
          });
        }
      } catch (error) {
        setAlert({
          visible: true,
          title: t('procedureBox.actions.removeDocument.alertError.title'),
          text: <Trans
                i18nKey='procedureBox.actions.removeDocument.alertError.text'
                components={{ bold: <strong />, a: <a /> }}
              />,
          type: 'error',
        });
      }
    } else {
      setModal({
        visible: true,
        title: t('procedureBox.actions.removeDocument.title'),
        handleClose: () => {
          setModal(undefined);
        },
        open: true,
        icon: <FaTrashAlt />,
        children: <DeleteDocument
          documents={documents || []}
          closeModal={() => setModal(undefined)}
          multipleActions={multipleActions}
        />,
      });
    }
  };

  const openArchiveProcesses = (values?: any, waiting?: any, multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t(`procedureBox.actions.archive.${proceduresSeleted.length > 1
        ? 'title_procedures'
        : 'title_procedure'
      }`),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaArchive />,
      children: <ProcessesArchives
        values={values}
        waiting={waiting}
        multipleActions={multipleActions}
      />,
    });
  };

  const openDeleteProcesses = () => {
    setAlert({
      visible: true,
      title: t('procedureBox.actions.delete.modal.alert.title'),
      text: t('procedureBox.actions.delete.modal.alert.text'),
      type: 'warning',
      cancelText: t('procedureBox.actions.delete.modal.alert.cancel'),
      confirmText: t('procedureBox.actions.delete.modal.alert.confirm'),
      handleConfirm: () => {
        setAlert(undefined);
        ProcedureBoxService.deleteProcedure(proceduresSeleted[0].id)
          .then(() => {
            if (pathname.includes('/procedure_box/show_procedure')) {
              window.location.href = '/procedure_box';
            } else {
              addToasts({ type: 'success', text: t('procedureBox.actions.delete.modal.results.success') });
              removeAllProcedures();
              loadData();
            }
          })
          .catch(() => addToasts({ type: 'error', text: t('procedureBox.actions.delete.modal.results.error') }));
      },
    });
  };

  const openJoinDocuments = (documents?: AttachDocumentType[], multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.joinDocuments.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaFolder />,
      children: <AttachDocument
        closeModal={() => setModal(undefined)}
        documents={documents}
        multipleActions={multipleActions}
        joinDocuments
      />,
    });
  };

  const openAttachProcedureCitationIntimation = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.attachCitationIntimation.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <LinkIcon />,
      children: <AttachProcedureCitationIntimation />,
    });
  };

  const openExpandedMarkersModal = (procedurId: number) => {
    setModal({
      title: t('procedureBox.actions.expandedMarkers.modal.title'),
      box: true,
      icon: <FaTag/>,
      size: 'medium',
      handleClose: () => setModal(undefined),
      children: <ExpandedMarkers procedureId={procedurId}/>,
      visible: true,
      open: true,
    });
  };

  const openExpandedInterestedPartsModal = (interessedParts: string[]) => {
    setModal({
      title: t('procedureBox.actions.expandedInterestedParts.modal.title'),
      box: true,
      size: 'small',
      handleClose: () => setModal(undefined),
      children: <ExpandedInterestedParts interestedParts={interessedParts}/>,
      visible: true,
      open: true,
    });
  };

  const openInitialPetition = (values: any, multipleActions?: MultipleActionStep) => {
    setModal({
      title: t('procedureBox.actions.initialPetition.modal.text'),
      box: true,
      handleClose: () => setModal(undefined),
      children: <PetitionInitial
        values={values}
        multipleActions={multipleActions}
      />,
      visible: true,
      open: true,
    });
  };

  const openIntermediaryPetition = (multipleActions?: MultipleActionStep) => {
    setModal({
      title: t('procedureBox.actions.intermediatePetition.modal.title'),
      box: true,
      handleClose: () => setModal(undefined),
      children: <PetitionIntermediary multipleActions={multipleActions} />,
      visible: true,
      open: true,
    });
  };

  const openNewProcedure = () => {
    setModal({
      visible: true,
      title: t('procedureBox.newProcedure.button'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaPlus />,
      children: <NewProcedure closeModal={() => setModal(undefined)}/>,
    });
  };

  const openNewCitationIntimation = () => {
    sendEvent({
      event_name: 'new_citation_intimation',
      label: t('procedureBox.newProcedure.citationIntimation.title'),
    });

    setModal({
      visible: true,
      title: t('procedureBox.newProcedure.citationIntimation.title'),
      size: 'medium',
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaQuoteRight />,
      children: <NewCitationIntimation closeModal={() => setModal(undefined)}/>,
    });
  };

  const openDeadline = (
    procedureDeadline: ProcedureDeadline | undefined,
    procedure?: Procedure,
  ) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.deadline.title'),
      handleClose: () => setModal(undefined),
      open: true,
      size: 'medium',
      icon: <FaClock/>,
      children: <DeadlineForm
        procedure={procedure}
        procedureDeadline={procedureDeadline}
        closeModal={() => setModal(undefined)}
      />,
    });
  };

  const openUpdateCategoryInProcess = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.category.update.title'),
      open: true,
      size: 'small',
      handleClose: () => setModal(undefined),
      children: <UpdateCategory closeModal={() => setModal(undefined)} />,
    });
  };

  const openCreateCategoryInProcess = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.category.create.title'),
      open: true,
      size: 'medium',
      handleClose: () => setModal(undefined),
      children: <CreateCategory closeModal={() => setModal(undefined)} />,
    });
  };

  const openAttachProcedure = (multipleActions?: MultipleActionStep) => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.attach_procedure.modal.title'),
      open: true,
      icon: <FaLink />,
      size: 'larger',
      handleClose: () => setModal(undefined),
      children: <AttachProcedure multipleActions={multipleActions}/>,
    });
  };

  const openUnattachProcedure = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.unattach_procedure.title'),
      children: <UnattachProcedure />,
      open: true,
      icon: <FaLink />,
      size: 'larger',
      handleClose: () => setModal(undefined),
    });
  };

  const openAttachCitationIntimation = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.citation_intimation.modal.title'),
      open: true,
      icon: <FaLink />,
      size: 'larger',
      handleClose: () => setModal(undefined),
      children: <AttachCitationIntimation closeModal={() => setModal(undefined)} />,
    });
  };

  const openTramitCitationIntimation = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.citation_intimation.tramit.modal.title'),
      open: true,
      icon: <FaPaperPlane />,
      size: 'medium',
      handleClose: () => setModal(undefined),
      children: <TramitProcedure
        closeModal={() => setModal(undefined)}
        isCitationIntimation={true}
      />,
    });
  };

  const openDeleteCitationIntimation = () => {
    setAlert({
      visible: true,
      type: 'warning',
      title: t('procedureBox.actions.citation_intimation.delete.title'),
      text: t('procedureBox.actions.citation_intimation.delete.text'),
      confirmText: t('general.yes'),
      confirmType: 'primary',
      cancelText: t('general.no'),
      cancelType: 'default',
      handleConfirm: () => {
        setAlert({
          visible: true,
          title: t('procedureBox.actions.citation_intimation.delete.load.title'),
          text: t('procedureBox.actions.citation_intimation.delete.load.text'),
          type: 'loading',
        });
        ActionsService.deleteCitationIntimation(citationIntimationSelected as CitationIntimation).then((r) => {
          setAlert(undefined);
          setCitationIntimation(undefined);
          loadData();
          addToasts({
            type: 'success',
            text: t('procedureBox.actions.citation_intimation.delete.toast'),
          });
        });
      },
    });
  };

  const handleMultipleActions = (multipleActions: MultipleActionStep, currentStep: string) => {
    if (currentStep === 'notes') {
      openNotesModal(multipleActions);
    } else if (currentStep === 'attachFiles') {
      openAttachFile(undefined, undefined, multipleActions);
    } else if (currentStep === 'removeDocument') {
      openDeleteFile(undefined, multipleActions);
    } else if (currentStep === 'joinDocuments') {
      openJoinDocuments(undefined, multipleActions);
    } else if (currentStep === 'prepareDocument') {
      openCreateDocument(undefined, undefined, multipleActions);
    } else if (currentStep === 'ipm') {
      openForwardIpm(undefined, multipleActions);
    } else if (currentStep === 'spu') {
      openSpu(undefined, multipleActions);
    } else if (currentStep === 'initialPetition') {
      openInitialPetition(undefined, multipleActions);
    } else if (currentStep === 'intermediatePetition') {
      openIntermediaryPetition(multipleActions);
    } else if (currentStep === 'changeFlux') {
      openChangeFlux(undefined, undefined, multipleActions);
    } else if (currentStep === 'signDocument') {
      openSigner(multipleActions);
    } else if (currentStep === 'tramit') {
      openTramit(undefined, multipleActions);
    } else if (currentStep === 'archive') {
      openArchiveProcesses(undefined, undefined, multipleActions);
    } else if (currentStep === 'forceTramit') {
      openForceTramit(undefined, multipleActions);
    } else if (currentStep === 'unArchive') {
      openUnarchiveModal(multipleActions);
    } else if (currentStep === 'attachProcedure') {
      openAttachProcedure(multipleActions);
    } else if (currentStep === 'registrationSpu') {
      openRegistrationSpu(undefined, multipleActions);
    }
  };

  const getDataAbleSign = async (type: string) => {
    const procedureIds = proceduresSeleted.map((p) => p.id);
    const notAbleSign: any[] = [];
    let response;

    if (type === 'signer') {
      response = await ProcedureBoxService.getDocumentsByProcedure(procedureIds);
    } else {
      response = await ProcedureBoxService.getDocumentsToCosignByProcedure(procedureIds);
    }

    response.procedures.map((p) => {
      if (p.documents.length === 0) {
        notAbleSign.push(p);
      }
    });

    const dataAbleSigner: DataAbleSigner = {
      allProceduresCount: proceduresSeleted.length,
      notAbleSign,
      ableSignCount: proceduresSeleted.length - notAbleSign.length,
    };

    return dataAbleSigner;
  };

  const openReadyForProgress = async (type: string, action: () => void) => {
    const {
      allProceduresCount,
      notAbleSign,
      ableSignCount,
    } = await getDataAbleSign(type);

    if (notAbleSign.length > 0) {
      const parameters = { notAbleSign: notAbleSign.length, allProceduresCount, ableSignCount };
      setAlert({
        visible: true,
        title: t(`procedureBox.actions.${type}.modal.notice.title`, parameters),
        text: t(`procedureBox.actions.${type}.modal.notice.text`),
        type: 'warning',
        cancelText: t('procedureBox.actions.attachExtract.modal.alert.cancel'),
        confirmText: t('procedureBox.actions.attachExtract.modal.alert.confirm'),
        handleConfirm: () => {
          setAlert(undefined);
          const procedureIds = notAbleSign.map((doc) => doc.procedure_id);
          removeProceduresById(procedureIds);

          if (ableSignCount > 0) {
            action();
          }
        },
      });
    } else {
      action();
    }
  };

  const openMovements = (movements: any[]) => {
    setModal({
      visible: true,
      title: t('procedureBox.processExtract.pjeExtract.movement.modal.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaShoePrints />,
      size: 'medium',
      children: <MovementsModal
        handleCloseModal={() => setModal(undefined)}
        movements={movements}
     />,
    });
  };

  const openEditProcedure = () => {
    setModal({
      visible: true,
      title: t('procedureBox.actions.editProcedure.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaEdit />,
      size: 'larger',
      children: <NewProcedure closeModal={() => setModal(undefined)} edit />,
    });
  };

  const openNotices = (notices: Notice[]) => {
    setModal({
      visible: true,
      title: t('procedureBox.processExtract.pjeExtract.notice.modal.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaTag />,
      size: 'larger',
      children: <PendingNoticesModal
        handleCloseModal={() => setModal(undefined)}
        notices={notices}
      />,
    });
  };

  const defineObjectUpdatedMode = () => {
    const wasSeenValues = proceduresSeleted.map((ps) => ps.was_seen);
    const firstProcedureWasSeen = proceduresSeleted.every((ps) => ps.was_seen) ? true : !wasSeenValues.some((value) => value);
    const updatedProcedures = proceduresSeleted.map((procedure) => ({
      ...procedure,
      was_seen: procedure.was_seen === firstProcedureWasSeen ? false : !procedure.was_seen,
    }));
    setProcedures(updatedProcedures);
  };

  const seenNotSeen = (procedureIds: number[]) => {
    ProcedureBoxService.updateSeenNotSeenProcedures(procedureIds)
      .then((res) => {
        if (res.status === 200) {
          loadData();
          defineObjectUpdatedMode();
        }
      });
  };

  const openCustomizeColumns = () => {
    setModal({
      visible: true,
      title: t('procedureBox.customizeColumns.title'),
      handleClose: () => setModal(undefined),
      open: true,
      icon: <FaCog />,
      children: <CustomizeColumnsModal />,
    });
  };

  useEffect(() => {
    setCitationIntimation(undefined);
  }, [box]);

  return (
    <ProcedureActionsContext.Provider
      value={{
        proceduresSeleted,
        addProcedure,
        addProcedures,
        removeProcedure,
        removeAllProcedures,
        addDeadlineToProcedures,
        removeDeadlineProcedures,
        isEmpty,
        isShowProcedure,
        removeProcedures,
        openSignModal,
        openTramitModal,
        openArchiveProcesses,
        openDeleteProcesses,
        handleSignModal,
        handleTramitModal,
        setAlert,
        alert,
        modal,
        setModal,
        handleForceTramit,
        openSigner,
        openCosigner,
        openTramit,
        openDistributeProcesses,
        openShareProcedure,
        openChangeFlux,
        openForceTramit,
        openAttachFile,
        openDeleteFile,
        openUnarchiveModal,
        openNotesModal,
        openMultipleActions,
        openCreateDocument,
        openForwardIpm,
        openUpdateCategoryInProcess,
        openCreateCategoryInProcess,
        openAttachProcedure,
        openUnattachProcedure,
        openSpu,
        openRegistrationSpu,
        openCreateSubProcess,
        openEletronicCalculation,
        openInitialPetition,
        openIntermediaryPetition,
        openPaymentOrder,
        openExpandedMarkersModal,
        openExpandedInterestedPartsModal,
        setSelectedSubProcess,
        selectedSubProcess,
        openMarkerModal,
        openNewProcedure,
        openNewCitationIntimation,
        openDeadline,
        toasts,
        setToasts,
        addToasts,
        openJoinDocuments,
        descriptions,
        setDescriptions,
        verify,
        setVerify,
        verifySignatures,
        setVerifySignatures,
        ipmStateSelected,
        setIpmStateSelected,
        archives,
        setArchives,
        stateDescription,
        setStateDescription,
        setShowIconSubProcess,
        showIconSubProcess,
        modalValues,
        setModalValues,
        handleMultipleActions,
        openMovements,
        fluxAmount,
        setFluxAmount,
        openEditProcedure,
        openNotices,
        seenNotSeen,
        openReadyForProgress,
        citationIntimationSelected,
        setCitationIntimation,
        openAttachCitationIntimation,
        openAttachProcedureCitationIntimation,
        openTramitCitationIntimation,
        openDeleteCitationIntimation,
        openCustomizeColumns,
      }}>
        {children}
    </ProcedureActionsContext.Provider>
  );
};

export default ProcedureActionsProvider;
