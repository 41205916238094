import { t } from 'i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { Label } from '../../Label';
import styles from './styles.module.scss';

type PropsProgressBar = {
  distributed: number;
  total: number;
};

export function ProgressBar(props: PropsProgressBar) {
  const percentage = () => {
    return ((props.distributed / props.total) * 100).toFixed(0);
  };

  const getColor = () => {
    if (props.distributed === props.total) {
      return 'var(--successHover)';
    }
    return 'var(--infoLight)';
  };

  const styleBar = {
    width: `${percentage()}%`,
    backgroundColor: getColor(),
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Label
          text={t(
            'procedureBox.actions.distributeProcesses.modal.distributePerCollaborator.progressBar.title',
          )}
        />
        {props.distributed === props.total && <FaCheckCircle color='var(--success)' />}
      </div>
      <div className={styles.bar}>
        <div className={styles.progressBar} style={styleBar} />
      </div>
      <div className={styles.progressInfo}>
        <label>
          {t(
            'procedureBox.actions.distributeProcesses.modal.distributePerCollaborator.progressBar.progressInfo',
            { current: props.distributed, total: props.total },
          )}
        </label>
        <label>{percentage()}%</label>
      </div>
    </div>
  );
}
