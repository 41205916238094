import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';
import { useLocation } from 'react-router-dom';
import Routes from './routes';
import { AuthProvider } from './context/authContext';

import './config/i18n';

export function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: location.pathname, title: location.pathname });
  }, [location]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data === 'reloadMainPage') {
        window.location.reload();
      } if (event.data.fromEpgm) {
        const { data } = event;
        data.fromEpgm = false;
        window.postMessage(data, '*');
      }
    }, false);
  }, []);
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}
