const routesList: { [key: string]: string } = {
  procedure_box: 'Caixa de processos',
  debt_box: 'Carteira de créditos',
  debt_box_search: 'Resultado da busca',
  show_procedure: 'Extrato de processo',
  procedure_collection: 'Acervo de processos',
  audience: 'Audiência',
  payment_orders: 'Relatório de Ordens Judiciais de Pagamento',
  faq: 'Faq',
  calculation: 'Cálculos contábeis',
  schedule: 'Agenda',
};

export function translateRoute(value: string): string {
  return routesList[value];
}

export function isValidRoute(value: string): boolean {
  return routesList[value] !== undefined;
}

export function isProcedureCollection(value: string): boolean {
  return value === '/procedure_collection';
}

export function isProcedureBox(value: string): boolean {
  return value === '/procedure_box';
}
