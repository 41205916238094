import { Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import { GenericMarker } from '../../../../../../@types/markers';
import { Marker } from '../../../../../../@types/model';
import { QueryContext } from '../../../../../../context/queryContext';
import { getMarkerColor, getMarkerLabel, isMarker } from '../../../../../../helpers/markers';
import { truncate } from '../../../../../../helpers/strings';
import { CheckBox } from '../../../../../CheckBox';
import styles from './styles.module.scss';
import { CustomMenu } from '../../../../../MarkersSelect/MarkersFixedValues/CustomMenu';

type MarkerOptionProps = {
  onChange: (marker: Marker | GenericMarker) => void;
  expanded?: boolean;
  marker: Marker | GenericMarker;
  procedureId: number;
  showTotalProcedures?: boolean;
  truncate?: number;
};

export function MarkerOption(props: MarkerOptionProps) {
  const { procedures } = useContext(QueryContext);
  const [open, setOpen] = useState(false);

  const verifyChecked = (item: Marker | GenericMarker) => {
    const procedure = procedures.find((obj) => {
      return obj.id === props.procedureId;
    });

    if (!isMarker(item)) {
      if (item.marker_priority) {
        return (procedure?.marker_priority || 'low') === item.marker_priority;
      }
      if (item.marker_pending) {
        return (procedure?.marker_pending || 'no_pending') === item.marker_pending;
      }
    } else if (procedure && procedure.markers.length > 0) {
      const result = procedure.markers.find((markerObject) => {
        return markerObject.id === item.id;
      });
      return !!result;
    }
    return false;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderContent = () => {
    if (getMarkerLabel(props.marker).length > 15) {
      return (

        <Tooltip
          open={open}
          placement="right-start"
          title={ getMarkerLabel(props.marker, props.showTotalProcedures) }
          >
            <div
              onMouseEnter={handleOpen}
              onMouseLeave={handleClose}>
                <CheckBox
                  value={ verifyChecked(props.marker) }
                  text={ `${truncate(getMarkerLabel(props.marker, props.showTotalProcedures), props.expanded ? (props.truncate ? props.truncate : 40) : 14)}` }
                  color={ getMarkerColor(props.marker) }
                  isMarker
                />
            </div>
        </Tooltip>
      );
    }
    return (
      <CheckBox
        value={ verifyChecked(props.marker) }
        text={ getMarkerLabel(props.marker, props.showTotalProcedures) }
        color={ getMarkerColor(props.marker) }
        isMarker
      />
    );
  };

  return (
    <div className={ `${styles.container} ${verifyChecked(props.marker) ? styles.selected : ''}` }>
      <div onClick={ () => props.onChange(props.marker) }>
        { renderContent() }
      </div>
        {/* TODO:
          1 - Se o Marcador for privado, é para exibir o menu
          2 - Se o Marcador for público, ai tem que verificar quem é o "dono" ou criador do marcador, apenas ele pode editar
        */}
      {isMarker(props.marker) && props.showTotalProcedures ? <CustomMenu className={ styles.customMenu } marker={props.marker} /> : null}
    </div>
  );
}
