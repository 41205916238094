import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import styles from '../styles.module.scss';
import ProcedureBoxService from '../../../services/procedureBoxService';
import { Breadcumb } from '../../../components/Breadcumb';
import { Actions } from '../Actions';
import { Title } from '../Title';
import { Tabs } from '../Tabs';
import { QueryContext } from '../../../context/queryContext';
import Show from '../../../components/Show';
import { CurrentUser } from '../../../@types/currentUser';

type DetailsProps = {
  title?: string;
};

export function Details(props: DetailsProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    currentProcedure, loadingDetails, setLoadingDetails, reloadCurrentProcedure,
  } = useContext(QueryContext);

  const seenProcedure = async () => {
    const currentUser: CurrentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (currentUser.id === currentProcedure?.current_individual_id) {
      await ProcedureBoxService.wasSeenUpdate(pathname.split('/')[3], true);
    }
  };

  useEffect(() => {
    reloadCurrentProcedure(pathname);
  }, []);

  useEffect(() => {
    seenProcedure();
  }, [currentProcedure]);

  useEffect(() => {
    reloadCurrentProcedure(pathname);
  }, [loadingDetails]);

  return (
    <Show if={currentProcedure.flux !== undefined}>
      <div>
        <Grid
          container
          columns={{ xs: 12, sm: 12, md: 12 }}
          spacing={2}
          className={ styles.childrenContent }
        >
          <Grid item xs={12} className={ styles.title }>
            <Grid item xs={12} sm={12} md={12}>
              <h1>{ t('procedureBox.processExtract.title') }</h1>
              <Breadcumb pathnames={(pathname.split('/').filter((x) => x))}/>
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <div className={ styles.iconGroupContainner }>
            <div className={ styles.rightIcons }>
              <Actions procedure={currentProcedure} />
            </div>
          </div>
          </Grid>
          <Grid item xs={12}>
            <div className={styles.container}>
              <Title setLoading={setLoadingDetails} procedure={currentProcedure} />
              <Tabs procedure={currentProcedure}/>
            </div>
          </Grid>
        </Grid>
      </div>
    </Show>
  );
}
