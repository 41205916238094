import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Header } from '../../../../components/Header';
import { RoundButton } from '../../../../components/RoundButton';
import FormHelper from '../../../../helpers/form';
import { Form } from './Form';
import styles from './styles.module.scss';
import { Errors } from '../../../../@types/config';
import { ProcedureType } from '../../../../@types/processRegistration';

type InterestedPartsProps = {
  activeOptions: any;
  passiveOptions: any;
  control: any;
  setValue: (name: string, value: string) => void;
  unregister: (name: string) => void;
  errors: Errors | null;
  setErrors: (errors: Errors) => void;
  prefix?: string;
  onChangeList?: (list: List[]) => void;
  list?: List[];
  procedure?: ProcedureType;
  isUniq?: boolean;
  isProurma?: boolean;
  flux?: number;
};

type List = {
  type: ListType;
  id: string;
  partId?: number;
};

type ListType = 'active' | 'passive' | 'uniq';

export function InterestedParts(props: InterestedPartsProps) {
  const [list, setList] = useState<List[]>(props.list || []);
  const prefix = props.prefix ? props.prefix : 'interested_parts_attributes';
  const uniqPart = props.procedure?.administrative_prourma_process?.interested_parts.filter(
    (activePartType) => activePartType.is_single_part,
  );
  let activePart: string | any[];
  let passivePart: string | any[];
  let processPartType: any[];

  useEffect(() => {
    if (props.onChangeList) {
      props.onChangeList(list);
    }
  }, [list]);

  const tempList: {
    type: ListType;
    id: string;
    partType?: boolean | undefined;
    partId?: number
  }[] = [];

  const handleAddForm = (
    type: ListType,
    times?: number,
    partType?: boolean,
    partId?: number,
  ) => {
    if (times) {
      for (let i = 0; i < times; i += 1) {
        const id = (Math.random() + 1).toString(36).substring(1);
        tempList.push({
          type, id, partType, partId,
        });
      }
      setList([...list, ...tempList]);
    } else {
      const id = (Math.random() + 1).toString(36).substring(1);
      setList([...list, { type, id }]);
    }
  };

  useEffect(() => {
    if (props.procedure) {
      switch (props.procedure?.activity_code) {
        case 'ATV0032':
          activePart = props.procedure?.administrative_prourma_process.interested_parts
            .filter((activePartType) => activePartType.active_part && !activePartType.is_single_part);
          passivePart = props.procedure?.administrative_prourma_process.interested_parts
            .filter((activePartType) => !activePartType.active_part && !activePartType.is_single_part);
          break;
        case 'ATV0016':
          activePart = props.procedure?.judicial_process.judicial_parts.filter((activePartType) => activePartType.active_part);
          passivePart = props.procedure?.judicial_process.judicial_parts.filter((activePartType) => !activePartType.active_part);
          break;
        default:
          break;
      }
      if (activePart?.length !== 0) {
        if (props.procedure.activity_code === 'ATV0032') {
          handleAddForm('uniq', activePart.length, true);
        } else if (props.procedure.activity_code === 'ATV0016') {
          if (Array.isArray(activePart)) {
            activePart.forEach((ap) => {
              handleAddForm('active', 1, true, ap.id);
            });
          }
        }
        if (passivePart?.length !== 0) {
          if (Array.isArray(passivePart)) {
            passivePart.forEach((pp) => {
              handleAddForm('passive', 1, false, pp.id);
            });
          }
        }
      } else if (uniqPart?.length !== 0) {
        if (uniqPart) {
          handleAddForm('uniq', uniqPart.length, false);
        }
      }
    }
  }, []);

  const handleDeleteForm = (id: string) => {
    let newList = [...list];
    newList = newList.filter((el) => el.id !== id);
    setList(newList);
  };

  const interestedPartOptions = (partType: string) => {
    if (props.procedure) {
      switch (props.procedure?.activity_code) {
        case 'ATV0032':
          processPartType = props.procedure?.administrative_prourma_process.interested_parts;
          break;
        case 'ATV0016':
          processPartType = props.procedure?.judicial_process.judicial_parts;
          break;
        default:
          break;
      }
      const procedurePartType = processPartType;
      if (partType === 'active') {
        return procedurePartType.filter((parType) => parType.active_part);
      }
      if (partType === 'uniq') {
        if (procedurePartType !== undefined) {
          return procedurePartType.filter((parType) => parType.is_single_part);
        }
        return '';
      }
      return procedurePartType.filter((parType) => !parType.active_part);
    }
    return '';
  };

  const showParticipations = () => {
    return props.passiveOptions.concat(props.activeOptions).sort((a: any, b: any) => a.label.localeCompare(b.label));
  };

  return (
    <div className={styles.container}>
      <Header text={t('procedureBox.newProcedure.interestedParts.header')} />
      {FormHelper.renderErrorField('interested_parts', props.errors)}
      {props.isUniq ? (
        <div className={styles.nestedForm}>
          <Header
            text={t(
              'procedureBox.newProcedure.interestedParts.form.uniq.label',
            )}
            regular
          />
          {list.map(
            (item, index) => item.type === 'uniq' && (
                <Form
                  id={index}
                  key={item.id}
                  options={showParticipations()}
                  control={props.control}
                  setValue={props.setValue}
                  delete={() => handleDeleteForm(item.id)}
                  unregister={props.unregister}
                  prefix={prefix}
                  errors={props.errors}
                  setErrors={props.setErrors}
                  passive
                  interestedParts={interestedPartOptions('uniq')}
                  partType={item.type}
                  isProurma={props.isProurma}
                  flux={props.flux}
                  isUniq
                />
            ),
          )}
          <RoundButton
            dataCy='add_interested_part'
            icon={<FaPlus />}
            backgroundColor="var(--primary)"
            size="small"
            onClick={() => handleAddForm('uniq')}
          />
        </div>
      ) : (
        <>
          <div className={styles.nestedForm}>
            <Header
              text={t(
                'procedureBox.newProcedure.interestedParts.form.active.label',
              )}
              regular
            />
            {list.map(
              (item, index) => item.type === 'active' && (
                  <Form
                    id={index}
                    partId={item.partId}
                    key={item.id}
                    control={props.control}
                    options={props.activeOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))}
                    setValue={props.setValue}
                    delete={() => handleDeleteForm(item.id)}
                    prefix={prefix}
                    unregister={props.unregister}
                    errors={props.errors}
                    setErrors={props.setErrors}
                    interestedParts={interestedPartOptions('active')}
                    flux={props.flux}
                    partType={item.type}
                    dataCy={'active'}
                  />
              ),
            )}
            <RoundButton
              dataCy='add_interested_part_active'
              icon={<FaPlus />}
              backgroundColor="var(--primary)"
              size="small"
              onClick={() => handleAddForm('active')}
            />
          </div>

          <div className={styles.nestedForm}>
            <Header
              text={t(
                'procedureBox.newProcedure.interestedParts.form.passive.label',
              )}
              regular
            />
            {list.map(
              (item, index) => item.type === 'passive' && (
                  <Form
                    id={index}
                    partId={item.partId}
                    key={item.id}
                    options={props.passiveOptions.sort((a: any, b: any) => a.label.localeCompare(b.label))}
                    control={props.control}
                    setValue={props.setValue}
                    delete={() => handleDeleteForm(item.id)}
                    unregister={props.unregister}
                    prefix={prefix}
                    errors={props.errors}
                    setErrors={props.setErrors}
                    passive
                    interestedParts={interestedPartOptions('passive')}
                    partType={item.type}
                    flux={props.flux}
                    dataCy={'passive'}
                  />
              ),
            )}
            <RoundButton
              dataCy='add_interested_part_passive'
              icon={<FaPlus />}
              backgroundColor="var(--primary)"
              size="small"
              onClick={() => handleAddForm('passive')}
            />
          </div>
        </>
      )}
    </div>
  );
}
