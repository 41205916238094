import { ReactNode } from 'react';
import styles from './styles.module.scss';

type TableProps = {
  children?: ReactNode;
};

export function Table(props: TableProps) {
  return (
    <table className={styles.table}>
      {props.children}
    </table>
  );
}
