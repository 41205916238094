import { createTheme } from '@mui/material/';

export const customTheme = (createTheme as any)({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 11,
          fontWeight: 'bold',
          color: 'var(--gray2)',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 15,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '4px 9px',

          '&.Mui-checked': {
            color: 'var(--primary)',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          sx: {
            width: 300,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'var(--gray2)',
          },
        },
      },
    },
  },
});
