import { Grid } from '@mui/material';
import styles from './styles.module.scss';

type ExpandedInterestedPartsProps = {
  interestedParts: string[];
};

export function ExpandedInterestedParts(props: ExpandedInterestedPartsProps) {
  return (
    <Grid container columns={{ xs: 1, sm: 2, md: 2 }} className={styles.container}>
      {props.interestedParts.map((interestedPart, index) => {
        return (
          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            key={index}
          >
            <span>{interestedPart}</span>
          </Grid>
        );
      })}
    </Grid>
  );
}
