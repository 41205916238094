import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Procedure } from '../../../@types/model';
import { Table } from '../Table';
import styles from './styles.module.scss';
import { ProcedureActionsContext } from '../../../context/procedureActionsContext';
import { Deadline } from '../../../components/Deadline';

type DataProps = {
  procedure: Procedure;
};

export function Tables(props: DataProps) {
  const {
    openNotesModal,
    addProcedures,
    openExpandedInterestedPartsModal,
  } = useContext(ProcedureActionsContext);

  const { t } = useTranslation();

  const attachedProcedures = [] as any;
  props.procedure.attached_procedures.map((attached_procedure, i) => {
    attachedProcedures.push(
      <span>
        <a className={ styles.linkProcedure } href={`/procedure_box/show_procedure/${attached_procedure.procedure_id}`}>
          {attached_procedure.procedure_number}
        </a>{props.procedure.attached_procedures.length === i + 1 ? '' : (<>, </>)}
      </span>,
    );
  });

  const openNotes = () => {
    addProcedures([props.procedure]);
    openNotesModal();
  };

  const renderNotes = () => {
    const length = props.procedure.procedure_notes?.length || 0;
    if (props.procedure.procedure_notes) {
      return (
        <a
          data-cy={'cypress-show-procedure-note'}
          className={`${styles.link} ${length >= 1 ? styles.danger : ''}`}
          onClick={openNotes}
        >
          {t(`procedureBox.processExtract.extractDataTable.seeAnnotation${length > 1 ? 's' : ''}`, { amount: length })}
        </a>
      );
    }
    return t('procedureBox.processExtract.extractDataTable.noAnnotations');
  };

  const currentResponsbile = {
    individual_name: props.procedure.individual_name,
    color: props.procedure.individual_color,
    profile_picture: props.procedure.individual_profile_picture,
  };

  const involvedPeople = [] as any;
  props.procedure.involved_people.map((person: any) => {
    involvedPeople.push({
      name: person.name,
      color: person.color,
      profile_picture: person.profile_picture,
    });
  });

  const sharedPeople = [] as any;
  props.procedure.shared_people.map((person: any) => {
    sharedPeople.push({
      name: person.name,
      color: person.color,
      profile_picture: person.profile_picture,
    });
  });

  const processExtractInformations = {
    [t('procedureBox.processExtract.extractDataTable.processNumber')]:
      props.procedure.process_number,
    [t('procedureBox.processExtract.extractDataTable.processFlux')]:
      props.procedure.flux.name,
    ...(props.procedure.origin_procedure_name ? {
      [t('procedureBox.processExtract.extractDataTable.processOrigin')]:
        props.procedure.origin_procedure_name,
    } : {}),
    [t('procedureBox.processExtract.extractDataTable.lastStep')]:
      props.procedure.last_product_generated,
    ...(props.procedure.interested_parts.length > 0 ? {
      [t('procedureBox.processExtract.extractDataTable.interessedPart')]:
        props.procedure.interested_parts.length > 2 ? (
          <a
            onClick={() => openExpandedInterestedPartsModal(props.procedure.interested_parts)}
            className={styles.link}
          >
            {
              t('procedureBox.processExtract.extractDataTable.interessedPartReduce', {
                firstInteressedPart: props.procedure.interested_parts[0],
                count: props.procedure.interested_parts.length,
              })
            }
          </a>
        ) : props.procedure.interested_parts.join(', '),
    } : {}),
    [t('procedureBox.processExtract.extractDataTable.processStatus')]: [
      t(
        `procedureBox.processExtract.extractDataTable.processStatusType.${props.procedure.status}`,
      ),
    ],
    [t('procedureBox.processExtract.extractDataTable.subject')]:
      props.procedure.subject === ''
        ? [t('procedureBox.processExtract.extractDataTable.noSubject')]
        : props.procedure.subject,
    [t('procedureBox.processExtract.extractDataTable.annotations')]:
      renderNotes(),
    [t('procedureBox.processExtract.extractDataTable.classification')]:
      props.procedure.process_category_name,

    ...(props.procedure.attached_procedures.length > 0
      ? {
        [t(
          'procedureBox.processExtract.extractDataTable.attachedProcedures.title',
        )]: attachedProcedures,
      }
      : {}),

    ...(Object.keys(props.procedure.main_subprocedures_numbers).length > 0
      ? {
        [t('procedureBox.processExtract.extractDataTable.mainSubProcedure')]:
            (
              <Link
                to={`/procedure_box/show_procedure/${props.procedure.main_subprocedures_numbers.procedure_id}`}
                className={styles.linkProcedure}
                target={'_blank'}
              >
                {props.procedure.main_subprocedures_numbers.procedure_number}
              </Link>
            ),
      }
      : {}),

    ...(props.procedure.subprocedures_numbers.length > 0
      ? {
        [t(
          'procedureBox.processExtract.extractDataTable.subProcessGenereted',
        )]: props.procedure.subprocedures_numbers.map((sb: any) => (
            <div>
              <Link
                to={`/procedure_box/show_procedure/${sb.procedure_id}`}
                target={'_blank'}
                className={styles.linkProcedure}
              >
                {sb.process_number}
              </Link>
            </div>
        )),
      }
      : {}),

    ...(props.procedure.procedure_deadlines && props.procedure.procedure_deadlines?.length > 0
      ? {
        [t('procedureBox.processExtract.extractDataTable.deadlines')]:
            props.procedure.procedure_deadlines.map((deadline) => (
              <Deadline
                procedure={props.procedure}
                procedureDeadline={deadline}
              />
            )),
      }
      : {}),
  };

  return (
    <div className={ styles.tablesContainer }>
      <Grid container columns={{ xs: 1, sm: 1, md: 12 }}>
        <Grid item xs={1} sm={1} md={6}>
          <div className={ styles.mineTable }>
            <Table
              tableWidth={850}
              rowTitleWidth={80}
              rowValueWidth={280}
              wantedRows={ processExtractInformations }
            />
          </div>
        </Grid>
        <Grid item xs={1} sm={1} md={6}>
          <div className={ styles.mineTable }>
            <Table
              singleColumn={true}
              currentResponsbile={props.procedure.individual_name}
              individual_color={props.procedure.individual_color}
              picture_profile={props.procedure.individual_profile_picture}
              tableWidth={600}
              rowTitleWidth={30}
              rowValueWidth={40}
              wantedColumns={
                [t('procedureBox.processExtract.extractDataTable.currentResponsbile')]
              }
              wantedRows={{ '': currentResponsbile }}
            />
          </div>
          <Grid item xs={1} sm={1} md={12}>
            <div className={ styles.mineTable }>
              <Table
                singleColumn={true}
                tableWidth={600}
                rowTitleWidth={30}
                rowValueWidth={40}
                wantedColumns={
                  [t('procedureBox.processExtract.extractDataTable.previousResponsible')]
                }
                wantedRows={involvedPeople}
              />
            </div>
          </Grid>
          <Grid item xs={1} sm={1} md={12}>
            <div className={ styles.mineTable }>
              <Table
                singleColumn={true}
                tableWidth={600}
                rowTitleWidth={30}
                rowValueWidth={40}
                wantedColumns={
                  [t('procedureBox.processExtract.extractDataTable.sharedWith')]
                }
                wantedRows={sharedPeople}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
