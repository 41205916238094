import { t } from 'i18next';
import { useContext } from 'react';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import Show from '../../../Show';
import styles from './styles.module.scss';

type SubProcedureProps = {
  title?: string;
  size?: 'small' | 'large' | 'medium' | 'micro';
  className?: string;
  disabled?: boolean;
  responsive: boolean;
  onlyRequestCalculation?: boolean;
};

export type Option = {
  title: string;
  action?: () => void;
  disabled?: boolean;
};

export function SubProcedure(props: SubProcedureProps) {
  const { openCreateSubProcess, openEletronicCalculation, openPaymentOrder } = useContext(ProcedureActionsContext);

  const subprocedure: Option[] = [
    {
      title: 'judicialOrder',
      action: openCreateSubProcess,
    },
    {
      title: 'paymentJudicialOrder',
      action: () => openPaymentOrder(undefined),
    },
    {
      title: 'requestCalculation',
      action: openEletronicCalculation,
    },
  ];

  const renderResponsive = () => {
    return (
      subprocedure.map((option: Option, index) => (
        props.onlyRequestCalculation && option.title !== 'requestCalculation' ? (
          <></>
        ) : (
          <div
            key={index}
            className={`${styles.itemOption}`}
            onClick={option.action}
          >
            <FaEnvelopeOpenText />
            <p>{t('procedureBox.actions.subProcedure.modal.title')} - {t(`procedureBox.actions.subProcedure.modal.${option.title}`)}</p>
          </div>
        )
      ))
    );
  };
  return (
    <>
      <Show if={!props.responsive}>
        <div className={`${styles.container} ${props.size ? props.size : styles.small} ${props.className} ${props.disabled ? styles.disabledButton : ''}`}>
          <div className={`${styles.dropdown}`}>
            <span><FaEnvelopeOpenText /></span>
              <div className={styles.content}>
               <Show if={!props.disabled}>
                  <p className={`${styles.title}`}>{t('procedureBox.actions.subProcedure.modal.title')}</p>
                  {subprocedure.map((option: Option, index) => (
                    props.onlyRequestCalculation && option.title !== 'requestCalculation' ? (
                      <></>
                    ) : (
                      <div
                        key={index}
                        className={`${styles.item}`}
                        onClick={option.action}
                      >
                        <p>{t(`procedureBox.actions.subProcedure.modal.${option.title}`)}</p>
                      </div>
                    )
                  ))}
                </Show>
                <Show if={!!props.disabled}>
                  <div className={`${styles.disabledItem}`}>
                    <p>{t('procedureBox.actions.subProcedure.modal.message')}</p>
                  </div>
                </Show>
              </div>
          </div>
        </div>
      </Show>
      <Show if={props.responsive}>
        {!props.disabled ? renderResponsive() : null}
      </Show>
    </>
  );
}
