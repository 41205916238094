import { CitationIntimation, Procedure } from '../@types/model';
import Axios from '../config/api';

export default class ActionsService {
  public static async getMultipleActionsList() {
    return Axios.get('api/general_orders/?order_type=1')
      .then((res) => res.data);
  }

  public static async deleteCitationIntimation(cn: CitationIntimation) {
    return Axios.delete(`api/citation_intimations/delete?citation_intimation_id=${cn.id}`)
      .then((res) => res.data);
  }

  public static async attachCitationIntimation(cn: CitationIntimation, procedure: Procedure) {
    return Axios.post('api/citation_intimations/attach', { citation_intimation_id: cn.id, procedure_id: procedure.id })
      .then((res) => res.data);
  }

  public static async updateCitationIntimationWasSeen(citation_intimation_id: number) {
    return Axios.post('api/citation_intimations/was_seen', { citation_intimation_id })
      .then((res) => res.data);
  }
}
