export const EPGM_URL = 'https://hom.epgm.pgm.fortaleza.ce.gov.br';
export const AGILIS_API_URL = 'https://hom2.apiagilis.pgm.fortaleza.ce.gov.br/';
export const AGILIS_FRONT = 'hom1';
export const AGILIS_ACCESS_TOKEN = '2TW5cyxjS-73u1_Fhmay';
export const EPGM_SIGN_URL = 'https://hom.epgm.pgm.fortaleza.ce.gov.br';
export const DEBITBOX_AGILIS_API_URL = 'https://hom.apiagilis.pgm.fortaleza.ce.gov.br';
export const SHOW_AGILIS_DEBITBOX = true;
export const EPGM_LOGIN_SCREEN = false;
export const GOOGLE_ANALYTICS_TOKEN = 'G-RYMMVYKYXC';

