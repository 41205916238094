import { t } from 'i18next';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { Grid } from '@mui/material';
import i18n from '../../config/i18n';
import { InputText } from '../InputText';
import { ExpansiveFaq } from './ExpansiveFaq';
import { Separator } from './Separator';
import styles from './styles.module.scss';

export function FaqList() {
  const [filter, setFilter] = useState('');
  const accordionLenght = Object.keys(t('faq.accordion', { returnObjects: true })).length;
  const translateComponents = {
    bold: <strong />,
    underline: <span />,
    a: <a />,
    list: <ul />,
    listItem: <li />,
    table: <table />,
    tableInscript: <table className={styles.inscriptionTable} />,
    tbody: <tbody />,
    th: <th />,
    tr: <tr />,
    td: <td />,
  };
  const renderTitle = (index: number) => {
    return (<Trans
      i18nKey={`faq.accordion.${index}.title`}
      components={translateComponents} />) as unknown as string;
  };

  const renderVideo = (index: number) => {
    if (i18n.exists(`faq.accordion.${index}.video`)) {
      return t(`faq.accordion.${index}.video`);
    }
    return '';
  };

  const renderText = (index: number) => {
    return (<Trans
      i18nKey={`faq.accordion.${index}.text`}
      components={translateComponents} />) as unknown as string;
  };

  const handleRender = (content: string) => {
    if (filter === '') {
      return true;
    }
    return content.toLowerCase().includes(filter.toLowerCase());
  };

  const ExpansiveFaqList = () => {
    let canRenderIds = [];
    const faqComponents: JSX.Element[] = [];
    for (let i = 1; i <= accordionLenght; i += 1) {
      const canRender = handleRender(t(`faq.accordion.${i}.text`)) || handleRender(t(`faq.accordion.${i}.title`));
      if (canRender === true) {
        canRenderIds.push(i);
      }
    }

    for (let i = 1; i <= accordionLenght; i += 1) {
      if (i <= 12 && canRenderIds.find((id) => id <= 12)) {
        faqComponents.push(<Separator title={t('faq.accordion.1.separator')}/>);
        canRenderIds = canRenderIds.filter((id) => id > 12);
      }

      if (i === 13 && canRenderIds.find((id) => id === 13)) {
        faqComponents.push(<Separator title={t('faq.accordion.13.separator')}/>);
        canRenderIds = canRenderIds.filter((id) => id >= 21);
      }

      if (i === 22 && canRenderIds.find((id) => id === 22)) {
        faqComponents.push(<Separator title={t('faq.accordion.22.separator')}/>);
        canRenderIds = canRenderIds.filter((id) => id >= 23);
      }

      const canRender = handleRender(t(`faq.accordion.${i}.text`)) || handleRender(t(`faq.accordion.${i}.title`));
      if (canRender === true) {
        faqComponents.push(
          <ExpansiveFaq
            key={i}
            title={renderTitle(i)}
            text={renderText(i)}
            video={renderVideo(i)}
          />,
        );
      }
    }

    return faqComponents;
  };

  return (
    <div className={styles.container}>
      <Grid
        container
        className={styles.filter}
        columns={{ xs: 1, sm: 3, md: 12 }}>
        <Grid
          item
          xs={1}
          sm={3}
          md={4}
        >
          <InputText
            placeholder={t('faq.filter')}
            className={styles.input}
            onChange={(e) => setFilter(e.target.value)}/>
        </Grid>
      </Grid>
      <div className={ styles.faq }>
          {ExpansiveFaqList()}
      </div>
    </div>
  );
}
