import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { SelectGroupOption } from '../../../@types/config';
import '../styles.scss';

type ItemMenuProps = {
  option: SelectGroupOption;
  handleOnClick: (item: string, option: SelectGroupOption, id: number) => void;
  isOpen?: (value: boolean) => void;
};

export function ItemMenu(props: ItemMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (props.isOpen) {
      props.isOpen(true);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (props.isOpen) {
      props.isOpen(false);
    }
  };
  const menuItens = [
    'Editar',
    'Excluir',
  ];
  return (
    <div>
      <IconButton
        className={open ? 'selectMenuOpen' : 'selectMenu'}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItens.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              props.handleOnClick(item, props.option, props.option.value);
              handleClose();
            }}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
