import './styles.scss';
import { Tooltip } from '@mui/material';
import logo from '../../assets/logo-epgm.svg';
import { BarButton } from '../Sidebar/BarButton';
import { Profile } from '../Profile';
import { Notifications } from '../Notifications';

type TopbarProps = {
  openMobile?: boolean;
  setOpenMobile: (e: boolean) => void;
};

export function Topbar(props: TopbarProps) {
  return (
    <div className='topbarContainer' id='topbar'>
      <div className='rightItems'>
        <BarButton
          opened={ false }
          onClick={ () => props.setOpenMobile(true) }
          mobile
        />
        <Tooltip title='Home'>
          <a href='/'>
            <img src={logo} alt='e-PGM' />
          </a>
        </Tooltip>
      </div>
      <div className='rightItems'>
        <Notifications />
        <Profile />
      </div>
    </div>
  );
}
