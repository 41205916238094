import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CheckBox } from '../../../CheckBox';
import styles from './styles.module.scss';
import { InputText } from '../../../InputText';
import { DateTimePicker } from '../../../DateTimePicker';
import { ApplicantsType, ApplicantsToSend } from '../../../../@types/config';
import { maskCpfOrCnpj } from '../../../../helpers/masks';

type ApplicantsProps = {
  id: number,
  participation_id: number;
  name: string;
  corporateName: string;
  cpf: string;
  cnpj: string;
  isMobile: boolean;
  applicants: ApplicantsType[];
  applicantsToSend: ApplicantsToSend[];
  setApplicantsToSend: (applicants: any) => void;
};

export function Applicants(props: ApplicantsProps) {
  const [checkedBox, setCheckedBox] = useState(false);
  const [valueFrom, setValueFrom] = useState('');
  const [valueUntil, setValueUntil] = useState('');
  const [valuePayment, setValuePayment] = useState('');

  const formatPeriod = (date: string[] | string) => {
    if (date.length === 0) {
      return '';
    }
    return `${date[1]}/${date[2]}`;
  };
  useEffect(() => {
    if (checkedBox) {
      const dataUntil = valueUntil.length === 0 ? '' : valueUntil.split('/');
      const dataFrom = valueFrom.length === 0 ? '' : valueFrom.split('/');
      const formatedNumber = valuePayment.replace(/R\$\s*/g, '');

      const applicantFiltered = [props.applicants.filter((app) => app.id === props.id)];
      const newApplicants = applicantFiltered.map(() => {
        return {
          able: 1,
          judicial_part_id: props.id,
          payment_value: formatedNumber.toString().length > 0 ? formatedNumber.toString() : '0,00',
          initial_date: formatPeriod(dataFrom),
          final_date: formatPeriod(dataUntil),
        };
      });

      const applicantExist = props.applicantsToSend.filter((applicant) => applicant.judicial_part_id === props.id);
      const applicantList = props.applicantsToSend.map((applicant) => {
        if (applicant.judicial_part_id === props.id) {
          return newApplicants[0];
        }
        return applicant;
      });
      if (applicantExist.length === 0) {
        props.setApplicantsToSend([...props.applicantsToSend, newApplicants[0]]);
      } else {
        props.setApplicantsToSend(applicantList);
      }
    } else {
      const newListApplicants = props.applicantsToSend.filter((applicants) => applicants.judicial_part_id !== props.id);

      props.setApplicantsToSend(newListApplicants);
    }
  }, [checkedBox, valueUntil, valueFrom, valuePayment]);

  const handlePaymentValue = (value: string) => {
    setValuePayment(value);

    const formatedNumber = value.replace(/R\$\s*/g, '');
    props.applicantsToSend.forEach((applicant) => {
      if (applicant.judicial_part_id === props.id) {
        // eslint-disable-next-line no-param-reassign
        applicant.payment_value = formatedNumber.toString();
      }
    });
  };

  return (
    <div className={styles.container}>
      <CheckBox
        onClick={() => setCheckedBox(!checkedBox)}
        color={'var(--primary)'}
        isMarker={checkedBox}
        value={checkedBox}
      />
      <Grid
        container
        columns={{ xs: 4, sm: 6, md: 12 }}
        spacing={{ xs: 1, md: 1 }}
      >
        <Grid item xs={4} sm={6} md={4}>
          <InputText
            label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.beneficiaryName')}
            tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.tooltips.beneficiaryName')}
            value={props.name ? props.name : props.corporateName}
            readOnly
          />
        </Grid>
        <Grid item xs={2} sm={3} md={3}>
          <InputText
            label={t(`procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.${props.cnpj ? 'cnpj' : 'cpf'}`)}
            value={props.cpf ? maskCpfOrCnpj(props.cpf).mask() : maskCpfOrCnpj(props.cnpj).mask()}
            readOnly
          />
        </Grid>
        <Grid item xs={2} sm={3} md={3}>
          <DateTimePicker
            label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.period')}
            placeholder={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.selectPeriod')}
            setValueFrom={setValueFrom}
            setValueUntil={setValueUntil}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <InputText
            label={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.value')}
            tooltip={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.tooltips.value')}
            onChange={(event) => handlePaymentValue(event.target.value)}
            value={valuePayment}
            placeholder={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.valuePlaceholder')}
            currency
          />
        </Grid>
      </Grid>
    </div>
  );
}
