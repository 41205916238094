import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import YoutubeModal from '../YoutubeModal';

type ExpansiveFaqProps = {
  title: string;
  text: string;
  video?: string;
};

export function ExpansiveFaq(props: ExpansiveFaqProps) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {props.text}<br />
          {props.video ? <YoutubeModal videoId={props.video} /> : <></>}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
