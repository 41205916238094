import { useContext } from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { Grid } from '@mui/material';
import { FaRegQuestionCircle } from 'react-icons/fa';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartTooltip, ResponsiveContainer, Text,
} from 'recharts';
import Format from '../../helpers/format';
import styles from './styles.module.scss';
import { HeaderLine } from '../Charts/HeaderLine';
import { Info } from '../Charts/Info';
import { Tooltip } from '../Tooltip';
import { DashboardContext } from '../../context/dashboardContext';
import { Menu } from '../Charts/Menu';
import { getMenuItems } from '../../helpers/dashboardMenu';

export function DebtNegotiations() {
  const {
    setSelectedYear, selectedYear, negotiations, loading, negotiationsPerMonth,
  } = useContext(DashboardContext);
  const getPeriod = () => {
    return `${moment(new Date(new Date().getFullYear(), 0, 1)).format('DD/MM/YY')} ${t('dashboard.general.untilToday')}`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active) {
      return null;
    }

    for (const bar of payload) {
      if (active) {
        return (
          <div className={styles.tooltip}>
            <p className={styles.name}>{`${t(`dashboard.prescriptions.months.${bar.payload.month}`)} de ${selectedYear}`}</p>
            <div className={ `${styles.content}` }>
              <p className={styles.period}>{`${selectedYear}:`}</p>
              <p className={styles.value}>{`${Format.currency(bar.payload.total_paid)}`}</p>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={ styles.container }>
      <HeaderLine
        title={t('dashboard.negotiations.title')}
        tooltip={t('dashboard.negotiations.tooltip')}
      />
      <Grid
        container
        columns={{ xs: 1, sm: 1, md: 4 }}
        className={ styles.title }
        spacing={2}
      >
        <Grid item xs={1} md={4}>
          <div className={ styles.info }>
            <div className={ styles.yearMenu }>
              <p>{t('dashboard.negotiations.header')}</p>
              <p><Menu itens={getMenuItems(7, parseInt(selectedYear, 10))} onChange={(e) => { setSelectedYear(`${parseInt(e, 10)}`); }} chevron/> </p>
            </div>
            <div className={ styles.periodHeader }>
              <p className={ styles.period }>{getPeriod()}</p>
              <Tooltip title={`${t('dashboard.negotiations.period')}`}>
                <FaRegQuestionCircle />
              </Tooltip>
            </div>
          </div>
        </Grid>
        <Grid item xs={1} md={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item >
              <Info
                title={t('dashboard.negotiations.paid')}
                totalValue={negotiationsPerMonth?.total_paid || 0}
                textColor={'var(--successHover)'}
                backgroundColor={'var(--successLight1)'}
                loading={loading || negotiations === undefined}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1} md={4}>
          <Grid>
            <Grid item >
              <div className={ styles.chart }>
                <ResponsiveContainer width={'99%'} minWidth={700} height={250} minHeight={200}>
                  <BarChart
                    data={negotiationsPerMonth?.months}
                    margin={{
                      top: 10,
                      right: 0,
                      left: 0,
                      bottom: 10,
                    }} >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="month"
                      tick={(e) => {
                        const { payload: { value } } = e;
                        e.fontSize = 12;
                        e.fill = 'var(--dark)';
                        return <Text {...e}>{t(`dashboard.prescriptions.months.${value}`).substring(0, 3)}</Text>;
                      }}
                    />
                    <YAxis
                      dataKey="total_paid"
                      tick={(e) => {
                        const { payload: { value } } = e;
                        e.fontSize = 10;
                        e.fill = 'var(--gray2)';
                        return <Text {...e}>{Format.formatValueName(value)}</Text>;
                      }}
                    />
                    <RechartTooltip cursor={{ fill: 'var(--gray5)' }}content={<CustomTooltip />} />
                    <Bar
                      dataKey="total_paid"
                      fill='var(--primary)'
                      barSize={16}
                      radius={[25, 25, 0, 0]}>
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1} md={4}>
          <Grid container direction="column" spacing={3}>
            <Grid item >
            <Info
                title={t('dashboard.negotiations.active')}
                totalValue={negotiations?.total_active || 0}
                textColor={'var(--primaryHover)'}
                backgroundColor={'var(--gray5)'}
                loading={loading || negotiations === undefined}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1} md={4}>
        <Grid container direction="row" spacing={1}>
            <Grid item width={0.5} >
              <Info
                  title={t('dashboard.negotiations.overdue')}
                  totalValue={negotiations?.total_overdue || 0}
                  textColor={'var(--warningHover)'}
                  backgroundColor={'#FFFBE8'}
                  loading={loading || negotiations === undefined}
                />
            </Grid>
            <Grid item width={0.5} >
              <Info
                title={t('dashboard.negotiations.lost')}
                totalValue={negotiations?.total_lost || 0}
                textColor={'var(--dangerHover)'}
                backgroundColor={'var(--dangerLight1)'}
                loading={loading || negotiations === undefined}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
