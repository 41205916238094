import { Tooltip } from '@mui/material';
import { ReactNode, MouseEvent, useContext } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Show from '../../Show';
import styles from './styles.module.scss';
import { QueryContext } from '../../../context/queryContext';

type ItemProps = {
  id?: string;
  key?: number;
  icon: ReactNode;
  text: string;
  counter: {
    number: number;
    text: string;
  }[];
  selected: boolean;
  greenNumber?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  open?: boolean;
  dropdown?: boolean;
};

export function Item(props: ItemProps) {
  const { updateAdvancedFilter, updateQuery, advancedFiltersData } = useContext(QueryContext);
  const { t } = useTranslation();

  const applyReadFilter = () => {
    if (!props.greenNumber && props.text === t('procedureBox.boxesSelect.inbox')) {
      updateAdvancedFilter('was_seen_true', '0');
      const entries = Object.entries(advancedFiltersData).filter(([_, v]) => v.length >= 1);
      if (entries.length >= 1) {
        updateQuery();
      }
    }
  };

  return (
    <div
      id={ props.id }
      key={ props.key }
      className={`${styles.container} ${props.selected ? styles.selected : ''} ${props.open ? styles.open : ''}`}
      onClick={ props.onClick }
    >
      <div className={ styles.textRow }>
        { props.icon }
        <span>
          { props.text }
        </span>
      </div>

      <div className={ `${styles.counters} ${props.greenNumber ? styles.green : ''}` }>
        <Show if={props.counter[0].number > 0}>
          <Tooltip title={ props.counter[0].text }>
            <span className={ styles.first } onClick={applyReadFilter}>
              { props.counter[0].number }
            </span>
          </Tooltip>
        </Show>
        <Show if={props.counter[1].number > 0}>
          <Tooltip title={ props.counter[1].text }>
            <span className={ styles.second }>
              { props.counter[1].number }
            </span>
          </Tooltip>
        </Show>
        { props.dropdown && <span className={ `${styles.chevron}` }>
          { props.selected && (props.open ? <FaChevronUp className={ styles.chevron }/> : <FaChevronDown className={ styles.chevron }/>) }
          </span>
        }
      </div>
    </div>
  );
}
