import { useContext, useEffect, useState } from 'react';
import { FaExclamationTriangle, FaRegQuestionCircle } from 'react-icons/fa';
import { t } from 'i18next';
import Switch from 'react-switch';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '../Tooltip';
import { InputText } from '../InputText';
import Show from '../Show';
import { ColorsSelect } from './ColorsSelect';
import styles from './styles.module.scss';
import { Button } from '../Button';
import MarkerService from '../../services/markerService';
import { ProcedureMarkersContext } from '../../context/procedureMarkersContext';
import { ProcedureActionsContext } from '../../context/procedureActionsContext';
import { QueryContext } from '../../context/queryContext';

interface CreateEditMarkerModalProps {
  handleCloseModal: () => void;
  type: 'create' | 'edit';
}

export function CreateEditMarkerModal(props: CreateEditMarkerModalProps) {
  const [checked, setChecked] = useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [colorSelected, setColorSelected] = useState('');
  const [inputErr, setInputErr] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { markers, markerId, loadMarkers } = useContext(ProcedureMarkersContext);
  const { setLoadingListMarkers } = useContext(QueryContext);
  const { addToasts } = useContext(ProcedureActionsContext);

  const inputChange = (event: any) => {
    setInputValue(event.target.value);

    if (event.target.value > 27) {
      setInputErr(true);
    } else {
      setInputErr(false);
    }
  };

  const useStyles = makeStyles(() => ({
    customTooltip: {
      fontSize: 10,
      width: 200,
      textAlign: 'left',
    },
  }));

  const classes = useStyles();

  const handleSaveInfos = async () => {
    const markerInfos = {
      label: inputValue,
      color: colorSelected,
      private_marker: !checked,
    };

    try {
      if (props.type === 'create') {
        await MarkerService.createMarker(markerInfos);
      } else {
        await MarkerService.editMarker(markerId, markerInfos);
      }

      addToasts({
        type: 'success',
        text: t(`procedureBox.toastMarker.success.${props.type}`, { marker_name: markerInfos.label }),
      });

      loadMarkers();
    } catch (error) {
      addToasts({
        type: 'error',
        text: t(`procedureBox.toastMarker.error.${props.type}`, { marker_name: markerInfos.label }),
      });
    }
    setLoadingListMarkers(true);
    props.handleCloseModal();
  };

  useEffect(() => {
    if (props.type === 'edit') {
      const markerInfosPublic = markers.public_markers.find((index) => index.id === markerId);
      const markerInfosPrivate = markers.private_markers.find((index) => index.id === markerId);
      if (markerInfosPublic) {
        setInputValue(markerInfosPublic?.label);
        setColorSelected(markerInfosPublic?.color);
        setChecked(true);
      } else if (markerInfosPrivate) {
        setInputValue(markerInfosPrivate?.label);
        setColorSelected(markerInfosPrivate?.color);
        setChecked(false);
      }
    }
  }, []);

  useEffect(() => {
    if (inputValue.length > 0 && colorSelected.length > 0) {
      setDisableButton(false);
    }

    if (inputErr || inputValue.length === 0) {
      setDisableButton(true);
    }
  }, [inputValue, colorSelected]);

  return (
    <div className={styles.container}>
      <div className={styles.inputContent}>
        <InputText
          onChange={(value) => inputChange(value)}
          placeholder={t('procedureBox.modalMarker.input.placeholdolder')}
          label={t('procedureBox.modalMarker.input.label')}
          className={styles.input}
          required
          value={inputValue}
        />
        <span>
          <Show if={inputErr}>
            <FaExclamationTriangle />
            <span>{t('procedureBox.modalMarker.warningText')}</span>
          </Show>
        </span>
      </div>
      <div className={styles.tooltipContent}>
        <Switch
          onClick={() => setChecked(!checked)}
          checked={checked}
          onChange={() => setChecked(!checked)}
          offColor='#e8e8e8'
          onColor='#1eb1e7'
          checkedIcon={false}
          uncheckedIcon={false}
          height={18}
          width={32}
        />
        <span>{t('procedureBox.modalMarker.publicExhibition')}</span>
        <div>
          <Tooltip
            title={`${t('procedureBox.modalMarker.tooltipTitle')}`}
            placement='right'
            classes={classes}
          >
            <div>
              <FaRegQuestionCircle
                color='var(--gray4)'
                style={{ marginLeft: 5 }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.colorsContent}>
        <span>{t('procedureBox.modalMarker.color')}</span>
        <div className={styles.colors}>
          <ColorsSelect color={colorSelected} setColor={setColorSelected} />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button size='flat' buttonType='default' title={ t('procedureBox.modalMarker.cancel') } round onClick={props.handleCloseModal} />
        <Button size='flat' buttonType='primary' title={ t('procedureBox.modalMarker.save') } round onClick={() => handleSaveInfos()} disabled={disableButton} />
      </div>
    </div>
  );
}
