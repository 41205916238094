import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { AttachedDocument } from '../../../@types/config';
import { DocumentList } from '../../DocumentList';
import Screen from '../../../helpers/screen';
import styles from '../styles.module.scss';
import Show from '../../Show';

type AttachmentsProps = {
  content: AttachedDocument[];
  nextStep: (documentsSeleted: AttachedDocument[]) => void;
  prevStep?: () => void;
};

export function Attachments(props: AttachmentsProps) {
  const [documentsSeleted, setDocumentsSeleted] = useState<AttachedDocument[]>(props.content);
  const [disabled, setDisabled] = useState(true);
  const [show, setShow] = useState(false);
  const isMobile = Screen.isMobile();

  const handleDocumentSelected = (value: AttachedDocument[]) => {
    const enabled = value.length === 0 ? undefined : !!(value.find((x) => Boolean(x.signed) === true));
    setDisabled(!enabled);
    setDocumentsSeleted(value);
  };

  useEffect(() => {
    props.nextStep(documentsSeleted);
    const docSigned = documentsSeleted.find((doc) => doc.signed !== null);

    if (documentsSeleted.length > 0 || docSigned) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [documentsSeleted]);

  return (
    <div>
      <div className={styles.verify}>
        <div className={styles.text}>
          <span>{t('procedureBox.actions.spu.modal.attachments.text')}</span>
          <span>{t('procedureBox.actions.spu.modal.attachments.message')}</span>
        </div>
      </div>
      <DocumentList
        documents={props.content}
        onChange={(val) => handleDocumentSelected(val)}
        isMobile={isMobile}
      />
      <Show if={disabled && show}>
        <div className={styles.itemCheck}>
          <span>
            <FaExclamationTriangle className={styles.warning}/>
            {t('procedureBox.actions.spu.modal.attachments.error')}
          </span>
        </div>
      </Show>
    </div>
  );
}
