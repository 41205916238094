import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { t } from 'i18next';
import { ThemeProvider } from '@mui/material/styles';
import { Layout } from '../components/Layout';
import { Home } from '../pages/Home';
import { ProcedureBox } from '../pages/ProcedureBox';
import { globalTheme } from '../styles/globalTheme';

import '../config/i18n';
import { ShowProcedure } from '../pages/ShowProcedure';
import { NewProcedure } from '../pages/NewProcedure';
import { Dashboard } from '../pages/Reports/Dashboard';
import { Audience } from '../pages/Reports/Audience';
import { Calculation } from '../pages/Reports/Calculation';
import { DashboardMedic } from '../pages/Reports/DashboardMedic';
import { Distribution } from '../pages/Reports/Distribution';
import { JudicialOrders } from '../pages/Reports/JudicialOrders';
import { PaymentOrders } from '../pages/Reports/PaymentOrders';
import { Petitioning } from '../pages/Reports/Petitioning';
import { ProceduresCabinet } from '../pages/Reports/ProceduresCabinet';
import { Productivity } from '../pages/Reports/Productivity';
import { JudicialProcedures } from '../pages/Reports/JudicialProcedures';
import { DebtBox } from '../pages/ActiveDebt/DebtBox';
import { ApproveRequirement } from '../pages/ActiveDebt/Requirements/ApproveRequirement';
import { RequestRequirement } from '../pages/ActiveDebt/Requirements/RequestRequirement';
import { NewRequirement } from '../pages/ActiveDebt/Requirements/NewRequirement';
import { NewContributor } from '../pages/ActiveDebt/Requirements/NewContributor';
import { Search } from '../pages/Home/Procedures/Search';
import { SearchDebit } from '../pages/ActiveDebt/Inscriptions/SearchDebit';
import { ExtractDebits } from '../pages/ActiveDebt/Inscriptions/ExtractDebits';
import { NewStatementResponsibility } from '../pages/ActiveDebt/Inscriptions/NewStatementResponsibility';
import { ExtinguishSuspendCredit } from '../pages/ActiveDebt/Inscriptions/ExtinguishSuspendCredit';
import { OwnershipChange } from '../pages/ActiveDebt/Inscriptions/OwnershipChange';
import { SuspensionExtinctionRequests } from '../pages/ActiveDebt/Inscriptions/SuspensionExtinctionRequests';
import { MigrationRegisteredDebts } from '../pages/ActiveDebt/Inscriptions/MigrationRegisteredDebts';
import { ApproveMigrationRegisteredDebts } from '../pages/ActiveDebt/Inscriptions/ApproveMigrationRegisteredDebts';
import { RetroactiveCalculation } from '../pages/ActiveDebt/Inscriptions/RetroactiveCalculation';
import { ForwardJudgment } from '../pages/ActiveDebt/Judgment/ForwardJudgment';
import { RequestJudgment } from '../pages/ActiveDebt/Judgment/RequestJudgment';
import { RequestProtest } from '../pages/ActiveDebt/Protest/RequestProtest';
import { AbandonmentCancellation } from '../pages/ActiveDebt/Protest/AbandonmentCancellation';
import { ActiveDebtDiagnosis } from '../pages/ActiveDebt/Management/ActiveDebtDiagnosis';
import { ActiveDebtDiagnosisPhase } from '../pages/ActiveDebt/Management/ActiveDebtDiagnosisPhase';
import { ActiveDebtDiagnosisRange } from '../pages/ActiveDebt/Management/ActiveDebtDiagnosisRange';
import { GeolocationContributors } from '../pages/ActiveDebt/Management/GeolocationContributors';
import { GeolocationContributorsRegions } from '../pages/ActiveDebt/Management/GeolocationContributorsRegions';
import { OverviewMunicipalActiveDebt } from '../pages/ActiveDebt/Management/OverviewMunicipalActiveDebt';
import { OverviewProdat } from '../pages/ActiveDebt/Management/OverviewProdat';
import { OverviewInstallmentManagement } from '../pages/ActiveDebt/Management/OverviewInstallmentManagement';
import { CollectionManagement } from '../pages/ActiveDebt/Management/CollectionManagement';
import { OverviewJudgment } from '../pages/ActiveDebt/Management/OverviewJudgment';
import { OverviewProtest } from '../pages/ActiveDebt/Management/OverviewProtest';
import { EvolutionDebtStock } from '../pages/ActiveDebt/Management/EvolutionDebtStock';
import { OverviewPhasesDebtInscriptions } from '../pages/ActiveDebt/Management/OverviewPhasesDebtInscriptions';
import { ExtractInscriptions } from '../pages/ActiveDebt/Reports/ExtractInscriptions';
import { GeneralCollection } from '../pages/ActiveDebt/Reports/GeneralCollection';
import { TaxCollection } from '../pages/ActiveDebt/Reports/TaxCollection';
import { CollectionCharges } from '../pages/ActiveDebt/Reports/CollectionCharges';
import { ManagementViewActiveDebt } from '../pages/ActiveDebt/Reports/ManagementViewActiveDebt';
import { ReportRequestsAPI } from '../pages/ActiveDebt/Reports/ReportRequestsAPI';
import { ManageInstallmentsAgreements } from '../pages/ActiveDebt/Reports/ManageInstallmentsAgreements';
import { Financial } from '../pages/ActiveDebt/Financial';
import { InformTax } from '../pages/ActiveDebt/Settings/InformTax';
import { CalculationStandards } from '../pages/ActiveDebt/Settings/CalculationStandards';
import { BlockedInscriptions } from '../pages/ActiveDebt/Settings/BlockedInscriptions';
import { Refis } from '../pages/ActiveDebt/Settings/Refis';
import { Scheduling } from '../pages/ActiveDebt/Settings/Scheduling';
import { Detran } from '../pages/Search/Detran';
import { ToParts } from '../pages/Search/ToParts';
import { Schedule } from '../pages/Schedule';
import { UserManagement } from '../pages/Settings/UserManagement';
import { FluxesManagement } from '../pages/Settings/FluxesManagement';
import { ProcedureCollection } from '../pages/ProcedureCollection';
import { Faq } from '../pages/Faq';
import { Details } from '../pages/ShowProcedure/Timeline/Details';
import { DebtBoxSearch } from '../pages/ActiveDebt/DebtBoxSearch';
import { SHOW_AGILIS_DEBITBOX } from '../config/constants';

const OtherRoutes: React.FC = () => {
  return (
    <ThemeProvider theme={globalTheme}>
      <Layout>
        <Routes>
          <Route path="/faq" element={<Faq title={t('faq.title')} />} />

          <Route path="/procedure_box" element={<ProcedureBox title={t('procedureBox.title')} />} />
          <Route path="/procedure_collection" element={<ProcedureCollection title={t('procedureCollections.title')} />} />
          <Route path="/" element={<Home />} />
          <Route path="/procedure_box/show_procedure/progress/:id" element={<Details isFullScreen />} />
          <Route path="/procedure_box/show_procedure/:id" element={<ShowProcedure title={t('procedureBox.processExtract.title')}/>} />
          <Route path="/new_procedure" element={<NewProcedure />} />
          <Route path="/procedures/search" element={<Search />} />
          <Route path="/procedures/reports/audience" element={<Audience />} />
          <Route path="/procedures/reports/calculation" element={<Calculation />} />
          <Route path="/procedures/reports/dashboard" element={<Dashboard />} />
          <Route path="/procedures/reports/dashboard_medic" element={<DashboardMedic />} />
          <Route path="/procedures/reports/distribution" element={<Distribution />} />
          <Route path="/procedures/reports/judicial_orders" element={<JudicialOrders />} />
          <Route path="/procedures/reports/payment_orders" element={<PaymentOrders />} />
          <Route path="/procedures/reports/petitioning" element={<Petitioning />} />
          <Route path="/procedures/reports/procedures_cabinet" element={<ProceduresCabinet />} />
          <Route path="/procedures/reports/productivity" element={<Productivity />} />
          <Route path="/procedures/reports/judicial_procedures" element={<JudicialProcedures />} />

          <Route path="/active_debt/debt_box" element={<DebtBox />} />
          {
            SHOW_AGILIS_DEBITBOX && <Route path="/active_debt/debt_box/debt_box_search" element={<DebtBoxSearch />} />
          }
          <Route path="/active_debt/requiriments/approve" element={<ApproveRequirement />} />
          <Route path="/active_debt/requiriments/request" element={<RequestRequirement />} />
          <Route path="/active_debt/requiriments/new" element={<NewRequirement />} />
          <Route path="/active_debt/requiriments/new_contributor" element={<NewContributor />} />

          <Route path="/active_debt/inscriptions/search_debit" element={<SearchDebit />} />
          <Route path="/active_debt/inscriptions/extract_debit" element={<ExtractDebits />} />
          <Route path="/active_debt/inscriptions/statement_responsibility" element={<NewStatementResponsibility />} />
          <Route path="/active_debt/inscriptions/extinguish_suspend_credit" element={<ExtinguishSuspendCredit />} />
          <Route path="/active_debt/inscriptions/ownership_change" element={<OwnershipChange />} />
          <Route path="/active_debt/inscriptions/suspension_extinction_requests" element={<SuspensionExtinctionRequests />} />
          <Route path="/active_debt/inscriptions/migration_registered_debts" element={<MigrationRegisteredDebts />} />
          <Route path="/active_debt/inscriptions/approve_migration_registered_debts" element={<ApproveMigrationRegisteredDebts />} />
          <Route path="/active_debt/inscriptions/retroactive_calculation" element={<RetroactiveCalculation />} />

          <Route path="/active_debt/protest/request_protest" element={<RequestProtest />} />
          <Route path="/active_debt/protest/abandonment_cancellation" element={<AbandonmentCancellation />} />

          <Route path="/active_debt/judgment/request" element={<RequestJudgment />} />
          <Route path="/active_debt/judgment/forward" element={<ForwardJudgment />} />

          <Route path="/active_debt/management/active_debt_diagnosis" element={<ActiveDebtDiagnosis />} />
          <Route path="/active_debt/management/active_debt_diagnosis_phase" element={<ActiveDebtDiagnosisPhase />} />
          <Route path="/active_debt/management/active_debt_diagnosis_range" element={<ActiveDebtDiagnosisRange />} />
          <Route path="/active_debt/management/geolocation_contributors" element={<GeolocationContributors />} />
          <Route path="/active_debt/management/geolocation_contributors_regions" element={<GeolocationContributorsRegions />} />
          <Route path="/active_debt/management/overview_municipal_active_debt" element={<OverviewMunicipalActiveDebt />} />
          <Route path="/active_debt/management/overview_prodat" element={<OverviewProdat />} />
          <Route path="/active_debt/management/overview_installment_management" element={<OverviewInstallmentManagement />} />
          <Route path="/active_debt/management/collection_management" element={<CollectionManagement />} />
          <Route path="/active_debt/management/overview_judgment" element={<OverviewJudgment />} />
          <Route path="/active_debt/management/overview_protest" element={<OverviewProtest />} />
          <Route path="/active_debt/management/evolution_debt_stock" element={<EvolutionDebtStock />} />
          <Route path="/active_debt/management/overview_phases_debt_inscriptions" element={<OverviewPhasesDebtInscriptions />} />

          <Route path="/active_debt/reports/extract_inscriptions" element={< ExtractInscriptions/>} />
          <Route path="/active_debt/reports/general_collection" element={< GeneralCollection/>} />
          <Route path="/active_debt/reports/tax_collection" element={< TaxCollection/>} />
          <Route path="/active_debt/reports/collection_charges" element={< CollectionCharges/>} />
          <Route path="/active_debt/reports/management_view_active_debt" element={< ManagementViewActiveDebt/>} />
          <Route path="/active_debt/reports/manage_installments_agreements" element={< ManageInstallmentsAgreements/>} />
          <Route path="/active_debt/reports/report_requests_API" element={< ReportRequestsAPI/>} />

          <Route path="/active_debt/financial" element={< Financial/>} />

          <Route path="/active_debt/settings/inform_tax" element={< InformTax/>} />
          <Route path="/active_debt/settings/calculation_standards" element={< CalculationStandards/>} />
          <Route path="/active_debt/settings/blocked_inscriptions" element={< BlockedInscriptions/>} />
          <Route path="/active_debt/settings/refis" element={< Refis/>} />
          <Route path="/active_debt/settings/scheduling" element={< Scheduling/>} />

          <Route path="/search/detran" element={< Detran/>} />
          <Route path="/search/to_parts" element={< ToParts/>} />

          <Route path="/schedule" element={< Schedule title={t('sidebar.modules.calendar')}/>} />

          <Route path="/settings/user_management" element={< UserManagement/>} />
          <Route path="/settings/fluxes_management" element={< FluxesManagement/>} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
};

export default OtherRoutes;
