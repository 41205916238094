import { createTheme } from '@mui/material/';

export const customTheme = (createTheme as any)({
  palette: {
    primary: {
      main: '#B9DFED',
    },
    secondary: {
      main: '#9DA423',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '8px',
          borderRadius: '5px',
          backgroundColor: 'var(--gray5)',
        },
      },
    },
  },
});
