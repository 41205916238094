import { useContext, useEffect, useState } from 'react';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import { t } from 'i18next';
import { FaPlusCircle, FaRegQuestionCircle } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import * as Yup from 'yup';
import { isValid, parse } from 'date-fns';
import { customTheme } from '../CustomTheme';
import { Button } from '../../../Button';
import Select from '../../../Select';
import Screen from '../../../../helpers/screen';
import { TextArea } from '../../../TextArea';
import { ProcedureActionsContext } from '../../../../context/procedureActionsContext';
import { Applicants } from './Applicants';
import { ApplicantsModal } from './ApplicantsModal';
import { Batch } from '../../../../@types/backgroundActions';
import { BackgroundActionsContext } from '../../../../context/backgroundActions';
import { InputText } from '../../../InputText';
import format from '../../../../helpers/format';
import { Procedure } from '../../../../@types/model';
import styles from './styles.module.scss';
import Show from '../../../Show';
import { InputDate } from '../../../InputDate';
import { isValidDate } from '../../../../helpers/validator';
import { MainCalculate, ProcedureDependencies } from '../../../../@types/requestCalculation';
import { OptionType } from '../../../../@types/config';

type MainCalculateInfosProps = {
  dependencies: ProcedureDependencies;
  subProcedure: Procedure;
  prevStep: () => void;
};

interface FormValues {
  starting_point: string;
  ending_point: string;
  total_value_authorial: string;
  citation_date: string;
  deadline: string;
  fee_update_criteria: string;
  another_notes: string;
  calculation_details: string;
  monetary_index: OptionType;
  monetary_index_note: string;
  monetary_term_index: OptionType;
  monetary_term_note: string;
  moratorium_interest_index: OptionType;
  moratorium_interest_index_note: string;
  interest_initial_term: OptionType;
  interest_initial_term_note: string;
  update_end_term: OptionType;
  update_end_term_note: string;
}

export function MainCalculateInfos(props: MainCalculateInfosProps) {
  const { setAlert, proceduresSeleted, setModal } = useContext(ProcedureActionsContext);
  const { addBatch } = useContext(BackgroundActionsContext);
  const [coordinationStatus, setCoordinationStatus] = useState<'yes' | 'no'>('yes');
  const [dataApplicantsToSend, setApplicantsToSend]: any = useState([]);
  const [attachAndTramit, setAttachAndTramit] = useState(false);
  const [applicantsList, setApplicantsList]: any = useState([]);
  const [dateError, setDateError] = useState('');
  const [open, setOpen] = useState(false);

  const isMobile = Screen.isMobile();

  const schema = Yup.object().shape({
    citation_date: Yup
      .string()
      .test('is-valid-citation-date', t('general.invalidDate'), (value) => {
        return isValidDate(value);
      })
      .required(t('general.requeried')),
    starting_point: Yup
      .string()
      .test('is-valid-starting-date', t('general.invalidDate'), (value) => {
        return isValidDate(value);
      })
      .required(t('general.requeried')),
    ending_point: Yup
      .string()
      .test('is-valid-ending-date', t('general.invalidDate'), (value) => {
        return isValidDate(value);
      })
      .required(t('general.requeried')),
    deadline: Yup
      .string()
      .test('is-future-date', t('general.date'), (value) => {
        if (!value) return false;

        const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
        if (!dateRegex.test(value)) return false;

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const formattedValue = value.replace(dateRegex, '$3-$2-$1');
        const selectedDate = parse(formattedValue, 'yyyy-MM-dd', new Date());

        return isValid(selectedDate) && selectedDate >= today;
      })
      .required(t('general.requeried')),
    monetary_index: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    monetary_index_note: Yup.string().test(
      'monetaryIndexRequiredIfValueIsZero',
      t('general.requeried'),
      function (value) {
        const monetaryIndex = this.parent.monetary_index;
        if (monetaryIndex?.value === 0) {
          return value !== undefined && value.trim() !== '';
        }
        return true;
      },
    ),
    monetary_term_index: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    monetary_term_note: Yup.string().test(
      'monetaryTermIndexRequiredIfValueIsZero',
      t('general.requeried'),
      function (value) {
        const monetaryTermIndex = this.parent.monetary_term_index;
        if (monetaryTermIndex?.value === 0) {
          return value !== undefined && value.trim() !== '';
        }
        return true;
      },
    ),
    moratorium_interest_index: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    moratorium_interest_index_note: Yup.string().test(
      'moratoriumInterestIndexRequiredIfValueIsZero',
      t('general.requeried'),
      function (value) {
        const moratoriumInterestIndex = this.parent.moratorium_interest_index;
        if (moratoriumInterestIndex?.value === 0) {
          return value !== undefined && value.trim() !== '';
        }
        return true;
      },
    ),
    interest_initial_term: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    interest_initial_term_note: Yup.string().test(
      'interestInitialTermRequiredIfValueIsZero',
      t('general.requeried'),
      function (value) {
        const interestInitialTerm = this.parent.interest_initial_term;
        if (interestInitialTerm?.value === 0) {
          return value !== undefined && value.trim() !== '';
        }
        return true;
      },
    ),
    update_end_term: Yup.object()
      .shape({
        label: Yup.string().required(t('general.requeried')),
        value: Yup.number().required(t('general.requeried')),
      })
      .required(t('general.requeried')),
    update_end_term_note: Yup.string().test(
      'updateEndTermRequiredIfValueIsZero',
      t('general.requeried'),
      function (value) {
        const updateEndTerm = this.parent.update_end_term;
        if (updateEndTerm?.value === 0) {
          return value !== undefined && value.trim() !== '';
        }
        return true;
      },
    ),
  });

  const {
    handleSubmit, formState: { errors }, control,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const handleRequestCalculate = (form: any) => {
    const data: MainCalculate = {
      calculation_type: 1,
      ...form,
      fee_conviction: coordinationStatus === 'yes',
    };

    const procedures = proceduresSeleted;
    const type = 'eletronicCalculation';

    const requesterList: string[] = dataApplicantsToSend.map((item: any) => String(item.id));
    const body = {
      attachAndTramit,
      subProcedure: props.subProcedure,
      procedure_id: props.subProcedure.id,
      requestCalculations: {
        ...data,
        monetary_index: data.monetary_index?.value,
        monetary_term_index: data.monetary_term_index?.value,
        moratorium_interest_index: data.moratorium_interest_index?.value,
        interest_initial_term: data.interest_initial_term?.value,
        update_end_term: data.update_end_term?.value,
        requester_list: requesterList,
        total_value_authorial: format.removeCurrencyFormat(data.total_value_authorial),
      },
    };

    const id = (Math.random() + 1).toString(36).substring(1);

    const batch: Batch = {
      id,
      procedures,
      type,
      count: proceduresSeleted.length,
      successList: [],
      failedList: [],
      body,
    };

    addBatch(batch);

    setAlert(undefined);
    setModal(undefined);
  };

  const showAlert = (form: any) => {
    setAlert({
      visible: true,
      handleConfirm: () => handleRequestCalculate(form),
      title: t(
        'procedureBox.actions.eletronicCalculation.modal.alertConfirmation.title',
        { processNumber: proceduresSeleted[0].process_number },
      ),
      text: t('procedureBox.actions.eletronicCalculation.modal.alertConfirmation.text'),
      confirmText: t('general.yes'),
      confirmType: 'primary',
    });
  };

  const validateDateRange = (startingPoint: string, endingPoint: string): boolean => {
    const startingDate = moment(startingPoint, 'DD-MM-YYYY');
    const endingDate = moment(endingPoint, 'DD-MM-YYYY');
    if (startingDate.isAfter(endingDate)) {
      return false;
    }
    return true;
  };

  const handleValidadion = (form: FormValues) => {
    if (form.starting_point !== null && form.ending_point !== null) {
      if (validateDateRange(form.starting_point, form.ending_point)) {
        setDateError('');
        showAlert(form);
      } else {
        setDateError(`${t('procedureBox.actions.eletronicCalculation.validation.ending_point')}`);
      }
    } else {
      showAlert(form);
    }
  };

  const addApplicants = (id: number, checked: boolean) => {
    const applicantExist = dataApplicantsToSend.filter((applicant: any) => applicant.id === id);
    const applicantToSend = applicantsList.filter((applicant: any) => applicant.id === id);

    if (checked) {
      if (applicantExist.length === 0) {
        setApplicantsToSend([...dataApplicantsToSend, applicantToSend[0]]);
      }
    } else {
      const newApplicantsList = dataApplicantsToSend.filter((app: any) => app.id !== id);
      setApplicantsToSend(newApplicantsList);
    }
  };

  useEffect(() => {
    setApplicantsList(props.dependencies.requesters);
  }, []);

  const monetaryIndexValue = useWatch({
    control,
    name: 'monetary_index.value',
    defaultValue: undefined,
  });
  const monetaryTermIndexValue = useWatch({
    control,
    name: 'monetary_term_index.value',
    defaultValue: undefined,
  });
  const moratoriumInterestIndexValue = useWatch({
    control,
    name: 'moratorium_interest_index.value',
    defaultValue: undefined,
  });
  const interestInitialTermValue = useWatch({
    control,
    name: 'interest_initial_term.value',
    defaultValue: undefined,
  });
  const updateEndTermValue = useWatch({
    control,
    name: 'update_end_term.value',
    defaultValue: undefined,
  });

  return (
    <form className={ styles.container } onSubmit={handleSubmit(handleValidadion)}>
      <Grid
        container
        className={ styles.infos }
        columns={{ xs: 4, sm: 12, md: 12 }}
        spacing={{ xs: 2, md: 2 }}
      >
        <Grid item xs={4} sm={4} md={4}>
          <InputText
            value={props.dependencies.main_procedure}
            label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.processNumber')}
            tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.processNumberTooltip') || ''}
            readOnly
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Select
            placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
            label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.object')}
            tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.objectTooltip')}
            options={props.dependencies.object}
            defaultValue={props.dependencies.object[0]}
            disabled
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}></Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="citation_date"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.citationDate')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.citationDateTooltip')}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.datePlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.citation_date?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="starting_point"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.startingPoint')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.startingPointTooltip')}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.datePlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.starting_point?.message}</p>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="ending_point"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.finalPoint')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.finalPointTooltip')}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.datePlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{dateError}</p>
          <p className={styles.error}>{errors.ending_point?.message}</p>
        </Grid>
        {/* Índice para correção monetária */}
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="monetary_index"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.indexMonetaryCorrection')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.indexMonetaryCorrectionTooltip')}
                options={props.dependencies.monetary_indices}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.monetary_index?.value?.message}</p>
          <p className={styles.error}>{errors.monetary_index?.message}</p>
          <Show if={monetaryIndexValue === 0}>
            <Controller
              control={control}
              name="monetary_index_note"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.other')}
                />
              )}
            />
            <p className={styles.error}>{errors.monetary_index_note?.message}</p>
          </Show>
        </Grid>
        {/* Termo inicial correção monetária */}
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="monetary_term_index"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.initialTermMonetary')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.initialTermMonetaryTooltip')}
                options={props.dependencies.monetary_term_indices}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.monetary_term_index?.value?.message}</p>
          <p className={styles.error}>{errors.monetary_term_index?.message}</p>
          <Show if={monetaryTermIndexValue === 0}>
            <Controller
              control={control}
              name="monetary_term_note"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.other')}
                />
              )}
            />
            <p className={styles.error}>{errors.monetary_term_note?.message}</p>
          </Show>
        </Grid>
        {/* Indice para juros de mora */}
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="moratorium_interest_index"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.indexForArrearsInterest')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.indexForArrearsInterestTooltip')}
                options={props.dependencies.moratorium_interest_indices}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
              />
            )}
          />
            <p className={styles.error}>{errors.moratorium_interest_index?.value?.message}</p>
            <p className={styles.error}>{errors.moratorium_interest_index?.message}</p>
            <Show if={moratoriumInterestIndexValue === 0}>
              <Controller
                control={control}
                name="moratorium_interest_index_note"
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.other')}
                  />
                )}
              />
              <p className={styles.error}>{errors.moratorium_interest_index_note?.message}</p>
            </Show>
        </Grid>
        {/* Termo inicial juros */}
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="interest_initial_term"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.initialTermInterest')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.initialTermInterestTooltip')}
                options={props.dependencies.interest_initial_terms}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.interest_initial_term?.value?.message}</p>
          <p className={styles.error}>{errors.interest_initial_term?.message}</p>
          <Show if={interestInitialTermValue === 0}>
            <Controller
              control={control}
              name="interest_initial_term_note"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.other')}
                />
              )}
            />
            <p className={styles.error}>{errors.interest_initial_term_note?.message}</p>
          </Show>
        </Grid>
        {/* Termo final da atualização */}
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="update_end_term"
            render={({ field }) => (
              <Select
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.finalUpdateTerm')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.finalUpdateTermTooltip')}
                options={props.dependencies.update_end_terms}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.selectPlaceholder')}
              />
            )}
          />
          <p className={styles.error}>{errors.update_end_term?.value?.message}</p>
          <p className={styles.error}>{errors.update_end_term?.message}</p>
          <Show if={updateEndTermValue === 0}>
            <Controller
              control={control}
              name="update_end_term_note"
              render={({ field }) => (
                <InputText
                  {...field}
                  label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.other')}
                />
              )}
            />
            <p className={styles.error}>{errors.update_end_term_note?.message}</p>
          </Show>
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="total_value_authorial"
            render={({ field }) => (
              <InputText
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.totalValueAuthorial')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.totalValueAuthorialTooltip') || ''}
                placeholder={t('procedureBox.actions.paymentOrder.modal.tabs.listBeneficiaries.applicantsList.valuePlaceholder')}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                }}
                currency
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <ThemeProvider theme={customTheme}>
            <section className={styles.coordination}>
              <Tooltip placement='left-end' title={t('procedureBox.actions.eletronicCalculation.modal.tooltips.condemnationFeesTooltip') || ''}>
                <FormLabel className={styles.label} id='radioGroup'>
                  {t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.condemnationFees.label')}
                  <FaRegQuestionCircle style={{ marginLeft: 5 }} color='var(--gray3)' fontSize='11px' className={styles.icon} />
                </FormLabel>
              </Tooltip>
              <RadioGroup
                aria-labelledby='radioGroup'
                name='controlledRadioGroup'
                onChange={(e) => {}}
                className={styles.radioGroup}
                row
              >
                <FormControlLabel
                  value={false}
                  checked={coordinationStatus === 'yes'}
                  onChange={() => setCoordinationStatus('yes')}
                  control={<Radio />}
                  label={
                    t(
                      'procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.condemnationFees.yes',
                    ).toString()
                  }
                />
                <FormControlLabel
                  checked={coordinationStatus === 'no'}
                  onChange={() => setCoordinationStatus('no')}
                  control={<Radio />}
                  label={
                    t(
                      'procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.condemnationFees.no',
                    ).toString()
                  }
                />
              </RadioGroup>
            </section>
          </ThemeProvider>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Controller
            control={control}
            name="fee_update_criteria"
            render={({ field }) => (
              <TextArea
                {...field}
                classNameContainer={styles.updateCriterial}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.updatingFees')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.updatingFeesTooltip')}
                placeholder={
                  t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.updatingFeesPlaceholder')
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <Controller
            control={control}
            name="another_notes"
            render={({ field }) => (
              <TextArea
                {...field}
                classNameContainer={styles.notesToConsider}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.notesToConsider')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.notesToConsiderTooltip')}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.notesToConsiderPlaceholder')}
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={4} md={4}>
          <Controller
            control={control}
            name="deadline"
            render={({ field }) => (
              <InputDate
                {...field}
                label={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.deliveryTime')}
                tooltip={t('procedureBox.actions.eletronicCalculation.modal.tooltips.deliveryTimeTooltip')}
                placeholder={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.datePlaceholder')}
                disabledHolidays
              />
            )}
          />
          <p className={styles.error}>{errors.deadline?.message}</p>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <section className={styles.applicantsContent}>
            <div className={styles.header}>
              <Tooltip
                placement='left-end'
                title={t('procedureBox.actions.eletronicCalculation.modal.tooltips.listOfApplicantsLabelTooltip') || ''}
              >
                <span>
                  {
                    t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.listOfApplicantsLabel')
                  }
                </span>
              </Tooltip>
            </div>
          </section>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <span className={styles.applicantsMessage}>
            {t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.listOfApplicantsText')}
          </span>
          { applicantsList.length > 0 && applicantsList.map((applicant: any) => <Applicants
              id={applicant.id}
              name={applicant.name}
              document={applicant.cpf || applicant.cnpj}
              isMobile={isMobile}
              onClick={(id, checked) => addApplicants(id, checked)}
            />)
          }
          <div className={styles.plus}>
            {
              !isMobile
                ? <FaPlusCircle className={styles.plusIcon} onClick={() => setOpen(true)} />
                : <Button
                    title={
                      t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.addApplicants.buttons.buttonCreate')
                    }
                    onClick={() => setOpen(true)}
                    round
                    buttonType='primary'
                  />
            }
          </div>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
          <div className={styles.documents}>
            <div className={styles.header}>
              <span>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.documentsLabel')}</span>
            </div>
            <p>{t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.documentsText')}</p>
            <section className={styles.calculateType}>
              <FormControlLabel
                checked={attachAndTramit}
                onChange={() => setAttachAndTramit(true)}
                control={<Radio />}
                label={
                  t(
                    'procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.mainCalculate.attachAndTramit',
                  ).toString()
                }
              />
            </section>
          </div>
        </Grid>
        <Grid item xs={4} sm={12} md={12}>
         <footer>
           <Button
             className={styles.buttonLeft}
             title={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.buttons.previusStep')}
             onClick={() => props.prevStep()}
             buttonType='default'
             size={isMobile ? 'small' : 'flat'}
             round
           />

           <Button
             title={t('procedureBox.actions.eletronicCalculation.modal.calculationDataContent.options.buttons.createEletronicOrder')}
             buttonType='primary'
             type='submit'
             disabled={dataApplicantsToSend.length === 0}
             size={isMobile ? 'small' : 'flat'}
             round
           />
         </footer>
        </Grid>
      </Grid>
      <ApplicantsModal
        open={open}
        setOpen={setOpen}
        setApplicantsList={setApplicantsList}
        participations={props.dependencies.participations}
        subProcedureId={props.subProcedure.id}
      />
    </form>
  );
}
