import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { useContext } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Text,
} from 'recharts';
import Format from '../../helpers/format';
import { HeaderLine } from '../Charts/HeaderLine';
import { Menu } from '../Charts/Menu';
import styles from './styles.module.scss';
import { DashboardContext } from '../../context/dashboardContext';
import { getMenuItems } from '../../helpers/dashboardMenu';

export function DebtProgress() {
  const {
    negotiationProgress, loading, prevYear, setPrevYear,
  } = useContext(DashboardContext);
  const currentYear = `${moment().year()}`;
  const baseYear = new Date().getFullYear() - 1;
  const getPeriod = () => {
    return `${t('dashboard.progressNegotiation.start')} 
    ${moment(new Date(new Date().getFullYear(), 0, 1)).format('DD/MM/YY')} 
    ${t('dashboard.general.untilToday')} ${t('dashboard.progressNegotiation.end')}`;
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active) {
      return null;
    }

    for (const bar of payload) {
      if (active) {
        return (
          <div className={styles.tooltip}>
            <p className={styles.name}>{t('dashboard.progressNegotiation.period')}</p>
            <div className={ `${styles.content} ${styles.previous}` }>
              <p className={styles.period}>{`${prevYear}: `}</p>
              <p className={styles.value}>{`${Format.currency(bar.payload.previous)}`}</p>
            </div>
            <div className={ `${styles.content}` }>
              <p className={styles.period}>{`${currentYear}: `}</p>
              <p className={styles.value}>{`${Format.currency(bar.payload.current)}`}</p>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className={ styles.container }>
      <HeaderLine
        title={t('dashboard.progressNegotiation.title')}
        tooltip={t('dashboard.progressNegotiation.tooltip')}
      />
      <div className={ styles.title }>
        <p>{getPeriod()} <Menu itens={getMenuItems(7, baseYear)} onChange={(item: string) => setPrevYear(item)} chevron/> </p>
      </div>
      {
        !loading && negotiationProgress.length > 0 ? (
        <div className={ styles.chart }>
        <ResponsiveContainer width={'99%'} minWidth={700} height={250} minHeight={200}>
            <BarChart
              data={negotiationProgress}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 10,
              }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="status"
              tick={(e) => {
                const { payload: { value } } = e;
                e.fontSize = 12;
                e.fill = 'var(--dark)';
                return <Text {...e}>{t(`dashboard.state.${value}`)}</Text>;
              }}
            />
            <YAxis
              tick={(e) => {
                const { payload: { value } } = e;
                e.fontSize = 10;
                e.fill = 'var(--gray2)';
                return <Text {...e}>{Format.formatValueName(value)}</Text>;
              }}
            />
            <Tooltip cursor={{ fill: 'var(--gray5)' }}content={<CustomTooltip />} />
            <Bar
              dataKey="previous"
              fill='var(--primaryDark)'
              barSize={16}
              radius={[25, 25, 0, 0]}>
            </Bar>
            <Bar
              dataKey="current"
              fill='var(--primary)'
              barSize={16}
              radius={[25, 25, 0, 0]}>
            </Bar>
            </BarChart>
        </ResponsiveContainer>
        </div>
        )
          : <Skeleton variant={'rectangular'} className={ styles.row }/>
      }
    </div>
  );
}
