import { Grid } from '@mui/material';
import { t } from 'i18next';
import { Field } from '../../../@types/digitalProcessesDashboard';
import { truncate } from '../../../helpers/strings';
import { Bar } from '../../Charts/Bar';
import { Tooltip } from '../../Tooltip';
import styles from './styles.module.scss';

type ItemProps = {
  field: Field;
  maxTotalInbox: number;
  maxTotalInputbox: number;
  maxTotalOutbox: number;
};

export function Item(props: ItemProps) {
  return (
    <div className={ styles.container }>
      <div className={ styles.header }>
        <p className={styles.field}>{props.field.field_name}</p>
        <p className={styles.job}>
          ({props.field.individuals.length} {t('dashboard.digitalProcesses.productivityChart.title').toLocaleLowerCase()})
        </p>
      </div>
      <div>
      <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {props.field.individuals.map((individual, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            {
              individual.individual_name.length > 25
                ? <Tooltip title={individual.individual_name}>
                <p className={styles.name}>{truncate(individual.individual_name, 25)}</p>
              </Tooltip> : <p className={styles.name}>{individual.individual_name}</p>
            }
            {
              individual.job_title_name.length > 35
                ? <Tooltip title={individual.job_title_name}>
                <p className={styles.job}>{truncate(individual.job_title_name, 35)}</p>
              </Tooltip> : <p className={styles.job}>{individual.job_title_name}</p>
            }
            <Bar
              color={'var(--gray3)'}
              hoverColor={'var(--gray6)'}
              tooltip={`${t('dashboard.digitalProcesses.productivityChart.totalInbox')} ${individual.total_inbox}`}
              width={props.maxTotalInbox > 0 ? (individual.total_inbox / props.maxTotalInbox) * 100 : 0}
            />
            <Bar
              color={'var(--primaryDisable)'}
              hoverColor={'var(--primary)'}
              tooltip={`${t('dashboard.digitalProcesses.productivityChart.totalInputbox')} ${individual.total_inputbox}`}
              width={props.maxTotalInputbox > 0 ? (individual.total_inputbox / props.maxTotalInputbox) * 100 : 0}
            />
            <Bar
              color={'var(--successDisable)'}
              hoverColor={'var(--success)'}
              tooltip={`${t('dashboard.digitalProcesses.productivityChart.totalOutbox')} ${individual.total_outbox}`}
              width={props.maxTotalOutbox > 0 ? (individual.total_outbox / props.maxTotalOutbox) * 100 : 0}
            />
          </Grid>
        ))}
      </Grid>
      </div>
    </div>
  );
}
